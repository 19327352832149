import {  Button, Dialog, DialogContent, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { shippingApiNm } from "./shippingApiNm"
import { alertError } from "components/Alert"
import FileDropPanel from "components/FileUplaod/FileDropPanel"
import SendEmailDialog from "./SendEmailDialog"
// import pdf from 'pdf-parse-fork';

const ED0209Dialog = ({ selectedId, dialogOpen, setDialogOpen, lastFilter, onFinish }) => {

  const { ax } = useContext(GlobalStateContext)
  const [selectedEDFiles, setSelectedEDFiles] = useState([])
  const [selectedCDFiles, setSelectedCDFiles] = useState([])
  const [edNo, setEDNo] = useState("")
  const [dialogSendEmailOpen, setDialogSendEmailOpen] = useState(false)

  const uploadFile = useCallback(async (files, shpmFileTypId)=>{

    for (const file of files) {
      const data = new FormData();
      data.append("file", file);
      data.append("ShpFileTypId", shpmFileTypId);
      data.append("ShpInvId", selectedId);
      data.append("Label", file.label);
      await ax.post(shippingApiNm.uploadFile, data)
    }
  }, [ax, selectedId])
  const saveFile = useCallback(async () => {
    if (selectedEDFiles.length === 0) {
      alertError("กรุณาเลือกอย่างน้อย 1 ไฟล์ใบขนสินค้าก่อน")
      return false
    }
    const postDataUpdate = {
      ShpInvId: selectedId,
      ColValues: {
        EDNo: edNo,
      }
    }
    await ax.post(shippingApiNm.updateShippingInvoiceColumns, postDataUpdate)

    await ax.post(shippingApiNm.deleteFileByType, { ShpInvId: selectedId, ShpFileTypId: 2 })
    
    await uploadFile(selectedEDFiles, 2)
    const postData = {
      ShpInvId: selectedId,
      ColNm: "ED0209CreTm"
    }
    await ax.post(shippingApiNm.updateShippingInvoiceTime, postData)
    if(selectedCDFiles.length > 0){
      postData.ColNm = "CDCreTm"
      await ax.post(shippingApiNm.updateShippingInvoiceTime, postData)
      await uploadFile(selectedCDFiles, 3)
    }

    
    return true
  }, [ax, selectedEDFiles, selectedCDFiles, selectedId, uploadFile, edNo])

  const handleDialogOk = useCallback(async () => {

    const result = await saveFile()
    if(!result) return

    onFinish()
    setDialogOpen(false)
  }, [saveFile, onFinish, setDialogOpen])

  const handleSaveAndSend = useCallback(async () => {
    const result = await saveFile()
    if(!result) return

    // const postData = {
    //   ShpInvId: selectedId,
    //   ColNm: "ED0209SndTm",
    // }
    // await ax.post(shippingApiNm.sendEmail, postData)

    setDialogSendEmailOpen(true)  

    // onFinish()
    // setDialogOpen(false)

  }, [ saveFile])
  
  const onSendEmailDialogFinish = useCallback(() => {
    onFinish()
    setDialogOpen(false)
  }, [onFinish, setDialogOpen])
  const handelEDFilesChange = useCallback( async (files)=>{
    // console.log({files})
    // if(files && files.length > 0){
    //   // const arraybuffer = await files[0].arrayBuffer()
    //   // pdf(Buffer.from(arraybuffer)).then(function (data) {
    //   //   console.log(data.text);
    //   // })

    //   const reader = new FileReader();
    //   reader.onload = function(event) {
    //     const arrayBuffer = event.target.result;
        
    //     // pdf.getDocument(arrayBuffer).promise.then(function (data) {
    //     //   console.log(data)
    //     // })

    //     pdfjsLib.getDocument(arrayBuffer).promise.then(function (pdf) {
    //       console.log(pdf)
    //     }).catch(function (reason) {
    //       // PDF loading error
    //       console.error(reason);
    //     });
    //   }
    //   reader.readAsArrayBuffer(files[0]);
    // }
    
    // console.log({blob})
    // if(files && files.length > 0){
    //   pdf(Buffer.from(files[0])).then(function (data) {
    //     console.log(data.text);
    //     // const edNo = data.text.match(/ED[0-9]{7}/g)
    //     // console.log({edNo})
    //     // if(edNo && edNo.length > 0){
    //     //   setEDNo(edNo[0])
    //     // }
    //   })
    // }
  }, [])
  useEffect(() => {
    if (dialogOpen) {
      setSelectedEDFiles([])
      setSelectedCDFiles([])
    }
  }, [dialogOpen, ax, selectedId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"ใบขน 0209 และ ใบกำกับตู้"}
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC mt={2} >
          <BoxFR sx={{ alignItems: "stretch" }} justifyContent="space-around" >
            <BoxFC sx={{gap:0}} >
              <Typography variant="h6" >ใบขน</Typography>
              <FileDropPanel width={400} height={200} 
                selectedFiles={selectedEDFiles} setSelectedFiles={setSelectedEDFiles} 
                onChange={handelEDFilesChange}/>
              <TextField label="เลขที่ใบขน" size="small" value={edNo} onChange={(e)=>setEDNo(e.target.value)} sx={{mt: 1}}/>
            </BoxFC>
            <BoxFC sx={{gap:0}}>
              <Typography variant="h6" >ใบกำกับตู้</Typography>
              <FileDropPanel width={400} height="100%" selectedFiles={selectedCDFiles} setSelectedFiles={setSelectedCDFiles} />
            </BoxFC>
          </BoxFR>

        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleDialogOk} >
        <Button variant="contained" color="secondary" onClick={handleSaveAndSend}>บันทึกและส่ง Email</Button>
      </DialogFooter>
      <SendEmailDialog 
        shpInvId={selectedId}
        dialogOpen={dialogSendEmailOpen}
        setDialogOpen={setDialogSendEmailOpen}
        colNm="ED0209CreTm"
        onFinish={onSendEmailDialogFinish}
        />
    </Dialog>
  )
}

export default ED0209Dialog