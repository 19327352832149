import { Box, Button, Checkbox, Collapse, Dialog, DialogContent, Divider, IconButton, ImageList, ImageListItem, ImageListItemBar, TextField, Typography } from "@mui/material";

import { BoxFC, BoxFR } from "components/BoxCustom";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import React, { useMemo, useState } from "react";
import { useContext } from "react";
import { useEffect } from "react";
import { jobOrderApiNm, jobOrderColNm } from "constants/jobOrderConstant";
import dayjs from "dayjs";
import { useCallback } from "react";
import SaveButton from "components/buttons/SaveButton";
import { DeleteForeverRounded, DeselectRounded, PhotoRounded, SelectAllRounded } from "resource/muiImport";
import { alertConfirm, alertConfirmDelete, alertSuccess, alertWarning } from "components/Alert";
import DriveFileMoveIcon from '@mui/icons-material/DriveFileMove';
import { containerNoCheck } from "utils";
import AddButton from "components/buttons/AddButton";
import AddJobOrderFileDialog from "../DailyJob/AddJobOrderFileDialog";
import { blueGrey, grey } from "@mui/material/colors";
import FullScreenImage from "pages/FullScreenImage/FullScreenImage";

const initDialogData = { ContNo: "", SealNo: "", TareWt: "" }
const JobOrderImageDialog = ({ dialogOpen, setDialogOpen, onFinish, selectedId, contNo, isFromSubcontract }) => {

  const { ax, setTargetTimestamp, setBadge } = useContext(GlobalStateContext)

  const [jobOrdFiles, setJobOrdFiles] = useState([])
  const [dialogData, setDialogData] = useState({ ...initDialogData })
  const [isShowMoveImage, setIsShowMoveImage] = useState(false);
  const [moveJobOrdId, setMoveJobOrdId] = useState("")
  const [addJobOrderFileDialogOpen, setAddJobOrderFileDialogOpen] = useState(false)
  const [curImage, setCurImage] = useState("")

  const isSelectedAll = useMemo(() => {
    return jobOrdFiles.length > 0 && jobOrdFiles.every(item => !item.checked)
  }, [jobOrdFiles])
  const textFieldProp = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    label: jobOrderColNm[name],
    value: dialogData[name] || "",
    sx: { width: 250 },
    onChange: (e) => {
      setDialogData(o => ({ ...o, [name]: e.target.value }))
    },
    onBlur: (e) => {
      const value = e.target.value.toUpperCase();
      if (name === "ContNo") {
        containerNoCheck(value)
      }
      setDialogData(o => ({ ...o, [name]: value }))
    }
  }), [dialogData])

  const getData = useCallback(() => {
    ax.post(jobOrderApiNm.getJobOrderFile, { JobOrdIds: selectedId }).then(value => {
      if (value.data && value.data.length > 0) {
        const files = value.data
        let contNo = "";
        let sealNo = "";
        let tareWt = "";
        for (const file of files) {
          if (file.OCRTxt) {
            if (contNo === "" || sealNo === "" || tareWt === "") {
              // console.log("in if")
              try {
                const ocrObj = JSON.parse(file.OCRTxt)
                // console.log("ocrObj::", ocrObj)
                if (ocrObj.ContNo && contNo === "") contNo = ocrObj.ContNo
                if (ocrObj.SealNo && sealNo === "") sealNo = ocrObj.SealNo
                if (ocrObj.TareWt && tareWt === "") tareWt = ocrObj.TareWt
              } catch (e) {
                console.log("parse error", e)
              }
            } else {
              break;
            }
          }

          file.checked = false;
        }
        if (contNo !== "" || sealNo !== "" || tareWt !== "") {
          setDialogData({
            ContNo: contNo,
            SealNo: sealNo,
            TareWt: tareWt
          })
        }
        setJobOrdFiles(files)
        // setJobOrdFiles([files[0], files[1]])
      }
    })
  }, [ax, selectedId])
  const handleImageDelete = useCallback((file) => () => {
    alertConfirmDelete(() => {
      ax.post(jobOrderApiNm.deleteJobOrderFile, { JobOrdFileId: file.JobOrdFileId, getArgs: { JobOrdId: selectedId } }).then(value => {
        if (value.data) {
          if (value.data.length > 0) {
            setJobOrdFiles(value.data[0].Files)
          } else {
            setJobOrdFiles([])
            setDialogOpen(false);
            onFinish();
          }
        }
      })
    })
  }, [ax, selectedId, onFinish, setDialogOpen])

  const handleConfirmMoveImageClick = useCallback(() => {
    console.log("jobOrderFiles:: ", jobOrdFiles)
    const jobOrdFileIds = jobOrdFiles.filter(item => item.checked).map(item => item.JobOrdFileId)
    if (jobOrdFileIds.length === 0) {
      alertWarning("กรุณาเลือกรูปก่อน")
      return
    }
    if (moveJobOrdId.trim().length === 0) {
      alertWarning("กรุณาระบุเลขที่ใบงาน")
      return
    }
    const postData = {
      JobOrdId: moveJobOrdId,
      JobOrdFileIds: jobOrdFileIds,
      getArgs: { JobOrdId: selectedId }
    }
    console.log("postData;:", postData)
    ax.post(jobOrderApiNm.moveJobOrderFile, postData).then(value => {
      if (value.data) {
        if (value.data.length > 0) {
          setJobOrdFiles(value.data[0].Files)
          setIsShowMoveImage(false)
          setMoveJobOrdId("");
        } else {
          setJobOrdFiles([])
          setDialogOpen(false);
          onFinish();
        }
        alertSuccess("ย้ายรูปเรียบร้อยแล้ว")
      }
    })
  }, [ax, moveJobOrdId, jobOrdFiles, onFinish, selectedId, setDialogOpen])

  const confirmSave = useCallback(() => {
    const jsondata = {
      JobOrdId: selectedId,
      ColValues: {
        ContNo: dialogData.ContNo,
        SealNo: dialogData.SealNo,
        TareWt: dialogData.TareWt,
      },
      LogRmk: "แก้ไขข้อมูลตู้",
      withBadge: 1
    }
    ax.post(jobOrderApiNm.updateJobOrderColumn, jsondata).then(value => {
      console.log("in updateJobOrderColumn value::", value)
      if (value.data) {
        setTargetTimestamp(dayjs())
        setDialogOpen(false)
        setBadge(o => {
          const newData = { ...o, jobOrderImage: value.data.badgeNum }
          localStorage.setItem("badgeData", JSON.stringify(newData))
          return newData
        })
        onFinish()
      }
    })
  }, [ax, dialogData, onFinish, selectedId, setDialogOpen, setTargetTimestamp, setBadge])

  const handleSave = useCallback(() => {
    let msg = ""
    console.log("dialogData.ContNo::", dialogData.ContNo)
    const checkResult = containerNoCheck(dialogData.ContNo, false)
    console.log("checkResult::", checkResult)
    if (!containerNoCheck(dialogData.ContNo, false)) {
      msg += "หมายเลขตู้ไม่ถูกต้อง"
    }
    console.log("msg::", msg)
    if (contNo !== "" && msg === "") {
      msg += "ใบงานนี้มีหมายเลขตู้อยู่แล้ว ต้องการแทนที่ด้วยหมายเลขตู้ใหม่หรือไม่"
    } else if (contNo !== "" && msg !== "") {
      msg += " และใบงานนี้มีหมายเลขตู้อยู่แล้ว ต้องการแทนที่ด้วยหมายเลขตู้ใหม่หรือไม่"
    } else if (contNo === "" && msg !== "") {
      msg += " ต้องการบันทึกหรือไม่"
    }
    if (msg !== "") {
      alertConfirm(msg, confirmSave)

    } else {
      confirmSave()
    }

  }, [contNo, confirmSave, dialogData.ContNo])

  const handleMoveButtonClick = useCallback(() => {
    setIsShowMoveImage(o => {
      const result = !o;
      if (!result) {
        setMoveJobOrdId("")
      }
      return result;
    })
  }, [])

  const handleMoveJobOrdIdChange = useCallback((e) => {
    setMoveJobOrdId(e.target.value)
  }, [])

  const handleCheckboxChagne = useCallback((item) => (e) => {
    item.checked = e.target.checked
    setJobOrdFiles(o => [...o])
  }, [])

  const handleAddFile = useCallback(() => {
    setAddJobOrderFileDialogOpen(true)
  }, [])

  const localOnFinish = useCallback(() => {
    getData()
  }, [getData])

  const handleSelctAll = useCallback(() => {
    setJobOrdFiles(o => o.map(item => ({ ...item, checked: isSelectedAll })))
  }, [isSelectedAll])

  useEffect(() => {
    if (dialogOpen) {
      getData()
    } else {
      setDialogData(initDialogData)
      setJobOrdFiles([])
      setCurImage("")
    }
  }, [getData, dialogOpen])

  console.log("jobOrdFiles::", jobOrdFiles)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl'
      PaperProps={{
        style: {
          maxHeight: 900,
          minHeight: 900,
        }
      }} >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={`รูปภาพ (${jobOrdFiles.length} รูป)`}
        onDeleteClick={null}
      />
      <DialogContent sx={{ pb: 0 }}>
        <BoxFR mt={1} height="100%" sx={{ alignItems: "stretch" }}>
          <BoxFC sx={{ gap: 1, width: 550 }}>
            <BoxFR>
              <AddButton onClick={handleAddFile} >เพิ่มรูป</AddButton>
              <Button variant="contained" onClick={handleSelctAll} sx={{ width: 160 }}>
                {isSelectedAll ? <SelectAllRounded sx={{ mr: 1 }} /> : <DeselectRounded sx={{ mr: 1 }} />}
                {isSelectedAll ? "เลือกทั้งหมด" : "ไม่เลือกทั้งหมด"}</Button>
              {!isFromSubcontract ?
                <BoxFR>
                  <Button variant="contained" onClick={handleMoveButtonClick}> <DriveFileMoveIcon sx={{ mr: 1 }} /> ย้านรูปที่เลือกไปใบงานอื่น</Button>
                  <Collapse orientation="horizontal" in={isShowMoveImage}>
                    <BoxFR>
                      <TextField
                        size="small"
                        label="เลขที่ใบงาน"
                        type="number"
                        value={moveJobOrdId}
                        onChange={handleMoveJobOrdIdChange} sx={{ width: 150 }} />
                      <Button variant="contained" sx={{ width: 120 }} onClick={handleConfirmMoveImageClick}>ยื่นยันการย้าย</Button>
                    </BoxFR>
                  </Collapse>
                </BoxFR> : null
              }
            </BoxFR>
            <ImageList cols={2} rowHeight={300} sx={{ width: "100%", m: 0, height: 610, bgcolor: blueGrey[50], p: 1, borderRadius: 1 }}>
              {jobOrdFiles.map(file => {

                const src = `/file/job-order/${selectedId}/${file.FileNm}`
                // const linkUrl = src.replaceAll("/", "~")
                const ocrObj = JSON.parse(file.OCRTxt) || {}
                // console.log("linkUrl::", linkUrl)
                return (
                  <ImageListItem key={file.FileNm} sx={{ position: "relative", "& .MuiImageListItemBar-root": { px: 5 } }} >
                    <img alt={file.Label} src={src} loading="lazy" onClick={() => { setCurImage(src) }} style={{ height: 300 }} />

                    <ImageListItemBar
                      sx={{ px: 6, "& .MuiImageListItemBar-titleWrap": { px: 0, py: 0.5 }, }}
                      title={`${file.Label}`}
                      subtitle={
                        <BoxFC sx={{ gap: 0.5 }}>
                          <span>by: {file.ModAccNNm} {dayjs(file.AddTm).format("DD/MM/YYYY HH:mm")}</span>
                          {Object.keys(ocrObj).map(key => (<span key={key}>{key}: {ocrObj[key]}</span>))}
                          {/* <div>{file.OCRTxt || "-"}</div> */}
                        </BoxFC>
                      }
                      // position="below"
                      actionIcon={
                        <BoxFR position="absolute" top={0} left={0} width="100%">
                          <Checkbox
                            checked={file.checked}
                            onChange={handleCheckboxChagne(file)}
                            sx={{
                              p: 0, mt: 0, ml: 1,
                              color: "white",
                              '&.Mui-checked': {
                                color: "white",
                              },
                            }}
                          />
                          <Box flex={1} />
                          <IconButton onClick={handleImageDelete(file)}><DeleteForeverRounded sx={{ p: 0, mt: 0, color: "white" }} /></IconButton>
                        </BoxFR>}
                      actionPosition="left"
                    />
                  </ImageListItem>
                )
              })}
            </ImageList>
            <Divider />
            <BoxFC sx={{ gap: 1 }} mt={0}>
              <BoxFR>
                <TextField  {...textFieldProp("ContNo")} />
                <TextField  {...textFieldProp("SealNo")} />
              </BoxFR>
              <BoxFR>
                <TextField  {...textFieldProp("TareWt")} />
                <SaveButton onClick={handleSave} sx={{ width: 250 }} />
              </BoxFR>
            </BoxFC>
          </BoxFC>
          <BoxFC flex={1} bgcolor={blueGrey[50]} borderRadius={1} height={770} width="100%"
            sx={{ justifyContent: "center", alignItems: "center" }}>
            {curImage ?
              <FullScreenImage linkUrl={curImage} />
              // <img alt="รูปภาพที่เลือก"
              //   src={curImage}
              //   loading="lazy"
              //   style={{ height: 815, width: "100%", objectFit: "contain" }}
              //   onClick={() => window.open(`/image/${curImage.replaceAll("/", "~")}`)} />
              :
              <BoxFC sx={{ gap: 0 }} justifyContent="center" alignItems="center" width="100%" height="100%">
                <PhotoRounded sx={{ fontSize: 50, color: grey[500] }} />
                <Typography variant="h6" sx={{ color: grey[500] }}>กรุณาเลือกรูป</Typography>
              </BoxFC>
            }

          </BoxFC>
        </BoxFR>

        <AddJobOrderFileDialog
          selectedId={selectedId}
          dialogOpen={addJobOrderFileDialogOpen}
          setDialogOpen={setAddJobOrderFileDialogOpen}
          onFinish={localOnFinish} />
      </DialogContent >
      <DialogFooter okText=""
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog >
  )
}

export default React.memo(JobOrderImageDialog)