import { numberFormat, numberFormatNoFlotingPoint } from "utils";
import { packingListColNm } from "./constant";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { RemoveCircleRounded } from "resource/muiImport";

export const plColumns = (handleDeleteRow) => [
  {
    field: 'No',
    headerName: "No.",
    width: 70,
  },
  {
    field: 'Mrk',
    headerName: packingListColNm.Mrk,
    width: 90,
    editable: true,
  },
  {
    field: 'Dscp',
    headerName: packingListColNm.Dscp,
    minWidth: 140,
    flex: 1,
    editable: true,
  },
  {
    field: 'QtyCT',
    headerName: packingListColNm.QtyCT,
    width: 90,
    align: "center",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
    editable: true,
  },
  {
    field: 'QtyPCS',
    headerName: packingListColNm.QtyPCS,
    width: 90,
    align: "center",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
    editable: true,
  },
  {
    field: 'Unit',
    headerName: packingListColNm.Unit,
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
    editable: true,
  },
  {
    field: 'Price',
    headerName: packingListColNm.Price,
    width: 90,
    align: "right",
    valueFormatter: (params) => params.value ? `${+params.value}` : "",
    editable: true,
  },
  {
    field: 'Amnt',
    headerName: packingListColNm.Amnt,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? `${numberFormat(+params.value)}` : "",
    editable: true,
  },
  {
    field: 'NW',
    headerName: packingListColNm.NW,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    editable: true,
  },
  {
    field: 'GW',
    headerName: packingListColNm.GW,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    editable: true,
  },
  {
    field: 'QtyM',
    headerName: packingListColNm.QtyM,
    width: 95,
    align: "center",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    editable: true,
  },
  {
    field: 'M3',
    headerName: packingListColNm.M3,
    width: 110,
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    editable: true,
  },
  {
    field: 'Rmk',
    headerName: packingListColNm.Rmk,
    width: 150,
    editable: true,
  },
  {
    field: "actions",
    type: "actions",
    width: 30,
    hide: handleDeleteRow?false: true,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<RemoveCircleRounded />}
        label="Delete"
        onClick={() => handleDeleteRow(params.row)}
      />
    ]
  },
]