
import React, { useContext } from "react";
import { Button, Checkbox, IconButton, Popover, Tooltip, Typography } from '@mui/material';
import { useTheme } from "@mui/material/styles";
import { BoxFC, BoxFR } from 'components/BoxCustom';
import BookmarkBorderRoundedIcon from '@mui/icons-material/BookmarkBorderRounded';
import BookmarkRoundedIcon from '@mui/icons-material/BookmarkRounded';
import { Link } from 'react-router-dom';
import { blueGrey, grey, purple, red, yellow } from '@mui/material/colors';
import { Box } from "@mui/system";
import { locationText } from "utils/locationText";
import dayjs from "dayjs";
import { GlobalStateContext } from "contexts/GlobalStateContext";

const sxGap0 = { gap: 0 }

const DataBoxAttn = ({ item }) => {
  return (
    <BoxFC sx={{
      border: `1px solid ${item.IsAttn === 1 ? purple[900] : red[900]}`,
      borderRadius: 2,
      bgcolor: item.IsAttn === 1 ? purple[600] : red[700],
      px: 0.5,
      alignItems: "center",
      minWidth: 150,
      color: "white",
      gap: 0
    }}>
      <Typography variant="body2">{item.StsNm}</Typography>
      {item.Dscp ? <Typography variant="body2">({item.Dscp})</Typography> : null}
    </BoxFC>
  )
}

const statusBoxProps = {
  ml: 0.2, width: 6, height: 16, borderRadius: 1
}
const DataBoxInner = ({ item, handles }) => {
  const { handleBkgClick, handleJobOrdIdClick
    , filterData, selectionPrint, setSelectionPrint, showCardDetail } = handles
  const { msData } = useContext(GlobalStateContext)
  const [anchorEl, setAnchorEl] = React.useState(null);

  const theme = useTheme();

  const handlePopoverOpen = (event) => {
    setAnchorEl(event.currentTarget);
  };

  const handlePopoverClose = () => {
    setAnchorEl(null);
  };
  const open = Boolean(anchorEl);

  let bgColor = "white"
  if (filterData.JobNo.indexOf(item.JobNo) > -1
    || filterData.CusId === item.CusId
    || filterData.JobId === item.JobId) {
    bgColor = yellow[100]
  } else if (item.hilightShpmId === item.ShpmId) {
    bgColor = yellow[100]
  } else if (item.RtnLevTm) {
    bgColor = grey[200]
  }
  const itemContStsObj = item.ContStses.reduce((prev, cur) => {
    prev[cur.ContStsId] = cur
    return prev
  }, {})
  // console.log("itemContStsObj::", itemContStsObj)
  // console.log("item::", item)
  return (
    <BoxFR
      sx={{
        border: `1px solid ${blueGrey[300]}`,
        borderRadius: 2,
        bgcolor: bgColor,
        px: 0.5,
        position: "relative",

      }}>
      <BoxFC sx={sxGap0}>
        <BoxFR sx={{ gap: 0 }}>
          {showCardDetail &&
            <BoxFR>
              <Checkbox sx={{ p: 0 }} checked={selectionPrint.includes(item.JobOrdId)}
                onChange={(e) => setSelectionPrint(o => {
                  return e.target.checked ? [...o, item.JobOrdId] : o.filter(j => j !== item.JobOrdId)
                })} />
            </BoxFR>
          }
          {
            msData.containerStatuses.map((sts, index) => (
              item.DrvId === itemContStsObj[sts.ContStsId]?.DrvId ?
                <Box key={sts.ContStsId}
                  sx={{ ...statusBoxProps, border: `1px solid ${theme.palette[sts.BgClr].main}`, bgcolor: theme.palette[sts.BgClr].main }} />
                :
                <Box key={sts.ContStsId}
                  sx={{ ...statusBoxProps, border: `1px solid ${theme.palette[sts.BgClr].main}`, bgcolor: "white" }} />
            ))
          }
          <Button sx={{ px: 0.5, py: 0, minWidth: 0 }} onClick={(e) => {
            e.stopPropagation()
            handleJobOrdIdClick(item.JobOrdId)
          }}>{item.JobOrdId}</Button>
          {item.JobSNm?.substring(0, 8)}
          {
            // item.ContStses.map(sts=><Typography key={sts.ContStsId} variant="body2" color={sts.BgClr} >|{sts.Abbr}</Typography>) 
          }
          {
            // msData.containerStatuses.map((sts, index) => (
            //   // <Box bgcolor="white">
            //     item.DrvId === itemContStsObj[sts.ContStsId]?.DrvId ?
            //     <Lens key={sts.ContStsId} color={sts.BgClr} sx={{ ml: index === 0 ? 0 : -1.5, border: "1px solid white", borderRadius: 10, bgcolor:  "white" }} />
            //     :
            //     <PanoramaFishEye key={sts.ContStsId} color={sts.BgClr} sx={{ ml: index === 0 ? 0 : -1.5 , border: "1px solid white", borderRadius: 10, bgcolor:  "white"}} />
            //   // </Box>
            // ))
          }
          {/* <Box flex={1} />
          <Tooltip title="ดึงงานกลับ" arrow placement="top" followCursor >
            <IconButton onClick={(e) => {
              e.stopPropagation()
              handleRemoveJobOrder(item)
            }} sx={{ p: 0, m: 0 }} >
              <RemoveCircleRoundedIcon />
            </IconButton>

          </Tooltip> */}
        </BoxFR>
        {/* <BoxFR sx={{gap:0, mt: -0.5, mb: showCardDetail?0: 0.5, pl: showCardDetail? 3: 0}}>

          {
            msData.containerStatuses.map((sts, index) => (
              item.DrvId === itemContStsObj[sts.ContStsId]?.DrvId ?
                <Box key={sts.ContStsId}
                  sx={{ ...statusBoxProps, border: `1px solid ${theme.palette[sts.BgClr].main}`, bgcolor: theme.palette[sts.BgClr].main }} />
                :
                <Box key={sts.ContStsId}
                  sx={{ ...statusBoxProps, border: `1px solid ${theme.palette[sts.BgClr].main}`, bgcolor: "white" }} />
            ))
          }
        </BoxFR> */}
        {showCardDetail &&
          <BoxFC sx={{ gap: 0 }}>
            <BoxFR>
              <Typography variant="body2">Apt: {item.AptTm ? dayjs(item.AptTm).format("DD/MM HH:mm") : " -"}</Typography>
            </BoxFR>
            <BoxFR>
              <Typography variant="body2">{item.ContSizeFull} {locationText(item, { numChar: 5 })}</Typography>
            </BoxFR>
            <BoxFR>
              <Typography variant="body2"><Link target="_blank" to={`/emily/DailyJob/JobNo/${item.JobNo}`} style={{ color: "#1976d2" }}>{item.JobNo}</Link> </Typography>
              <Tooltip title="ไฮไลท์บุคกิ้งเดียวกัน" arrow followCursor>
                <IconButton sx={{ p: 0, m: 0 }} onClick={handleBkgClick(item.hilightShpmId === item.ShpmId ? null : item.ShpmId)}>
                  {
                    item.ShpmId === item.hilightShpmId ?
                      <BookmarkRoundedIcon color="primary" /> :
                      <BookmarkBorderRoundedIcon color="primary" />
                  }
                </IconButton>
              </Tooltip>
            </BoxFR>
            <BoxFR>
              <Typography variant="body2">Clsg: {item.RtnDteEn ? dayjs(item.RtnDteEn).format("DD/MM HH:mm") : " -"}</Typography>
            </BoxFR>
          </BoxFC>
        }
        {/* <BoxFR sx={{ gap: 0.5 }}>
          <Box width={18} height={14} borderRadius={1} bgcolor={item.PlnClr} border={1} borderColor={grey[500]} />
          <Typography variant="body2"> {item.PlnTxt || "-"}</Typography>
        </BoxFR> */}
      </BoxFC>


      {item.Rmk && <Box>
        <Typography
          color={red[500]}
          onMouseEnter={handlePopoverOpen}
          onMouseLeave={handlePopoverClose}
          sx={{
            fontSize: "1.5rem",
            position: "absolute",
            top: -2,
            right: 3,
            cursor: "help"
          }}>*</Typography>
        <Popover
          id="mouse-over-popover"
          sx={{
            pointerEvents: 'none',
          }}
          open={open}
          anchorEl={anchorEl}
          anchorOrigin={{
            vertical: 'bottom',
            horizontal: 'center',
          }}
          transformOrigin={{
            vertical: 'top',
            horizontal: 'center',
          }}
          onClose={handlePopoverClose}
          disableRestoreFocus
        >
          <Typography sx={{ p: 1 }}>{item.Rmk}</Typography>
        </Popover>
      </Box>}
    </BoxFR>
  )
}
const DataBox = ({ dataArr, handles }) => {

  return (
    <BoxFR sx={{ gap: 0.2, flexWrap: "wrap" }} py={0.5} >
      {dataArr.map((item, index) => (
        item.Type === "Attn" ?
          <DataBoxAttn key={index} item={item} />
          :
          <DataBoxInner key={index} item={item} handles={handles} />
      ))}
    </BoxFR>
  )
}

export default DataBox