
import { IconButton, Paper, Typography } from "@mui/material";
import { blueGrey, red, yellow } from "@mui/material/colors";
import { Box } from "@mui/system";
import { BoxFC, BoxFR } from "components/BoxCustom";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import dayjs from "dayjs";
import React, { useMemo, useState } from "react";
import { jobColumns } from "./columns";
import { ExpandCircleDownRounded, RemoveCircleRounded } from "resource/muiImport";


const getRowClassName = (params) => {
  if (params.row.TakeDteEn && dayjs(params.row.TakeDteEn).isBefore(dayjs())) {
    return "red"
  } else if (params.row.TakeDteEn && dayjs(params.row.TakeDteEn).add(-3, "day").isBefore(dayjs())) {
    return "yellow"
  } else if (params.row.TakeDteSt && dayjs(params.row.TakeDteSt).isAfter(dayjs())) {
    return "grey"
  } else {
    return null
  }
}

const sxColor = {
  "& .grey": { bgcolor: blueGrey[100], '&:hover': { bgcolor: `${blueGrey[200]}!important` } },
  "& .yellow": { bgcolor: yellow[100], '&:hover': { bgcolor: `${yellow[200]}!important` } },
  "& .red": { bgcolor: red[100], '&:hover': { bgcolor: `${red[200]}!important` } }
}
const JobBox = ({ header, data, handleSelect, disableArrow, fullHeight }) => {
  const [expanded, setExpanded] = useState(false)

  const numCont = useMemo(() => {
    return data.reduce((prev, cur) => prev + cur.JobOrdIds.length, 0)
  }, [data])

  const flexObj = fullHeight? { flex: 1 } : {}
  // const header = useMemo(()=>{
  //   return msData.tags.find(tag=>tag.TagId === index)?.Name || "ไม่มี Tag"
  // }, [msData.tags, index])

  console.log("JobBox render data::", data)  
  return (
    <Paper elevation={3} sx={{ 
        p: 1, width: "100%", minWidth: 750,  height:expanded ? 800 : 300, transition: 'height 0.5s ease-in-out', ...flexObj  
      }}>
      <BoxFC sx={{ gap: 0, height: "100%"}}>
        <BoxFR>
          <Typography variant="h6">{header} ({numCont}เที่ยว)</Typography>
          <IconButton onClick={() => { setExpanded(o => !o) }}>
            {expanded ?
              <RemoveCircleRounded color="primary" /> :
              <ExpandCircleDownRounded color="primary" />
            }
          </IconButton>
        </BoxFR>
        <Box flex={1} sx={sxColor}>
          <DataGridCellExpand
            hideFooter
            rows={data}
            columns={jobColumns(handleSelect, disableArrow)}
            getRowClassName={getRowClassName}
            />
        </Box>
      </BoxFC>
    </Paper>
  )
}

export default JobBox