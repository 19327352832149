import { Box, Button, IconButton, Tooltip, Typography } from "@mui/material"
import { jobOrderColNm } from "constants/jobOrderConstant"
import { shipmentColNm } from "constants/shipmentConstant"
import dayjs from "dayjs"
import { ImageRounded, ArticleRounded, EditRounded, LocalShippingRounded, FmdGoodRounded, DescriptionRounded
  , MarkEmailReadRounded, MailOutlineRounded, BookmarkAddedRounded, BookmarkBorderRounded, CreditScoreRounded
  , CreditCardRounded, CheckCircleRounded, CancelRounded } from "resource/muiImport"
import { SHIPMENT_TYPE } from "constants/serverConstant"
import { BoxFC, BoxFR } from "components/BoxCustom"
import { getPOIPlace } from "utils/getPOIPlace"
import { locationText } from "utils/locationText"
import CheckCircleRoundedIcon from '@mui/icons-material/CheckCircleRounded';
import LensIcon from '@mui/icons-material/Lens';
import PanoramaFishEyeIcon from '@mui/icons-material/PanoramaFishEye';
import { GridActionsCellItem } from "@mui/x-data-grid"
import { dateTimeDiff } from "utils"
import { subcontractJobColNm } from "constants/subcontractConstant"
import GridCellExpand from "components/DataGridCellExpand/GridCellExpand"
import { driverColNm } from "constants/driverConstant"
import { truckColNm } from "constants/truckConstant"
import { red } from "@mui/material/colors"




const iconProps = {
  color: "primary",
  sx: { width: 30, height: 30 }
}
const iconPropsDisable = {
  color: "disabled",
  sx: { width: 30, height: 30 }
}
const calculateBgColor = ({ row }) => {
  if (!row.ShorData || row.ShorData.length === 0) {
    return null
  } else {
    let data = []
    if (row.ShorData.length === 1) {
      data = row.ShorData[0]
    } else {

      let maxData = row.ShorData[0]

      for (let i = 1; i < row.ShorData.length; i++) {
        if (!maxData?.TagData && !row.ShorData[i]?.TagData) {
          continue
        } else if (!maxData?.TagData && row.ShorData[i]?.TagData) {
          maxData = row.ShorData[i]
        } else if (row.ShorData[i]?.TagData && dayjs(row.ShorData[i].TagData.PCItmTm).isAfter(dayjs(maxData.TagData.PCItmTm))) {
          maxData = row.ShorData[i]
        }
      }
      data = maxData
    }
    // console.log("data", data, row)
    if (!data.TagData) {
      return "";
    } else if (data.TagData?.Vssl === row.Vssl && data.TagData?.RtnPOIId === row.RtnPOIId) {
      return "palegreen"
    } else {
      return "hotpink"
    }
  }
  // if (!params.row.ShorId) {
  //   return null
  // } else {
  //   const row = params.row
  //   if (row.ShVssl === row.Vssl
  //     && ( row.RtnPOIId === row.ShRtnPOIId ||(!row.RtnPOIId && !row.ShRtnPOIId))) {
  //     return "palegreen"
  //   } else {
  //     return "hotpink"
  //   }
  //   // if (row.ShVssl !== row.Vssl || ( row.RtnPOIId !== row.ShRtnPOIId || row.ShRtnPlc !== row.RtnPlc)) {
  //   //   return "hotpink"
  //   // } else {
  //   //   return "palegreen"
  //   // }
  // }
}

export const columns = (driverComboObj, handleDriverClick, handlePlanColorClick, handleImageClick, handleOpenDailyJobDialog, handleOpenMap
  , handleEditTextClick, handlePlnDriverClick) => [
    {
      headerName: "ข้อมูล",
      field: "actions",
      type: "actions",
      width: 30,
      getActions: (params) => [
        <GridActionsCellItem
          icon={<ArticleRounded color="primary" />}
          label="info"
          onClick={() => handleOpenDailyJobDialog(params.id)}
        />
      ]
    },
    {
      field: "No",
      headerName: "No.",
      width: 60,
      align: "center",
    },
    // {
    //   field: "DXAmnt",
    //   headerName: jobOrderColNm.DXAmnt,
    //   width: 100,
    //   align: "right",
    //   valueGetter: params => params.value * -1,
    // },
    // {
    //   field: "DXNName",
    //   headerName: "พนักงานขับรถ(เงิน)",
    //   width: 100,
    //   // cellClassName: (params)=> params.row.DXIsPaid === 0?'purple-column': ""
    // },
    {
      field: "AdvAmnts",
      headerName: "เงินล่วงหน้า",
      width: 100,
      align: "right",
      valueGetter: params => params.value,
    },
    {
      field: "AdvUsrNNms",
      headerName: "ผู้รับเงิน",
      width: 100,
      // cellClassName: (params)=> params.row.DXIsPaid === 0?'purple-column': ""
    },
    {
      field: "JobNo",
      headerName: jobOrderColNm.JobNo,
      width: 120,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "JobOrdId",
      headerName: jobOrderColNm.JobOrdId,
      width: 100,
      align: "center",
      renderCell: (params) => (
        <Button variant="contained" size="small">{params.value}</Button>
      )
    },
    {
      field: "ShpmTypAbbr",
      headerName: jobOrderColNm.ShpmTypNm,
      width: 100,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "AptTm",
      headerName: "เวลานัด",
      width: 100,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    },
    {
      field: "IsRdyTake",
      headerName: "สถานะ",
      width: 70,
      align: "center",
      renderCell: (params) => {
        if (params.row.ShpmTypId === SHIPMENT_TYPE.EXPORT) {
          let icon = null
          if (params.row.ContDoc) {
            icon = <DescriptionRounded  {...iconProps} />
          } else if (params.row.IsSndMail) {
            icon = <MarkEmailReadRounded  {...iconProps} />
          } else if (params.row.ContNo) {
            icon = <MailOutlineRounded {...iconPropsDisable} />
          } else if (params.row.IsRdyTake) {
            icon = <BookmarkAddedRounded {...iconProps} />
          } else {
            icon = <BookmarkBorderRounded {...iconPropsDisable} />
          }
          return <BoxFR bgcolor={calculateBgColor(params)}
            flex={1} alignSelf="stretch" justifyContent="center">{icon}</BoxFR>
        } else if (params.row.ShpmTypId === SHIPMENT_TYPE.IMPORT) {
          if (params.row.IsRdyTake) {
            return <CreditScoreRounded {...iconProps} />
          } else {
            return <CreditCardRounded {...iconPropsDisable} />
          }
        } else {
          if (params.row.IsRdyTake) {
            return <CheckCircleRounded color="success" sx={{ width: 30, height: 30 }} />
          } else {
            return <CancelRounded color="action" sx={{ width: 30, height: 30 }} />
          }
        }
      },
    },
    {
      field: "ContSizeFull",
      headerName: jobOrderColNm.ContSize,
      width: 80,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "TlrTyp",
      headerName: jobOrderColNm.TlrTyp,
      width: 90,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "ContNo",
      headerName: jobOrderColNm.ContNo,
      width: 130,
      renderCell: (params) => {
        return (
          <Button fullWidth sx={{ height: 30 }} variant={params.value ? "contained" : "outlined"} >{params.value}</Button>
        )
      },
    },
    {
      field: "SealNo",
      headerName: "ซีล",
      width: 100,
      renderCell: (params) => {
        return (
          <Button fullWidth sx={{ height: 30 }} variant={params.value ? "contained" : "outlined"} >{params.value}</Button>
        )
      },
    },
    {
      field: "NumFile",
      headerName: "รูปภาพ",
      width: 70,
      renderCell: (params) => (
        <IconButton onClick={handleImageClick(params.id, params.row)}>
          <ImageRounded color={params.value ? "primary" : "inherit"} />
        </IconButton>
      )
    },
    {
      field: "TareWt",
      headerName: jobOrderColNm.TareWt,
      width: 90,
    },
    {
      field: "FullWt",
      headerName: jobOrderColNm.FullWt,
      width: 90,
    },
    {
      field: "ISOCd",
      headerName: jobOrderColNm.ISOCd,
      width: 90,
    },
    {
      field: "TakePlc",
      headerName: jobOrderColNm.TakePlc,
      valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
      width: 90,
    },
    {
      field: "Loc",
      headerName: jobOrderColNm.Loc,
      valueGetter: (params) => getPOIPlace(params.row, "LOC"),
      width: 120,
    },
    {
      field: "RtnPlc",
      headerName: jobOrderColNm.RtnPlc,
      valueGetter: (params) => getPOIPlace(params.row, "RTN"),
      width: 90,
    },
    {
      field: "TakeDteSt",
      headerName: jobOrderColNm.TakeDteSt,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "TakeDteEn",
      headerName: jobOrderColNm.TakeDteEn,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "RtnDteSt",
      headerName: jobOrderColNm.RtnDteSt,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "RtnDteEn",
      headerName: jobOrderColNm.RtnDteEn,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "ContStses",
      headerName: "พนักงานขับรถ",
      width: 340,
      valueGetter: (params) => params.value.map(item => item.DrvNNm || "---").join(","),
      renderHeader: (params) => {
        // console.log("header params", params)
        return (
          <BoxFC width="100%" sx={{ gap: 0 }}>
            <Typography variant="body2" flex={1} textAlign="center">พนักงานขับรถ:คน/เที่ยว</Typography>
            <BoxFR >
              {params.colDef.headerNameData.map(sts => (
                <Typography key={sts} variant="body2" flex={1} textAlign="center">{sts}</Typography>
              ))}
            </BoxFR>
          </BoxFC>
        )
      },
      renderCell: (params) => {
        return (
          <Box display="flex" gap={0.4} width="100%" >
            {
              params.row.ContStses.map((item, index) => (
                // <Box key={index} sx={{
                //   display: "flex",
                //   justifyContent: "center",
                //   alignItems: "center",
                //   color: "white",
                //   width: 60,
                //   height: 30,
                //   backgroundColor: item.DrvNNm? item.Clr: "transparent",
                //   border: "2px solid ",
                //   borderColor: item.Clr,
                //   borderRadius: 2,
                //   overflow: "hidden"
                // }}> {item.DrvNNm} </Box>
                <Button key={item.ContStsId} sx={{
                  minWidth: 60, height: 30, flex: 1, justifyContent: 'flex-start'
                  , overflow: 'hidden', whiteSpace: 'nowrap'
                }}
                  variant={item.DrvNNm ? "contained" : "outlined"}
                  color={item.Clr}
                  onClick={() => handleDriverClick(item.ContStsId, params.row)}>
                  {item.DrvNNm /* {driverComboObj[item.DrvId]?.label || ""} */}

                </Button>
              ))
            }
          </Box>
        )
      },
    },
    {
      field: "ArvTm",
      headerName: jobOrderColNm.ArvTm,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "LevTm",
      headerName: jobOrderColNm.LevTm,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "TakeArvTm",
      headerName: jobOrderColNm.TakeArvTm,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "TakeLevTm",
      headerName: jobOrderColNm.TakeLevTm,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "RtnArvTm",
      headerName: jobOrderColNm.RtnArvTm,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "RtnLevTm",
      headerName: jobOrderColNm.RtnLevTm,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "FinDte",
      headerName: jobOrderColNm.FinDte,
      type: 'dateTime',
      valueGetter: (params) => params.value && dayjs(params.value),
      valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
      width: 100,
    },
    {
      field: "Bkg",
      headerName: jobOrderColNm.Bkg,
      width: 120,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "Agnt",
      headerName: shipmentColNm.Agnt,
      width: 100,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "Vssl",
      headerName: shipmentColNm.Vssl,
      width: 100,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "Voy",
      headerName: shipmentColNm.Voy,
      width: 80,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "Port",
      headerName: shipmentColNm.Port,
      width: 80,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "JobSNm",
      headerName: jobOrderColNm.JobSNm,
      width: 120,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "CusSNm",
      headerName: jobOrderColNm.CusSNm,
      width: 120,
      headerClassName: 'grey-column',
      cellClassName: 'grey-column'
    },
    {
      field: "PlnTxt",
      headerName: jobOrderColNm.PlnTxt,
      width: 300,
      editable: true,
      renderCell: (params) => {
        return (

          <GridCellExpand width={300} value={params.value}>
            <Box display="flex" gap={0.5} justifyContent="flex-start" alignItems="center">
              <IconButton sx={{ p: 0 }} onClick={(e) => handlePlanColorClick(e, params.row)}>
                <Box className="hover-pointer" sx={{
                  width: 20,
                  height: 20,
                  backgroundColor: params.row.PlnClr,
                  border: "1px solid ",
                  borderColor: "text.disabled",
                  borderRadius: 1.4
                }} />
              </IconButton>

              <IconButton sx={{ p: 0 }} onClick={() => handlePlnDriverClick(params)}>
                <LocalShippingRounded />
              </IconButton>
              <IconButton sx={{ p: 0 }} onClick={() => handleEditTextClick(params)}>
                <EditRounded />
              </IconButton>
              {params.row.PlnData ? <Typography variant="body2" color={params.row.PlnData.PlnClr}>
                [{params.row.PlnData.DrvNNm}]&nbsp;</Typography> : null}
            </Box>
          </GridCellExpand>

        )
      },
    },
    {
      field: "Rmk",
      headerName: jobOrderColNm.Rmk,
      width: 300,
      editable: true,
      renderCell: (params) => {
        return (
          <GridCellExpand width={300} value={params.value}>
            <IconButton sx={{ p: 0 }} onClick={() => handleEditTextClick(params)}>
              <EditRounded />
            </IconButton>
          </GridCellExpand>
        )
      },
    },
    {
      field: "Memo",
      headerName: jobOrderColNm.Memo,
      valueGetter: (params) => params.value?.map(item => item.Memo)?.join(", "),
      width: 300,
    },
    {
      field: "JoRef1",
      headerName: jobOrderColNm.JoRef1,
      width: 100,
      editable: true,
      renderCell: (params) => {
        return (
          <GridCellExpand width={300} value={params.value}>
            <IconButton sx={{ p: 0 }} onClick={() => handleEditTextClick(params)}>
              <EditRounded />
            </IconButton>
          </GridCellExpand>
          // <Box display="flex" gap={1} justifyContent="flex-start" alignItems="center">
          //   <IconButton sx={{ p: 0 }} onClick={() => handleEditTextClick(params)}>
          //     <EditRounded />
          //   </IconButton>
          //   {params.value || ""}
          // </Box>
        )
      },
    },
    {
      field: "JoRef2",
      headerName: jobOrderColNm.JoRef2,
      width: 100,
      editable: true,
      renderCell: (params) => {
        return (
          <GridCellExpand width={300} value={params.value}>
            <IconButton sx={{ p: 0 }} onClick={() => handleEditTextClick(params)}>
              <EditRounded />
            </IconButton>
          </GridCellExpand>
          // <Box display="flex" gap={1} justifyContent="flex-start" alignItems="center">
          //   <IconButton sx={{ p: 0 }} onClick={() => handleEditTextClick(params)}>
          //     <EditRounded />
          //   </IconButton>
          //   {params.value || ""}
          // </Box>
        )
      },
    },
    {
      field: "ShRef1",
      headerName: jobOrderColNm.ShRef1,
      width: 100,
    },
    {
      field: "ShRef2",
      headerName: jobOrderColNm.ShRef2,
      width: 100,
    },
    {
      field: "TagNm",
      headerName: jobOrderColNm.TagNm,
      width: 100,
    },
    {
      field: "DrvNNm",
      headerName: "ผู้รับผิดชอบ",
      width: 100,
    },
    {
      field: "TukCd",
      headerName: "เลขรถ",
      width: 100,
      renderCell: (params) => (
        <BoxFR sx={{ gap: 0 }}>
          {params.value}
          {params.value ?
            <IconButton onClick={handleOpenMap(params)}>
              <FmdGoodRounded color="primary" />
            </IconButton>
            : null
          }
        </BoxFR>
      )
    },
    {
      field: "LicNm",
      headerName: "ทะเบียนรถ",
      width: 80,
      renderCell: (params) => (
        <BoxFR sx={{ gap: 0 }}>
          {params.value}
          {params.value ?
            <IconButton onClick={handleOpenMap(params)}>
              <FmdGoodRounded color="primary" />
            </IconButton>
            : null
          }
        </BoxFR>
      )
    },
    {
      field: 'LastKnowPOISNm',
      headerName: 'สถานที่ล่าสุด',
      width: 100,
      valueGetter: (params) => params.row.FinDte ? "-" : params.value,
    },
    {
      field: 'LastKnowPOITm',
      headerName: 'เวลาล่าสุด',
      width: 100,
      valueGetter: (params) => {
        if (params.row.LastKnowPOISNm && params.value && !params.row.FinDte) {
          return `(${params.row.GPSPOIId === params.row.LastKnowPOIId ? "อยู่" : "ออก"})${dateTimeDiff(dayjs(), dayjs(params.value))}`
        } else {
          return "-"
        }
      }
    },
    {
      field: "InvNote",
      headerName: jobOrderColNm.InvNote,
      width: 100,
      editable: true,
      renderCell: (params) => {
        return (
          <Box display="flex" gap={1} justifyContent="flex-start" alignItems="center">
            <IconButton sx={{ p: 0 }} onClick={() => handleEditTextClick(params)}>
              <EditRounded />
            </IconButton>
            {params.value || ""}
          </Box>
        )
      },
    },
  ]
//----------------------------------------------------end columns-----------------------------------------


export const jobOrdLogcolumns = [
  {
    field: "No",
    headerName: "No.",
    width: 45,
    align: "center",
  },
  {
    field: "ModDte",
    headerName: "เวลาแก้ไข",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "LogFlg",
    headerName: "ประเภท",
    width: 100,
    align: "center",
    valueFormatter: (params) => {
      if (params.value === 'A') return "เพิ่ม"
      else if (params.value === 'E') return "แก้ไข"
      else if (params.value === 'D') return "แก้ไข"
      else return "-"
    }
  },
  {
    field: "LogRmk",
    headerName: "การแก้ไข",
    width: 150,
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "IsRdyTake",
    headerName: "พร้อมรับ",
    width: 70,
    align: "center",
    renderCell: (params) => {
      return params.value === 1 ? <CheckCircleRounded color="success" /> : <CancelRounded color="action" />
    },
  },
  {
    field: "IsSndMail",
    headerName: "ส่งEmail",
    width: 70,
    align: "center",
    renderCell: (params) => {
      return params.value === 1 ? <CheckCircleRounded color="success" /> : <CancelRounded color="action" />
    },
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSize,
    width: 80,
    headerClassName: 'grey-column',
    cellClassName: 'grey-column'
  },
  {
    field: "TlrTyp",
    headerName: jobOrderColNm.TlrTyp,
    width: 90,
    headerClassName: 'grey-column',
    cellClassName: 'grey-column'
  },
  {
    field: "ContNo",
    headerName: jobOrderColNm.ContNo,
    width: 130,
  },
  {
    field: "SealNo",
    headerName: "ซีล",
    width: 100,
  },
  {
    field: "TareWt",
    headerName: jobOrderColNm.TareWt,
    width: 90,
  },
  {
    field: "FullWt",
    headerName: jobOrderColNm.FullWt,
    width: 90,
  },
  {
    field: "ISOCd",
    headerName: jobOrderColNm.ISOCd,
    width: 90,
  },
  {
    field: "TakePlc",
    headerName: jobOrderColNm.TakePlc,
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 90,
  },
  {
    field: "Loc",
    headerName: jobOrderColNm.Loc,
    valueGetter: (params) => getPOIPlace(params.row, "LOC"),
    width: 120,
  },
  {
    field: "RtnPlc",
    headerName: jobOrderColNm.RtnPlc,
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 90,
  },
  {
    field: "TakeDteSt",
    headerName: jobOrderColNm.TakeDteSt,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "TakeDteEn",
    headerName: jobOrderColNm.TakeDteEn,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "RtnDteSt",
    headerName: jobOrderColNm.RtnDteSt,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "RtnDteEn",
    headerName: jobOrderColNm.RtnDteEn,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "ArvTm",
    headerName: jobOrderColNm.ArvTm,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "LevTm",
    headerName: jobOrderColNm.LevTm,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "FinDte",
    headerName: jobOrderColNm.FinDte,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "PlnTxt",
    headerName: jobOrderColNm.PlnTxt,
    width: 300,
  },
  {
    field: "PlnClr",
    headerName: jobOrderColNm.PlnTxt,
    width: 300,
    renderCell: (params) => {
      return (
        <Box display="flex" gap={1} justifyContent="flex-start" alignItems="center">
          <Box className="hover-pointer" sx={{
            width: 20,
            height: 40,
            backgroundColor: params.row.PlnClr,
            border: "1px solid ",
            borderColor: "text.disabled",
            borderRadius: 1.4
          }} />
        </Box>
      )
    },
  },
  {
    field: "Rmk",
    headerName: jobOrderColNm.Rmk,
    width: 300,
  },
  {
    field: "JoRef1",
    headerName: jobOrderColNm.JoRef1,
    width: 100,
  },
  {
    field: "JoRef2",
    headerName: jobOrderColNm.JoRef2,
    width: 100,
  },
]

export const jobOrdTransferColumns = (toggleContSts) => [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "JobOrdId",
    headerName: jobOrderColNm.JobOrdId,
    width: 100,
  },
  {
    field: "JobSNm",
    headerName: jobOrderColNm.JobSNm,
    width: 100,
  },
  {
    field: "AptTm",
    headerName: "เวลานัด",
    width: 100,
    type: 'dateTime',
    valueFormatter: (params) => params.value ? dayjs(params.value).format("DD/MM HH:mm") : "-",
  },
  {
    field: "ContSizeFull",
    headerName: jobOrderColNm.ContSize,
    width: 80,
  },
  {
    field: "Location",
    headerName: "สถานที่",
    valueGetter: (params) => locationText(params.row),
    flex: 1,
  },
  {
    field: "ContSts",
    headerName: "สถานะ",
    width: 120,
    renderCell: (params) => {
      const msData = JSON.parse(localStorage.getItem("msData"))
      const contStsesMaster = msData.containerStatuses.filter(sts => !sts.IsHide)
      const contStses = params.row.ContStses || []
      const contStsObj = contStses.reduce((prev, cur) => {
        prev[cur.ContStsId] = cur
        return prev
      }, {})
      return (
        <BoxFR sx={{ gap: 0 }}>
          {contStsesMaster.map(contSts => {
            const contStsId = contSts.ContStsId
            return contStsObj[contStsId] ?
              <IconButton key={contStsId} sx={{ p: 0 }} onClick={toggleContSts(contStsObj[contStsId])}>
                {contStsObj[contStsId].IsCmpt ?
                  <CheckCircleRoundedIcon color={contSts.BgClr} />
                  :
                  <LensIcon color={contSts.BgClr} />
                }
              </IconButton>
              :
              <PanoramaFishEyeIcon key={contStsId} color={contSts.BgClr} />
          })}
        </BoxFR>
      )
    }
  },
]

export const scJobcolumns = [
  {
    field: 'ShowDetail',
    headerName: '',
    width: 40,
    align: 'center',
    renderCell: (params) => {
      return (
        <IconButton size="small" >
          <ArticleRounded color="primary" />
        </IconButton>
      )
    }
  },
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 70,
    align: 'center',
  },
  {
    field: 'ContSize',
    headerName: "ขนาดตู้",
    width: 80,
    align: 'center'
  },
  {
    field: 'ContTyp',
    headerName: "ประเภทตู้",
    width: 100,
    align: 'center'
  },
  {
    field: 'TlrTyp',
    headerName: "ประเภทหาง",
    width: 100,
    align: 'center'
  },
  {
    field: 'UntPr',
    headerName: subcontractJobColNm.UntPr,
    width: 120,
    align: 'right',
  },
  {
    field: 'Prty',
    headerName: subcontractJobColNm.Prty,
    width: 90,
    align: 'center',
  },
  {
    field: 'Rmk',
    headerName: subcontractJobColNm.Rmk,
    flex: 1,
  }
]

export const planDriverColumsn =  [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 60,
    align: 'center',
  },
  {
    field: 'GrpNm',
    headerName: driverColNm.GrpNm,
    width: 60,
    align: 'center',
  },
  {
    field: 'NName',
    headerName: jobOrderColNm.DrvNm,
    width: 120,
    renderCell: ({row}) => (
      <BoxFR >
        <Typography variant="body2">{row.NName}</Typography>
        {row.DrvIsAval === 0 ? 
          <Tooltip placement="top" followCursor title={`${row.DrvAttnStsNm}${row.DrvAttnDscp? `(${row.DrvAttnDscp})`: ""}`}>
            <Typography variant="h6" sx={{cursor: "default"}} color={red[500]}>*</Typography>
          </Tooltip>: null}
      </BoxFR>
    )
  },
  {
    field: 'TukCd',
    headerName: truckColNm.Code,
    width: 100,
    renderCell: ({row}) => (
      <BoxFR >
        <Typography variant="body2">{row.TukCd}</Typography>
        {row.TukIsAval === 0 ? 
          <Tooltip placement="top" followCursor title={`${row.TukAttnStsNm}${row.TukAttnDscp? `(${row.TukAttnDscp})`: ""}`}>
            <Typography variant="h6" sx={{cursor: "default"}} color={red[500]}>*</Typography>
          </Tooltip>: null}
      </BoxFR>
    )
  },
  {
    field: 'yd',
    headerName: 'งานเมื่อวาน',
    // width: 300,
    flex: 1,
    renderCell: ({row}) => row.YdJO.length> 0? 
    <BoxFR sx={{gap: 0}}>
      {row.YdJO.length > 0? <Typography variant="body2" >({row.YdJO.length})-</Typography>: null}
      {row.YdJO.map((item)=>(<Typography key={item.JobOrdId} variant="body2" color={item.PlnClr}>[{item.Text}]&nbsp;</Typography>)) }
    </BoxFR>:null
  },
  {
    field: 'td',
    headerName: 'งานวันนี้',
    // width: 300,
    flex: 1,
    renderCell: ({row}) => row.TdJO.length> 0? 
    <BoxFR sx={{gap: 0}}>
      {row.TdJO.length > 0? <Typography variant="body2" >({row.TdJO.length})-</Typography>: null}
      {row.TdJO.map((item)=>(<Typography key={item.JobOrdId} variant="body2" color={item.PlnClr}>[{item.Text}]&nbsp;</Typography>)) }
    </BoxFR>:null
  },
  {
    field: 'plan',
    headerName: 'plan วันนี้',
    // width: 300,
    flex: 1,
    renderCell: ({row}) => row.PlnJO.length> 0? 
    <BoxFR sx={{gap: 0}}>
      {row.PlnJO.length > 0? <Typography variant="body2" >({row.PlnJO.length})-</Typography>: null}
      {row.PlnJO.map((item)=>(<Typography key={item.JobOrdId} variant="body2" color={item.PlnClr}>[{item.Text}]&nbsp;</Typography>)) }
    </BoxFR>:null
  },
  {
    field: 'LastKnowPOISNm',
    headerName: "สถานที่ล่าสุด",
    width: 120,
  },
  {
    field: 'LastKnowPOITm',
    headerName: "เวลาล่าสุด",
    width: 120,
    valueGetter: (params) => {
      if (params.row.LastKnowPOISNm && params.value && !params.row.FinDte) {
        return `(${params.row.GPSPOIId === params.row.LastKnowPOIId ? "อยู่" : "ออก"})${dateTimeDiff(dayjs(), dayjs(params.value))}`
      } else {
        return "-"
      }
    }
  },
]