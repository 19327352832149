import { Dialog, DialogContent, TextField } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { initDriverAdvancePay } from "./initData"
import dayjs from "dayjs"
import { pettyCashApiNm, pettyCashColNm } from "pages/Menu1/PettyCash/constant"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import { branchApiNm } from "branch/constant/branchApiNm"
import { alertConfirmDelete, alertError } from "components/Alert"
import { UserContext } from "contexts/UserContext"
import ComboBox from "components/ComboBox"
import { bahtProps } from "utils/bahtProps"

const DriverAdvancePayDialog = ({ pcTrnsId, dialogOpen, setDialogOpen, onFinish }) => {

  const { user } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initDriverAdvancePay })

  const dateTimePickerProp = useCallback((name, disabled) => ({
    label: pettyCashColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    disableOpenPicker: disabled,
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} fullWidth required={true} error={!dialogData[name]} 
      disabled={disabled}/>,
  }), [dialogData])

  const textFieldProps = useCallback((name) => ({
    fullWidth: true,
    size: "small",
    label: pettyCashColNm[name],
    value: dialogData[name],
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value })),
  }), [dialogData])

  const handleSave = useCallback(() => {
    console.log("validate::", dialogData)
    if (!dialogData.Amnt || !dialogData.DrvId || !dialogData.PayTm || !dialogData.PCJnlId) {
      alertError("กรุณาตรวจสอบข้อมูล")
      return
    }

    if (!pcTrnsId) {
      const postdata = {
        AdmAccId: user.AccId,
        UsrAccId: dialogData.DrvId,
        InOutTyp: "O",
        ExpId: 79,
        PCJnlId: dialogData.PCJnlId,
        JobOrdId: null,
        PayTm: dayjs(dialogData.PayTm).format("YYYY-MM-DD HH:mm:ss"),
        Dscp: dialogData.Dscp,
        Amnt: dialogData.Amnt,
        WhtPrct: 0,
        VatPrct: 0,
        IsOwnRcpt: 0,
        IsCmpt: 1,
        IsCost: 1,
        IsReim: 1,
        getArgs: null
      }
      ax.post(pettyCashApiNm.insertPCTransaction, postdata).then(value => {
        if (value.data) {
          onFinish()
          setDialogOpen(false)
        }
      })
    } else {
      ax.post(branchApiNm.updateDriverPaymentAmnt, {...dialogData}, false).then(value => {
        if (value.data) {
          onFinish()
          setDialogOpen(false)
        }
      })
    }

  }, [ax, onFinish, dialogData, user.AccId, setDialogOpen, pcTrnsId])

  const handleDelete = useCallback(() => {
    alertConfirmDelete(()=>{
      ax.post(branchApiNm.deleteDriverPayment, {PCTrnsIds: [dialogData.PCTrnsId]}).then(value=>{
        if(value.data){
          onFinish()
          setDialogOpen(false)
        }
      })
    })
  }, [dialogData.PCTrnsId, ax, onFinish, setDialogOpen])

  useEffect(() => {
    if (dialogOpen) {
      if (pcTrnsId) {
        ax.post(branchApiNm.getDriverAdvancePay, { PCTrnsId: pcTrnsId }).then(value => {
          if (value.data && value.data.length > 0) {
            setDialogData({ ...value.data[0]})
          }
        })
      } else {
        setDialogData({ ...initDriverAdvancePay, PayTm: dayjs() })
      }
    } else {
      setDialogData({ ...initDriverAdvancePay })
    }
  }, [pcTrnsId, dialogOpen, ax])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xs'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="การเบิกเงินล่วงหน้า"
        onDeleteClick={handleDelete}
      />
      <DialogContent>
        <BoxFC pt={2} px={5} sx={{ gap: 1 }} alignItems="center">
          <DateTimePickerCustomTime {...dateTimePickerProp("PayTm", dialogData.PCPrdId?true:false)} />
          <ComboBox options={msData.driverCombo} label={pettyCashColNm.UsrAccId} sx={{ width: "100%" }}
            selectedId={dialogData.DrvId}
            required={true}
            error={!dialogData.DrvId}
            setSelectedId={(id) => setDialogData(o => ({ ...o, DrvId: id }))}
          />
          <TextField {...textFieldProps("Amnt")} type="number" error={!dialogData.Amnt} {...bahtProps} 
            disabled={dialogData.PCPrdId?true: false}/>
          <ComboBox sx={{ width: "100%" }} options={msData.pcJournalCombo} label={pettyCashColNm.PCJnlNm}
            selectedId={dialogData.PCJnlId}
            setSelectedId={(id) => setDialogData(o => ({ ...o, PCJnlId: id }))}
            required={true}
            error={!dialogData.PCJnlId}
            disabled={dialogData.PCPrdId?true:false}
          />
          <TextField {...textFieldProps("Dscp")} />
        </BoxFC>
      </DialogContent>
      <DialogFooter okText="บันทึก"
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(DriverAdvancePayDialog)