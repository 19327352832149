import { Box, Button, ButtonGroup, CircularProgress, Fade, MenuItem, Snackbar, SnackbarContent, TextField, Tooltip, Typography } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { columns } from './columns';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { addIdForDataGrid, containerNoCheck } from 'utils';
import { jobOrderApiNm, jobOrderColNm } from 'constants/jobOrderConstant';
import { green, grey } from '@mui/material/colors';
import { CloudDoneRounded, CloudUploadRounded, ContentPasteRounded, InfoRounded } from "resource/muiImport";
import SelectDateTimeDialog from 'components/dialog/SelectDateTimeDialog';
import dayjs from 'dayjs';
import { alertConfirm, alertError } from 'components/Alert';
import HowToCliboardDialog from './HowToCliboardDialog';
import JobOrderImageDialog from '../JobOrderImage/JobOrderImageDialog';
import AddJobOrderFileDialog from '../DailyJob/AddJobOrderFileDialog';
import { UserContext } from 'contexts/UserContext';
import ComboBox from 'components/ComboBox';

let selectedId = null;
let lastFilter = null
let selectedField = ""
let selectedRow = null
const SubcontractJobOrder = () => {
  const { user } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [dialogSelectDateOpen, setDialogselectDateOpen] = useState(false)
  const [dialogHowToOpen, setDialogHowToOpen] = useState(false)
  const [dialogImageOpen, setDialogImageOpen] = useState(false)
  const [dialogAddJobOrderFileOpen, setDialogAddJobOrderFileOpen] = useState(false)
  const [snackSucessOpen, setSnackSucessOpen] = useState(false)
  const [snackUploadOpen, setSnackUploadOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })


  // const filterProps = useCallback((name)=>({
  //   size: 'small',
  //   value: filterData[name] || '',
  //   onChange: (e) => setFilterData(o => ({ ...o, [name]: e.target.value || null }))
  // }))
  // const openSanck = useCallback((msg) => {
  //   snackMsg = msg
  //   setSnackSucessOpen(true)
  // }, [])
  const getData = useCallback((filter) => {
    ax.post(jobOrderApiNm.getSubcontractJobOrder, filter).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, 'JobOrdId'))
        lastFilter = filter
      }
    })
  }, [ax])

  const handleCellClick = useCallback((params) => {
    selectedId = params.id
    selectedField = params.field
    selectedRow = params.row
    if (["LevTm", "ArvTm"].includes(params.field)) {
      setDialogselectDateOpen(true)
    } else if (params.field === "NumFile") {
      if (params.row.NumFile) {
        setDialogImageOpen(true)
      } else {
        setDialogAddJobOrderFileOpen(true)
      }
    }
  }, [])

  const onSelectDateFinish = useCallback((data) => {
    console.log("onSelectDateFinish", data)
    const postData = {
      JobOrdId: selectedId,
      ColValues: { [selectedField]: data ? dayjs(data).format("YYYY-MM-DD HH:mm:ss") : null },
      WithFamily: false,
      LogRmk: `แก้ไขข้อมูล ${jobOrderColNm[selectedField]}`
    }
    ax.post(jobOrderApiNm.updateJobOrderColumn, postData).then(value => {
      if (value.data) {
        setDataTable((o) => {
          const result = o.map((row) => ({ ...row }))
          const found = result.find((row) => row.id === selectedId)
          if (found) {
            found[selectedField] = data
          }
          return result
        })
      }
    })
  }, [ax])

  const handleEditCommit = useCallback(({ field, id, value }) => {
    // console.log(params)
    let apiNm = ""
    let postData = null
    let updateValue = value
    if (["ContNo", "SealNo", "TareWt"].includes(field)) {
      updateValue = value.toUpperCase()

      if (field === "ContNo") {
        containerNoCheck(updateValue)
      }
      apiNm = jobOrderApiNm.updateJobOrderColumn
      postData = {
        JobOrdId: id,
        ColValues: { [field]: updateValue },
        WithFamily: false,
        LogRmk: `แก้ไขข้อมูล ${jobOrderColNm[selectedField]}`
      }
    } else if (["DrvNm", "Tel", "LicNm", "TlrLicNm", "CitzId", "TukLicId"].includes(field)) {
      apiNm = jobOrderApiNm.updateSubcontractJobOrderColumn
      postData = {
        JobOrdId: id,
        ColValues: { [field]: value },
        LogRmk: `แก้ไขข้อมูล ${field}`
      }
    }

    if (apiNm === "") return

    setSnackUploadOpen(true)
    ax.post(apiNm, postData, false).then((res) => {
      setSnackUploadOpen(false)
      if (res.data) {
        setSnackSucessOpen(true)
        setDataTable((o) => {
          const result = o.map((row) => ({ ...row }))
          const found = result.find((row) => row.id === id)
          if (found) {
            found[field] = updateValue
          }
          return result
        })
      }
    })
  }, [ax])

  const pasteDataFromClipboard = useCallback(() => {
    const pasteCols = ["ContNo", "SealNo", "TareWt", "DrvNm", "Tel", "LicNm", "TlrLicNm", "CitzId", "TukLicId"]
    if (!pasteCols.includes(selectedField)) {
      alertError("ไม่สามารถวางข้อมูลในช่องนี้ได้")
      return
    }
    navigator.clipboard.readText().then(text => {
      const jobOrdArr = []
      const scDataArr = []
      const rows = text.split("\n")
      let runRow = 0
      const startColIndex = pasteCols.indexOf(selectedField)
      const startRowIndex = dataTable.findIndex(row => row.id === selectedId)
      for (const row of rows) {
        console.log("row", row)
        if (row.trim() === "") continue
        if (runRow >= dataTable.length - startRowIndex) break

        const cols = row.split("\t")
        let runCol = 0
        let joColValue = {}
        let scColValue = {}
        let hasJo = false
        let hasSc = false
        for (const col of cols) {
          if (runCol >= pasteCols.length) break
          const colNm = pasteCols[startColIndex + runCol]
          if (["ContNo", "SealNo", "TareWt"].includes(colNm)) {
            hasJo = true
            joColValue[pasteCols[startColIndex + runCol]] = col.trim()
          } else if (["DrvNm", "Tel", "LicNm", "TlrLicNm", "CitzId", "TukLicId"].includes(colNm)) {
            hasSc = true
            scColValue[pasteCols[startColIndex + runCol]] = col.trim()
          }
          runCol++
        }
        if (hasJo) {
          jobOrdArr.push({
            JobOrdId: dataTable[startRowIndex + runRow].id,
            ColValues: joColValue,
            WithFamily: false,
            LogRmk: `แก้ไขข้อมูลหมายเลขตู้`
          })
        }
        if (hasSc) {
          scDataArr.push({
            JobOrdId: dataTable[startRowIndex + runRow].id,
            ColValues: scColValue,
            LogRmk: `แก้ไขข้อมูลงานรถร่วม`
          })
        }
        runRow++
        console.log("colValues", joColValue, scColValue)

      }
      const postData = { jobOrdArr, scDataArr }
      console.log("postData", postData)
      ax.post(jobOrderApiNm.updateSubcontractJobOrderFromClipboard, postData).then((value) => {
        if (value.data) {
          getData(lastFilter)
          setSnackSucessOpen(true)
        }
      })
    })
  }, [ax, dataTable, getData])

  const onJobOrderImageFinish = useCallback(() => {
    getData(lastFilter)
  }, [getData])

  const handleUnlinkId = useCallback((row)=> () => {
    alertConfirm("ยืนยันตัดการเชื่อมโยงใบงาน", ()=>{
      const postData = {
        JobOrdId: row.JobOrdId,
        LnkId: row.LnkId,
        LnkSiteAbbr: row.LnkSiteAbbr
      }
      ax.post(jobOrderApiNm.unlinkId, postData).then(value=>{
        if(value.data){
          getData(lastFilter)
        }
      })
    }, null, "ตัดการเชื่อมโยงใบงาน", "ยกเลิก")
  }, [ax, getData])

  const columnsMemo = useMemo(() => {
    return columns(handleUnlinkId)
  }, [handleUnlinkId])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])
  return (
    <BoxFC height='100%'>
      <BoxFR>
        <ButtonGroup>
          <Button variant="contained" onClick={pasteDataFromClipboard}>
            <ContentPasteRounded sx={{ mr: 1 }} />
            วางข้อมูลจาก Clipboard
          </Button>
          <Button variant="contained" sx={{ minWidth: 0, p: 0 }} onClick={() => { setDialogHowToOpen(true) }}>
            <Tooltip title="วิธีวางข้อมูลจาก Clipboard" arrow placement='top'>
              <InfoRounded />
            </Tooltip>
          </Button>
        </ButtonGroup>
        <Typography variant='caption' sx={{ alignSelf: "flex-end" }}>(สามารถพิมพ์แก้ไขได้ในข่องสีขาว หรือ copy จาก Excel และ "กดปุ่มวางจาก CLIPBOARD")</Typography>
        <Box flex={1} />
        {user.AccTypId === 1 ?
          <BoxFR>
            <ComboBox sx={{ width: 150 }} options={msData.jobCombo} label="งาน"
              selectedId={filterData.JobId}
              setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
            />
            <TextField size='small' sx={{ width: 130 }} label="สถานะงาน" select
              value={filterData.IsFinDte} onChange={(e) => setFilterData(o => ({ ...o, IsFinDte: e.target.value }))}>
              <MenuItem value=''>ไม่ระบุ</MenuItem>
              <MenuItem value={1}>งานจบแล้ว</MenuItem>
              <MenuItem value={0}>งานยังไม่จบ</MenuItem>
            </TextField>
          </BoxFR> : null
        }
        <DateTimeSelect sx={{ width: 180 }} label='เวลานัด' start={filterData.AptTmSt} end={filterData.AptTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, AptTmSt: st, AptTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <SearchButton onClick={(() => getData(filterData))} />
        <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
      </BoxFR>
      <Box height='100%' sx={{
        "& .grey-cell": { bgcolor: grey[100] }
      }}>
        <DataGridCellExpand
          hideFooter
          rows={dataTable}
          columns={columnsMemo}
          onCellClick={handleCellClick}
          onCellEditCommit={handleEditCommit}
        />
      </Box>
      <Snackbar
        key="snackUpload"
        open={snackUploadOpen}
        onClose={() => setSnackSucessOpen(false)}
        sx={{ width: 100 }}
        TransitionComponent={Fade} >
        <SnackbarContent sx={{ bgcolor: grey[100], color: grey[800] }}
          message={<BoxFR><CloudUploadRounded sx={{ color: grey[800] }} /> <CircularProgress size={20} /> กำลังบันทึกข้อมูล...</BoxFR>} />
      </Snackbar>
      <Snackbar
        key="snackSuccess"
        open={snackSucessOpen}
        onClose={() => setSnackSucessOpen(false)}
        TransitionComponent={Fade}
        sx={{ width: 100 }}
        autoHideDuration={1200} >

        <SnackbarContent sx={{ bgcolor: green[100], color: grey[800] }}
          message={<BoxFR><CloudDoneRounded sx={{ mr: 1, color: grey[800] }} /> บันทึกข้อมูลเรียบร้อยแล้ว</BoxFR>}
        // message="✔ บันทึกข้อมูลเรียบร้อยแล้ว"
        />
      </Snackbar>
      <SelectDateTimeDialog
        title={`แก้ไข ${jobOrderColNm[selectedField]}`}
        label={jobOrderColNm[selectedField]}
        oldData={selectedRow && selectedRow[selectedField]}
        setDialogOpen={setDialogselectDateOpen}
        dialogOpen={dialogSelectDateOpen}
        onFinish={onSelectDateFinish}
      />
      <HowToCliboardDialog dialogOpen={dialogHowToOpen} setDialogOpen={setDialogHowToOpen} />

      <JobOrderImageDialog
        dialogOpen={dialogImageOpen}
        setDialogOpen={setDialogImageOpen}
        selectedId={selectedId}
        contNo={selectedRow?.ContNo}
        onFinish={onJobOrderImageFinish}
        lastFilter={lastFilter}
        isFromSubcontract={true}
      />
      <AddJobOrderFileDialog
        selectedId={selectedId}
        selectedRow={selectedRow}
        dialogOpen={dialogAddJobOrderFileOpen}
        setDialogOpen={setDialogAddJobOrderFileOpen}
        onFinish={onJobOrderImageFinish}
        lastFilter={lastFilter} />
    </BoxFC>
  );
}

export default SubcontractJobOrder

