import { PrintRounded } from "resource/muiImport";
import { Box, Button, TextField } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback, useState, useContext } from "react";
import JobBox from "./JobBox";
import CheckboxFormControl from "components/CheckboxFormControl";
// import { UserContext } from "contexts/UserContext";
import { printJobOrder } from "branch/functions/printJobOrder";
// import { isSamePlace } from "utils/isSamePlce";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { jobOrderApiNm } from "constants/jobOrderConstant";
import { alertError } from "components/Alert";
import AddDataDialog from "./AddDataDialog";

let selectedRow = []
const JobOrderNoDriver = ({ jobData, selectionDrvs, handleSelect, leftPanelWidth, confirmSelect }) => {

  const { ax, msData} = useContext(GlobalStateContext)
  // const { user } = useContext(UserContext)
  const [jobOrdId, setJobOrdId] = useState("")
  const [dialogAddDataOpen, setDialogAddDataOpen] = useState(false)
  // const [groupLocJobData, setGroupLocJobData] = useState(false)
  const [categoryTable, setcategoryTable] = useState(true)
  // const {msData} = useContext(GlobalStateContext)

  const handlePrintEmptyJobOrder = useCallback(() => {
    const printDataArr = [{
      jobOrderData: {
        AptTm: null,
        TalePlc:"",
        Loc: "",
        RtnPlc: "",
        ContSize: "",
        ContTyp: "",
        ContHq: "",
        ContNo: "",
        SealNo: "",
        Rmk:"",
        Ref1: "",
        CusNm: "",
      }, 
      shipmentData: {
        JobNo: "",
        CusNm: "",
        Bkg: "",
        Agnt: "",
        Job: {RcptNm:"", RcptAddr:"", RcptTaxId:"", RcptBrchNo: ""},
      }, 
      jobOrdIds:[""],
      isPrintDateTime: true, 
      fName: ""
    }]
    printJobOrder({printDataArr, msData})
  }, [msData, ])

  const handleGiveJobOrder = useCallback(() => {
    if(selectionDrvs.length > 1){
      alertError("กรุณาเลือกพนักงานขับรถไม่เกิน 1 คน")
      return
    }
    ax.post(jobOrderApiNm.getJobOrder, {JobOrdId: jobOrdId}).then(value => {
      if(value.data){
        if(value.data.length === 0){
          alertError("ไม่พบเลขที่ใบงานนี้")
          return
        }
        selectedRow = value.data[0]
        if(selectedRow.FinDte){
          alertError("ใบงานนี้ถูกลงจบแล้ว ไม่สามารถจ่ายใบงานได้อีก")
          return 
        }
        selectedRow.JobOrdIds = [jobOrdId]
        setDialogAddDataOpen(true)
      }
    })
  }, [ax, jobOrdId, selectionDrvs])
  // console.log("in JobOrderNoDriver Render jobData::", jobData)
  // console.log("in JobOrderNoDriver Render display::", displayJobData)
  // console.log("displayJobData::", displayJobData)
  return (

    <BoxFC sx={{pt:1, gap: 1, overflowY: "scroll", pr: 1, width: leftPanelWidth, height: "100%", transition: 'width 0.5s ease-in-out' }}>
      <BoxFR minWidth={750}>
        <Button variant="contained" onClick={handlePrintEmptyJobOrder}>
          <PrintRounded sx={{ mr: 1 }} /> พิมพ์ใบงานเปล่า
        </Button>
        <TextField label="เลขที่ใบงาน" size="small" type="number" sx={{width: 150}}
          onKeyUp={(e) => { if (e.key === "Enter") { handleGiveJobOrder() } }}
          value={jobOrdId} onChange={(e)=>setJobOrdId(e.target.value)}/>
        <Button onClick={handleGiveJobOrder} variant="contained">จ่ายใบงาน</Button>
        <Box flex={1} />
        <CheckboxFormControl checked={categoryTable} onChange={(e) => { setcategoryTable(e.target.checked) }} label="แยกประเภทงาน" />
      </BoxFR>
      {
        categoryTable ?
          <BoxFC sx={{ gap: 1 }}>
            <JobBox header="ไม่ระบุวันที่" data={jobData.nd} handleSelect={handleSelect} />
            <JobBox header="งานวันก่อน" data={jobData.yd} handleSelect={handleSelect} />
            <JobBox header="งานวันนี้" data={jobData.td} handleSelect={handleSelect} />
            <JobBox header="งานวันพรุ่งนี้" data={jobData.tm} handleSelect={handleSelect} />
            <JobBox header="งานวันหน้า" data={jobData.atm} handleSelect={handleSelect} />
          </BoxFC>
          :
          <JobBox header="งานทั้งหมด" data={jobData.all} handleSelect={handleSelect} fullHeight={true} />
      }
      <AddDataDialog
        dialogOpen={dialogAddDataOpen}
        setDialogOpen={setDialogAddDataOpen}
        row={selectedRow}
        confirmSelect={confirmSelect}
        selectionDrvs={selectionDrvs} />
    </BoxFC>
  )
}

export default JobOrderNoDriver