import { IconButton, Typography } from "@mui/material";
import { BoxFC, BoxFR, } from "components/BoxCustom";
import React, { useCallback, useMemo, useState } from "react";
import { addIdForDataGrid, moveRowTo } from "utils";
import { Box } from "@mui/system";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { favExpColumns } from "./columns";
import { AddCircleRounded } from "resource/muiImport";
import { expenseColNm } from "constants/expenseConstant";
import ComboBox from "components/ComboBox";

const FavoriteExpense = ({ favoriteExpense, setFavoriteExpense, msData }) => {

  const [expId, setExpId] = useState(null)

  const dataTable = useMemo(() =>
    addIdForDataGrid(favoriteExpense.map(item => ({ ...item, ExpNm: msData.expObj[item.ExpId].ExpNm })), "ExpId")
    , [favoriteExpense, msData.expObj])

  const expCombo = useMemo(() => {
    return msData.expCombo.filter(item => !item.isFav)
  }, [msData.expCombo])
  const handleRowOrderChange = useCallback((params) => {
    console.log(params)
    setFavoriteExpense(o => {
      const result = moveRowTo(o, params.oldIndex, params.targetIndex).map((row, index) => ({ ...row, order: index }))
      let no = 0;
      for (const row of result) {
        no++
        row.No = no
      }
      return result
    })
  }, [setFavoriteExpense])

  const handleAddExpense = useCallback(() => {
    if (!expId) return
    setFavoriteExpense(o => {
      if (o.find(e => e.ExpId === expId)) return o
      return [
        ...o,
        {
          id: expId,
          No: o.length + 1,
          ExpId: expId,
          ExpNm: msData.expCombo.find(e => e.id === expId).label,
        }
      ]
    })
  }, [setFavoriteExpense, expId, msData.expCombo])

  const handleRemoveRow = useCallback((row) => {
    setFavoriteExpense(o => o.filter(e => e.ExpId !== row.ExpId))
  }, [setFavoriteExpense])

  const favExpColumnsMemo = useMemo(() => favExpColumns(handleRemoveRow), [handleRemoveRow])

  console.log("favoriteExpense::", favoriteExpense)
  return (
    <BoxFC height="100%" sx={{ gap: 1 }}>
      <BoxFR>
        <Typography variant="h6">ค่าใช้จ่ายที่ใช้บ่อย</Typography>
        <ComboBox sx={{ width: 200 }} options={expCombo} label={expenseColNm.ExpNm}
          selectedId={expId}
          setSelectedId={(id) => setExpId(id)}
        />
        <IconButton onClick={handleAddExpense} sx={{ p: 0 }}>
          <AddCircleRounded fontSize="large" color="primary" />
        </IconButton>
      </BoxFR>
      <Box flex={1} width={550}>
        <DataGridCellExpand
          disableSelectionOnClick
          rowReordering
          onRowOrderChange={handleRowOrderChange}
          hideFooter
          hideToolbar
          rows={dataTable}
          columns={favExpColumnsMemo}
        />
      </Box>
    </BoxFC>
  )
}

export default React.memo(FavoriteExpense)