import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { maintenanceApiNm } from "../Checkup/constant"
import { itemColumns } from "./columns"
import { blue, yellow } from "@mui/material/colors"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import ImagePreview from "components/ImagePreview/ImagePreview"
import dayjs from "dayjs"
import CheckboxFormControl from "components/CheckboxFormControl"
import { PrintRounded } from "resource/muiImport"
import { printCheckupCheck } from "branch/functions/printCheckupCheck"
import { alertConfirmDelete } from "components/Alert"

const CheckupRecordDialog = ({ selectedId, dialogOpen, setDialogOpen, row, lastFilter, onFinish }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [files, setFiles] = useState([])
  const [isAprv, setIsAprv] = useState(false)

  const handleSave = useCallback(() => {
    const postData = {
      MtChkChkIds: [selectedId],
      IsAprv: isAprv,
      getArgs: lastFilter
    }
    ax.post(maintenanceApiNm.updateCheckupCheckApprove, postData).then(value => {
      if (value.data) {
        setDialogOpen(false)
        onFinish(value.data)
      }
    })
  }, [ax, isAprv, selectedId, setDialogOpen, lastFilter, onFinish])

  const handelPrint = useCallback(() => {
    ax.post(maintenanceApiNm.getMtCheckupCheckDetail, { MtChkChkId: selectedId}).then(value => {
      printCheckupCheck({data: value.data.checkupCheck, items: value.data.items})
    })
  }, [ax, selectedId])

  const handleDetete = useCallback(()=>{
    alertConfirmDelete(()=>{
      const postData = {
        MtChkChkId: selectedId,
        getArgs: lastFilter
      }
      ax.post(maintenanceApiNm.deleteCheckupCheck, postData).then(value=>{
        if(value.data){
          setDialogOpen(false)
          onFinish(value.data)
        }
      })
    })
  }, [ax, selectedId, setDialogOpen, onFinish, lastFilter])
  useEffect(() => {
    if (dialogOpen) {
      ax.post(maintenanceApiNm.getMtCheckupCheckDetail, { MtChkChkId: selectedId }).then(value => {
        if (value.data) {
          const modTable = []
          let lastCatId = 0
          let catIndex = 0
          let itemIndex = 0
          for(const item of value.data.items) {
            if(lastCatId !== item.MtChkCatId) {
              catIndex++
              itemIndex = 1
              modTable.push({
                id: `${item.MtChkCatId}:0`,
                No: catIndex,
                MtChkItmNm: item.MtChkCatNm,
                Chkd: null,
                IsCat: true
              })
              modTable.push({
                id: `${item.MtChkCatId}:${item.MtChkItmId}`,
                No: `${catIndex}.${itemIndex}`,
                MtChkItmNm: item.MtChkItmNm,
                Chkd: item.Chkd,
                IsCat: false
              })
              lastCatId = item.MtChkCatId
            } else {
              itemIndex++
              modTable.push({
                id: `${item.MtChkCatId}:${item.MtChkItmId}`,
                No: `${catIndex}.${itemIndex}`,
                MtChkItmNm: item.MtChkItmNm,
                Chkd: item.Chkd,
                IsCat: false
              })
            
            }
          }
          setDataTable(modTable)
          setFiles(value.data.files)
          setIsAprv(value.data.checkupCheck.IsAprv)
        }
      })
    } else {
      setDataTable([])
      setFiles([])
      setIsAprv(false)
    }
  }, [dialogOpen, ax, selectedId])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"รายการตรวจสภาพรถ"}
        onDeleteClick={handleDetete}
      >
        <Button sx={{ml: 2}} variant="contained" color="secondary" onClick={handelPrint}><PrintRounded sx={{ mr: 1 }} />พิมพ์รายงาน</Button>
      </DialogHeader>
      <DialogContent>
        <BoxFC pt={2} >
          <Typography variant="h6" sx={{ alignSelf: "center" }}>{row?.MtChkPlnNm ?? "-"}</Typography>
          <BoxFR justifyContent="space-between">
            <Typography><span style={{ color: blue[900], fontWeight: "bold" }}>วันที่ตรวจ: </span>{dayjs(row?.MtChkDte).format("DD/MM/YYYY")}</Typography>
            <Typography><span style={{ color: blue[900], fontWeight: "bold" }}>พนักงานขับรถ: </span>{row?.DrvNm}</Typography>
            <Typography><span style={{ color: blue[900], fontWeight: "bold" }}>ทะเบียนรถ: </span>{row?.LicNm}</Typography>
          </BoxFR>

          <BoxFR height={650} sx={{ alignItems: "stretch" }} >
            <BoxFC height="100%">
              <Box flex={1} width={450} sx={{ 
                "& .highlight": { bgcolor: yellow[100] },
                "& .highlight:hover": { bgcolor: yellow[200] },
                "& .cat-row": { fontWeight: "bold", bgcolor: blue[50], "&:hover": { bgcolor: blue[100]}}
                }}>
                <DataGridCellExpand
                  density="compact"
                  hideFooter
                  rows={dataTable}
                  columns={itemColumns}
                  getRowClassName={({ row }) => row.IsCat? "cat-row":row.Chkd !== 1 ? "highlight" : ""}
                />
              </Box>
              <CheckboxFormControl label="ยืนยันการตรวจสภาพรถ"
                checked={isAprv === 1} onChange={e => setIsAprv(e.target.checked ? 1 : 0)} />
            </BoxFC>
            <ImagePreview title="" imageItems={files} />
          </BoxFR>
        </BoxFC>

      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(CheckupRecordDialog)