import { RemoveCircleRounded } from "resource/muiImport"
import { Box, MenuItem, TextField } from "@mui/material"
import { GridActionsCellItem } from "@mui/x-data-grid"
import SwitchFormControl from "components/SwitchFormControl"
import { expenseColNm } from "constants/expenseConstant"

const boxClrSX = {
  border: "1px solid grey",
  width: "100%",
  height: 30,
  borderRadius: 1
}
const menuItemSX = {
  px: 1,
  py: 0.5
}
export const columns = (handleChangeCol) => [
  {
    field: "headerName",
    headerName: "ชื่อคอลัมน์",
    flex: 1
  },
  {
    field: "width",
    headerName: "ความกว้าง",
    width: 90,
    editable: true,
    type: "number"
  },
  {
    field: "visible",
    headerName: "แสดง/ซ่อน",
    width: 120,
    renderCell: (params) => (
      <SwitchFormControl label={params.value ? "แสดง" : "ซ่อน"}
        sx={{ width: "100%" }}
        checked={params.value}
        onChange={(e) => {
          handleChangeCol(params.id, "visible", e.target.checked)
          // setDailyTableColWidth(o => {
          //   item.visible = e.target.checked
          //   return [...o]
          // })
        }}
      />
    )
  },
  {
    field: "colClr",
    headerName: "สี",
    width: 100,
    renderCell: (params) => (
      <TextField variant="outlined" size="small" sx={{ width: "100%" }} select value={params.value || ""}
        onChange={e => handleChangeCol(params.id, params.field, e.target.value)}>

        <MenuItem sx={menuItemSX} value={null}><Box sx={{ ...boxClrSX, bgcolor: "white" }} /></MenuItem>
        <MenuItem sx={menuItemSX} value="whitesmoke-column-color"><Box sx={{ ...boxClrSX, bgcolor: "whitesmoke" }} /></MenuItem>
        <MenuItem sx={menuItemSX} value="LavenderBlush-column-color"><Box sx={{ ...boxClrSX, bgcolor: "LavenderBlush" }} /></MenuItem>
        <MenuItem sx={menuItemSX} value="Beige-column-color"><Box sx={{ ...boxClrSX, bgcolor: "Beige" }} /></MenuItem>
        <MenuItem sx={menuItemSX} value="Azure-column-color"><Box sx={{ ...boxClrSX, bgcolor: "Azure" }} /></MenuItem>
        <MenuItem sx={menuItemSX} value="Honeydew-column-color"><Box sx={{ ...boxClrSX, bgcolor: "Honeydew" }} /></MenuItem>
      </TextField>
    )
  },
]
export const favExpColumns = (handleRemoveRow) => [
  {
    field: "No",
    headerName: "No.",
    width: 50,
    align: 'center',
  },
  {
    field: "ExpNm",
    headerName: expenseColNm.ExpNm,
    flex: 1,
  },
  {
    field: "actions",
    type: "actions",
    width: 30,
    getActions: (params) => [
      <GridActionsCellItem
        icon={<RemoveCircleRounded />}
        label="Remove"
        onClick={() => handleRemoveRow(params.row)}
      />
    ]
  },
]