export const clearFilterData = {
  AptTmSt: null,
  AptTmEd: null,
  FinDteSt: null,
  FinDteEn: null,
  DrvId: null,
  SalDte: null,
  JobOrdId: null,
  ContNo: null,
  JobId: null,
  PaySts: null,
  IsFilterSalDte: 1
}

export const initFilterData = {
  ...clearFilterData
}

export const initAddDriverTripDialogData = {
  PayTm: "",
  UsrAccId: "",
  JobOrdId: null,
  Loc: "",
  ExpId: null,
  Amnt: 0,
  Dscp: "",
  Rmk: "",
}