
import { PrintRounded } from "resource/muiImport";
import { Button, ButtonGroup, Dialog, DialogContent, Divider, InputAdornment, TextField, Typography } from "@mui/material";
import { blue } from "@mui/material/colors";
import { alertError } from "components/Alert";
import { BoxFC, BoxFR } from "components/BoxCustom";
import CheckboxFormControl from "components/CheckboxFormControl";
import ComboBox from "components/ComboBox";
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime";
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader";
import PaperComponent from "components/PaperComponent";
import TextFieldWithOption from "components/TextFieldWithOption/TextFieldWithOption";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import dayjs from "dayjs";
import React, { useCallback, useContext, useMemo } from "react";
import { useEffect } from "react";
import { useState } from "react";

const initContSts = {
  IsChk: false,
  DrvId: null,
  TukId: null,
  StsTm: null,
}
let oContSts1 = { ...initContSts }
let oContSts3 = { ...initContSts }
let oContSts4 = { ...initContSts }
let oContSts5 = { ...initContSts }

const AddDataDialog = ({ dialogOpen, setDialogOpen, confirmSelect, row, selectionDrvs, isManualJobordId }) => {

  const { msData } = useContext(GlobalStateContext)


  const initDailogData = useMemo(() => ({
    DrvId: null,
    TukId: null,
    DrvActTm: null,
    Amnt: 0,
    Dacp: "",
    IsCash: +(msData.serverData.DefaultValue?.IsCash || 0),
    IsPaid: +(msData.serverData.DefaultValue?.IsPaid || 0),
    IsTake: 0,
    IsLoc: 0,
    IsBack: 0,
    IsRtn: 0,
    AptTm: null,
    JobOrdId: null,
    FuelAmnt: 0,
    RemainFuelAmnt: 0,
    NumJobOrd: 1,
  }), [msData.serverData])


  const [dialogData, setDialogData] = useState({ ...initDailogData })
  const [contSts1, setContSts1] = useState({ ...initContSts })
  const [contSts3, setContSts3] = useState({ ...initContSts })
  const [contSts4, setContSts4] = useState({ ...initContSts })
  const [contSts5, setContSts5] = useState({ ...initContSts })

  const drvNm = useMemo(() => {
    if (selectionDrvs.length === 0) {
      return ""
    } else if (selectionDrvs.length === 1) {
      const drv = msData.drivers.find(driver => driver.DrvId === selectionDrvs[0])
      if (!drv) return ""
      const tuk = msData.trucks.find(truck => truck.TukId === drv.TukId)
      const tukStr = tuk ? ` (${tuk.Code})` : ""
      return `${drv.FName} ${drv.LName}${tukStr}`
    } else {
      return `จำนวน ${selectionDrvs.length} คน`
    }
  }, [selectionDrvs, msData.drivers, msData.trucks])



  const datetimePickerProp = useCallback((name, label) => ({
    label: label,
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small" sx={{ width: 220 }}
      {...params} required={true} error={!dialogData[name]} />,
  }), [dialogData])

  const checkBoxProp = useCallback((state, setState, stsId) => ({
    checked: state.IsChk,
    onChange: (e) => setState(o => {
      if (e.target.checked) {
        let stsTm = dayjs()
        if(dialogData.AptTm && ["3", "4", "5"].includes(stsId)){
          stsTm = dayjs().isAfter(dayjs(dialogData.AptTm))? dayjs(): dayjs(dialogData.AptTm)
          setDialogData(o=>({...o, DrvActTm: stsTm}))
        }
        if (dialogData.DrvId) {
          return {
            IsChk: true,
            DrvId: dialogData.DrvId,
            TukId: msData.drivers.find(driver => driver.DrvId === dialogData.DrvId)?.TukId,
            StsTm: stsTm
          }
        } else {
          return { ...o, IsChk: true }
        }
      } else {
        if(stsId === "3"){
          setDialogData(o=>({...o, DrvActTm: dayjs()}))
        }
        return {
          IsChk: false,
          DrvId: row[`ContSts${stsId}`]?.DrvId,
          TukId: row[`ContSts${stsId}`]?.TukId,
          StsTm: row[`ContSts${stsId}`]?.StsTm || null
        }
      }
    })
  }), [dialogData.DrvId, dialogData.AptTm, msData.drivers, row])

  const contStsProp = useCallback((state, setState, name, label) => {
    return {
      label: label,
      sx: { width: 150 },
      options: label === "พนักงานขับรถ" ? msData.driverCombo : msData.truckCombo,
      selectedId: state[name],
      setSelectedId: (id) => setState(o => ({ ...o, [name]: id })),
    }
  }, [msData.driverCombo, msData.truckCombo])

  const dateTimePickerProp = useCallback((state, setState) => ({
    label: "เวลาวิ่งงาน",
    inputFormat: "DD/MM HH:mm",
    mask: "__/__ __:__",
    value: state.StsTm,
    onChange: (newValue) => { setState(o => ({ ...o, StsTm: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} />,
  }), [])

  const checkB4Confirm = useCallback(() => {
    if (selectionDrvs.length === 0 && !dialogData.DrvId) {
      alertError("กรุณาเลือกพนักงานขับรถก่อน")
      return false
    }

    const numDrvId = selectionDrvs.length === 0 ? 1 : selectionDrvs.length
    //row might be null in init
    if (row?.JobOrdIds.length < numDrvId * +dialogData.NumJobOrd) {
      alertError("จำนวนงานทั้งหมดมีน้อยกว่าจำนวนใบงานที่จะจ่าย")
      return false
    }
    return true
  }, [selectionDrvs, dialogData, row])

  const handleOk = useCallback(() => {
    if (!checkB4Confirm()) return

    const checkIsSame = (o, n)=>{
      const isSame =  o.DrvId === n.DrvId 
        && o.TukId === n.TukId 
        && o.StsTm ?"null" : dayjs(o.StsTm).format("YYYY-MM-DD HH:mm") 
          === n.StsTm? "null" :  dayjs(n.StsTm).format("YYYY-MM-DD HH:mm")
      return isSame ? n: null
    }
    confirmSelect({ 
      row, 
      data: { ...dialogData }, 
      contStses: {
        "1": checkIsSame(oContSts1, contSts1),
        "3": checkIsSame(oContSts3, contSts3),
        "4": checkIsSame(oContSts4, contSts4),
        "5": checkIsSame(oContSts5, contSts5),
      },
      isPrint: false })
    setDialogOpen(false)
  }, [dialogData, row, confirmSelect, contSts1, contSts3, contSts4, contSts5, setDialogOpen, checkB4Confirm])

  const handleOkWithPrint = useCallback(() => {
    if (!checkB4Confirm()) return
    confirmSelect({ row, data: { ...dialogData }, isPrint: true })
    setDialogOpen(false)
  }, [dialogData, row, confirmSelect, setDialogOpen, checkB4Confirm])

  const handleDriverSelect = useCallback((id) => {
    const tukId = msData.drivers.find(driver => driver.DrvId === id)?.TukId
    setDialogData(o => ({ ...o, DrvId: id, TukId: tukId }))
  }, [msData.drivers])

  useEffect(() => {
    if (dialogOpen) {
      let aptTm = row.AptTm ? dayjs(row.AptTm) : row.TakeDteSt ? dayjs(row.TakeDteSt) : null

      // const drvId = selectionDrvs?.length === 1? selectionDrvs[0] : null 
      setDialogData(o => ({
        ...o,
        AptTm: aptTm,
        DrvId: selectionDrvs.length === 1 ? selectionDrvs[0] : null,
        TukId: selectionDrvs.length === 1 ? msData.drivers.find(driver => driver.DrvId === selectionDrvs[0])?.TukId : null,
        DrvActTm: dayjs()
      }))
      if (row.ContSts1) {
        oContSts1 = { ...row.ContSts1, IsChk: false }
        setContSts1({...oContSts1})
      }
      if (row.ContSts3) {
        oContSts3 = { ...row.ContSts3, IsChk: false }
        setContSts3({...oContSts3})
      }
      if (row.ContSts4) {
        oContSts4 = { ...row.ContSts4, IsChk: false }
        setContSts4({...oContSts4})
      }
      if (row.ContSts5) {
        oContSts5 = { ...row.ContSts5, IsChk: false }
        setContSts5({...oContSts5})
      }
    } else {
      setDialogData({ ...initDailogData })
      setContSts1({...initContSts})
      setContSts3({...initContSts})
      setContSts4({...initContSts})
      setContSts5({...initContSts})
      oContSts1 = { ...initContSts }
      oContSts3 = { ...initContSts }
      oContSts4 = { ...initContSts }
      oContSts5 = { ...initContSts }
    }
  }, [dialogOpen, row, initDailogData, selectionDrvs, msData.drivers])

  console.log("row")
  return (

    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      PaperProps={{
        style: {
          width: 700,
          minWidth: 700,
        }
      }}>

      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="ข้อมูลการจ่ายงานและเงิน"
      />
      <DialogContent>
        <BoxFC mt={2} sx={{ gap: 2 }}>
          <Typography variant="h6">การจ่ายงาน {row && row.JobOrdIds?.length === 1 ? `(#${row.JobOrdIds[0]})` : ""}</Typography>
          <BoxFR>
            <TextField size="small" variant="outlined" label="จำนวนใบงาน" type="number" sx={{ width: 100 }}
              value={dialogData.NumJobOrd}
              disabled={row && row.JobOrdIds?.length === 1}
              onChange={e => setDialogData(o => ({ ...o, NumJobOrd: e.target.value }))} />
            <DateTimePickerCustomTime {...datetimePickerProp("AptTm", "เวลานัด")} />
          </BoxFR>
          <BoxFR>
            {
              selectionDrvs.length <= 1 ?
                <BoxFR>
                  <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label={"ผุ้รับผิดชอบ"}
                    disabled={selectionDrvs.length === 1}
                    selectedId={dialogData.DrvId}
                    setSelectedId={handleDriverSelect}
                  />
                  <ComboBox sx={{ width: 150 }} options={msData.truckCombo} label={"เลขรถ"}
                    selectedId={dialogData.TukId}
                    setSelectedId={(id) => setDialogData(o => ({ ...o, TukId: id }))}
                  />
                </BoxFR>
                :
                <BoxFR>
                  <Typography variant="h6">พนักงานขับรถ:</Typography>
                  <Typography variant="h6" color={blue[800]}>{drvNm}</Typography>
                </BoxFR>
            }

            <DateTimePickerCustomTime {...datetimePickerProp("DrvActTm", "เวลาวิ่งงาน")} />
          </BoxFR>

          <BoxFR>
            <BoxFC width={150} sx={{ gap: 1 }}>
              <CheckboxFormControl label={"รับ (ตู้/สินค้า)"} {...checkBoxProp(contSts1, setContSts1, "1")} />
              <ComboBox {...contStsProp(contSts1, setContSts1, "DrvId", "พนักงานขับรถ")} />
              <ComboBox {...contStsProp(contSts1, setContSts1, "TukId", "เลขรถ")} />
              <DateTimePickerCustomTime {...dateTimePickerProp(contSts1, setContSts1)} />
            </BoxFC>
            <BoxFC width={150} sx={{ gap: 1 }}>
              <CheckboxFormControl label={"ส่ง (ตู้/สินค้า)"} {...checkBoxProp(contSts3, setContSts3, "3")} />
              <ComboBox  {...contStsProp(contSts3, setContSts3, "DrvId", "พนักงานขับรถ")} />
              <ComboBox {...contStsProp(contSts3, setContSts3, "TukId", "เลขรถ")} />
              <DateTimePickerCustomTime {...dateTimePickerProp(contSts3, setContSts3)} />
            </BoxFC>
            <BoxFC width={150} sx={{ gap: 1 }}>
              <CheckboxFormControl label={"พักลาน"} {...checkBoxProp(contSts4, setContSts4, "4")} />
              <ComboBox {...contStsProp(contSts4, setContSts4, "DrvId", "พนักงานขับรถ")} />
              <ComboBox {...contStsProp(contSts4, setContSts4, "TukId", "เลขรถ")} />
              <DateTimePickerCustomTime {...dateTimePickerProp(contSts4, setContSts4)} />
            </BoxFC>
            <BoxFC width={150} sx={{ gap: 1 }}>
              <CheckboxFormControl label={"คืน (ตู้/สินค้า)"} {...checkBoxProp(contSts5, setContSts5, "5")} />
              <ComboBox {...contStsProp(contSts5, setContSts5, "DrvId", "พนักงานขับรถ")} />
              <ComboBox {...contStsProp(contSts5, setContSts5, "TukId", "เลขรถ")} />
              <DateTimePickerCustomTime {...dateTimePickerProp(contSts5, setContSts5)} />
            </BoxFC>

          </BoxFR>
          <Divider width="100%" sx={{ mt: 1 }} />
          <Typography variant="h6" mt={1}>การจ่ายเงิน</Typography>
          <BoxFR>
            <TextField size="small" variant="outlined"
              label="จำนวนเงิน" type="number"
              value={dialogData.Amnt} onChange={e => setDialogData(o => ({ ...o, Amnt: +e.target.value }))}
              onFocus={(e) => e.target.select()}
              sx={{ width: 130 }}
              inputProps={{ style: { textAlign: 'right' } }}
              InputProps={{ endAdornment: <InputAdornment position="end">บาท</InputAdornment> }} />

            <ButtonGroup variant="outlined" >
              <Button sx={{ px: 1 }} variant={dialogData.IsCash === 1 ? "contained" : "outlined"}
                onClick={() => setDialogData(o => ({ ...o, IsCash: 1 }))}>เงินสด</Button>
              <Button sx={{ px: 1 }} variant={dialogData.IsCash === 0 ? "contained" : "outlined"}
                onClick={() => setDialogData(o => ({ ...o, IsCash: 0 }))}>โอน</Button>
            </ButtonGroup>
            <TextFieldWithOption
              size="small" label="รายละเอียด" value={dialogData.Dscp} sx={{ flex: 1 }}
              setValue={(value) => setDialogData(o => ({ ...o, Dscp: value }))}
              options={msData.serverData.PreText.DailyExpense} />
          </BoxFR>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ตกลง"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleOk} >
        <Button variant="contained" color="secondary" onClick={handleOkWithPrint}>
          <PrintRounded sx={{ mr: 1 }} />
          ตกลงและพิมพ์ใบงาน
        </Button>
      </DialogFooter>
    </Dialog>
  )
}

export default React.memo(AddDataDialog)