const dayjs = require("dayjs")

export const clearFilterData = {
  PayTmSt: null,
  PayTmEn: null,
  AdmAccId: null,
  UsrAccId: null,
  InOutTyp: null,
  JobOrdId: null,
  PCJnlId: null,
  IsCmpt: null,
  JobId: null,
  ContNo: null,
  JobNo: null,
  ExpId: null,
  CtrlCd: null,
}
export const initFilterData = {
  ...clearFilterData,
  PayTmSt: dayjs().format('YYYY-MM-DD 00:00:00.000'),
  PayTmEn: dayjs().format('YYYY-MM-DD 23:59:59.997'),
}

export const initAdvanceDialogData = {
  UsrAccId: null,
  PayTm: dayjs().format('YYYY-MM-DD HH:mm'),
  ExpId: null,
  Dscp: "",
  JobOrdId: null,
  LocText: "",
  PCJnlId: null,
  Amnt: "",
  AdmAccId: null,
  Rmk: "",
  isJobOrdIdCorrect: null,
  InOutTyp: "O",
  IsCmpt: 0,
  IsCost: 0,
  VatPrct: 0,
  WhtPrct: 0,
  IsOwnRcpt: 0,
  PayAmnt: 0,
  PCPrdId: null,
  CtrlCd: "",
}

export const initReimbursementDialogData = {
  ...initAdvanceDialogData,
  DocNo: "",
  DocDte: dayjs().format('YYYY-MM-DD'),
  ReimDscp: "",
  ReimRmk: "",
}
export const initChangeDialogData = {
  ...initAdvanceDialogData,
  InOutTyp: "I",
  IsChangeWithdraw: false
}

export const initItemDialogData = {
  PayTm: dayjs().format('YYYY-MM-DD HH:mm'),
  PCItmTm: dayjs().format('YYYY-MM-DD HH:mm'),
  ExpId: null,
  Dscp: "",
  DocNo: "",
  JobOrdId: null,
  LocText: "",
  DocDte: dayjs().format('YYYY-MM-DD'),
  Amnt: "",
  AdmAccId: null,
  PCTrnsId: null,
  IsCost: 0,
  VatPrct: 0,
  WhtPrct: 0,
  IsOwnRcpt: 0,
  CtrlCd: "",
}

export const clearPCPeriodFilterData = {
  PCPrdTmSt: null,
  PCPrdTmEn: null,
  PCJnlId: null
}
export const initPCPeriodFilterData = {
  ...clearPCPeriodFilterData,
  PCPrdTmSt: dayjs().add(-7, 'day').format("YYYY-MM-DD 00:00:00"),
  PCPrdTmEn: dayjs().format("YYYY-MM-DD 23:59:59")
}