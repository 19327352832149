import { Box, IconButton } from '@mui/material'
import { BoxFR } from 'components/BoxCustom'
import React, { useState, useCallback, useMemo, useEffect } from 'react'
import { useParams } from "react-router-dom"
import ZoomInIcon from '@mui/icons-material/ZoomIn';
import ZoomOutIcon from '@mui/icons-material/ZoomOut';
import ZoomOutMapIcon from '@mui/icons-material/ZoomOutMap';
import { grey } from '@mui/material/colors';
import { Rotate90DegreesCcwRounded, Rotate90DegreesCwRounded } from "resource/muiImport";

let oldX = 0;
let oldY = 0;
let lastMoveX = 0;
let lastMoveY = 0;
let isDrag = false;
const FullScreenImage = ({ linkUrl, width, height }) => {
  const [scale, setScale] = useState(1);
  const [move, setMove] = useState({ x: 0, y: 0 });
  const [rotate, setRotate] = useState(0);

  const { imgUrl } = useParams();

  const handelScaleUp = useCallback(() => {
    setScale(o => o + 0.2)
  }, [])
  const handelScaleDown = useCallback(() => {
    setScale(o => o - 0.2 <= 0.1 ? o : o - 0.2)
  }, [])

  const setScaleTo1 = useCallback(() => {
    setScale(1)
    lastMoveX = 0;
    lastMoveY = 0;
    setMove({ x: 0, y: 0 })
  }, [])

  const handleMouseDown = useCallback((e) => {
    e.preventDefault();
    oldX = e.pageX
    oldY = e.pageY
    isDrag = true
  }, [])
  const handleMouseUp = useCallback((e) => {
    e.preventDefault()
    isDrag = false
    lastMoveX = e.pageX - oldX + lastMoveX
    lastMoveY = e.pageY - oldY + lastMoveY
  }, [])
  const handleMouseMove = useCallback((e) => {
    if (isDrag) {
      setMove({
        x: e.pageX - oldX,
        y: e.pageY - oldY
      })
    }
  }, [])

  const handleRotateCw = useCallback(() => {
    setRotate(o => o + 90)
  }, [])

  const handleRotateCcw = useCallback(() => {
    setRotate(o => o - 90)
  }, [])
  console.log("imgUrl::", imgUrl)
  const url = useMemo(() => {
    if (linkUrl) return linkUrl.replaceAll("~", "/");
    return imgUrl.replaceAll("~", "/")
  }, [imgUrl, linkUrl])


  useEffect(() => {
    // console.log("useEffect::", linkUrl)
    setScaleTo1()
  }, [setScaleTo1, linkUrl])
  console.log("linkUrl::", linkUrl)
  console.log("url::", url)
  return (
    <Box width={width || "100%"} bgcolor={grey[400]} height={height || "100vh"} position="relative" overflow="hidden">

      <img
        alt="รูปภาพ"
        onMouseMove={handleMouseMove}
        onMouseDown={handleMouseDown}
        onMouseUp={handleMouseUp}
        style={{
          objectFit: "contain",
          transform: `scale(${scale}) translate(${move.x + lastMoveX}px, ${move.y + lastMoveY}px) rotate(${rotate}deg)`
        }} width="100%" height="100%" src={url} />
      <Box position="absolute" top={0} left={0}>
        <BoxFR mx={2} mt={2} sx={{ background: "grey", opacity: 0.8 }} borderRadius={4}>
          <IconButton onClick={handelScaleUp}>
            <ZoomInIcon fontSize='large' sx={{ color: "white" }} />
          </IconButton>
          <IconButton onClick={handelScaleDown}>
            <ZoomOutIcon fontSize='large' sx={{ color: "white" }} />
          </IconButton>
          <IconButton onClick={handleRotateCw}>
            <Rotate90DegreesCwRounded fontSize='large' sx={{ color: "white" }} />
          </IconButton>
          <IconButton onClick={handleRotateCcw}>
            <Rotate90DegreesCcwRounded fontSize='large' sx={{ color: "white" }} />
          </IconButton>
          <IconButton onClick={setScaleTo1}>
            <ZoomOutMapIcon fontSize='large' sx={{ color: "white" }} />
          </IconButton>
        </BoxFR>
      </Box>
    </Box>
  )
}

export default FullScreenImage