import { Dialog, DialogContent, List, ListItemButton, ListItemIcon, ListItemText, TextField } from "@mui/material"
import { BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { shippingApiNm } from "./shippingApiNm"
import {  grey } from "@mui/material/colors"
import dayjs from "dayjs"
import { ArticleRounded } from "resource/muiImport"
import { Link } from "react-router-dom/cjs/react-router-dom"

let allEDCfFiles = []
const RejectDetailDialog = ({ selectedId, dialogOpen, setDialogOpen }) => {

  const { ax } = useContext(GlobalStateContext)
  const [edCfData, setEDCfData] = useState([])
  const [message, setMessage] = useState("")
  const [edCfFiles, setEDCfFiles] = useState([])
  const [selectedED, setSelectedED] = useState(null)

  const handleDateClick = useCallback((data) => (e) => {
    console.log("data::", data)
    setMessage(data.Msg)
    setEDCfFiles(allEDCfFiles.filter(file => file.ShpEDCfId === data.ShpEDCfId))
    setSelectedED(data.ShpEDCfId)
  }, [])
  useEffect(() => {
    if (dialogOpen) {
      ax.post(shippingApiNm.getShippingInvoiceDetail, { ShpInvId: selectedId }).then(value => {
        if (value.data && value.data.length > 0) {
          const data = value.data[0]
          const edCfData = data.EDCfs.filter(edCf => edCf.IsCf === 0)
          setEDCfData(edCfData)
          allEDCfFiles = data.Files.filter(file => file.ShpFileTypId === 4)
          setEDCfFiles(allEDCfFiles.filter(file => file.ShpEDCfId === edCfData[0].ShpEDCfId))
          setSelectedED(edCfData[0].ShpEDCfId)
          setMessage(edCfData[0].Msg)
        }
      })
    } else {
      setEDCfData([])
      setMessage("")
      setEDCfFiles([])
      setSelectedED(null)
      allEDCfFiles = []
    }
  }, [dialogOpen, ax, selectedId])
  console.log("edCfData::", edCfData)
  console.log("edCfFiles::", edCfFiles)
  console.log("allEDCfFiles::", allEDCfFiles)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รายละเอียดการปฏิเสธ"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFR mt={2} sx={{ alignItems: "flex-start" }}>
          <List sx={{ height: 195, width: 200, bgcolor: grey[100], borderRadius: 2 }}>
            {
              edCfData.map((item, index) => (
                <ListItemButton key={item.ShpEDCfId} onClick={handleDateClick(item)} selected={selectedED === item.ShpEDCfId}>
                  <ListItemText primaryTypographyProps={{ style: { whiteSpace: "normal" } }}>
                    {item.ModDte ? dayjs(item.ModDte).format("DD/MM/YYYY HH:mm") : "-"}
                  </ListItemText>
                </ListItemButton>
              ))
            }
          </List>
          <TextField multiline rows={7} sx={{ width: 300 }} value={message || ""} label="ข้อความ">

          </TextField>
          <List sx={{ height: 195, width: 300, bgcolor: grey[100], borderRadius: 2 }}>
            {
              edCfFiles.map((item, index) => (
                <Link to={`/file/shipping/${item.ShpInvId}/${item.FileNm}`} target="_blank">
                  <ListItemButton key={item.ShpEDCfId} >
                    <ListItemIcon sx={{ p: 0, minWidth: 0, mr: 2 }}>
                      <ArticleRounded />
                    </ListItemIcon>
                    <ListItemText sx={{ width: "100%" }} primary={item.Label}
                      primaryTypographyProps={{ style: { whiteSpace: "normal", overflow: "hidden", textOverflow: "ellipsis" } }} />
                  </ListItemButton>
                </Link>
              ))
            }
          </List>
        </BoxFR>
      </DialogContent>
      <DialogFooter okText=""
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} >
      </DialogFooter>
    </Dialog>
  )
}

export default RejectDetailDialog