import { Box, Dialog, DialogContent } from "@mui/material"
import { BoxFC } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { otherDriverTripColumns } from "./columns"
import { branchApiNm } from "branch/constant/branchApiNm"
import { addIdForDataGrid } from "utils"
import AddDriverTripDialog from "./AddDriverTripDialog"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"

let selectedPCItmId = null
let selectedRow = {}

const OtherDriverTripDialog = ({ jobOrdId, dialogOpen, setDialogOpen, onFinish, lastFilter, suggestDrvIds, joData }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [dialogAddDriverTripOpen, setDialogAddDriverTripOpen] = useState(false)

  const getData = useCallback(()=>{
    ax.post(branchApiNm.getDriverTripPayment, { JobOrdId: jobOrdId }).then(value => {
      if (value.data && value.data.length > 0) {
        const filtered = value.data[0].Exps.filter(exp =>{
          return (!(exp.ExpId === 89 && joData.Sts1DrvId === exp.UsrAccId) 
            &&!(exp.ExpId === 90 && joData.Sts3DrvId === exp.UsrAccId)
            &&!(exp.ExpId === 91 && joData.Sts5DrvId === exp.UsrAccId))
          //  !driverTripWatchCols.includes(exp.ExpId)
          })
        setDataTable(addIdForDataGrid(filtered, "PCItmId"))
      }
    })
  }, [ ax, jobOrdId, joData])
  const handleCellClick = useCallback((params) => {
    if (params.field === 'ShowDetail') {
      selectedPCItmId = params.row.PCItmId
      selectedRow = {
        ...params.row, 
        ...joData
      }
      setDialogAddDriverTripOpen(true)
    }
  }, [joData])

  const handleRowDoubleClick = useCallback((params) => {
    selectedPCItmId = params.row.PCItmId
    selectedRow = {
      ...params.row, 
      ...joData
    }
    setDialogAddDriverTripOpen(true)
  }, [joData])

  const onAddDriverTripDialogFinish = useCallback((data) => {
    onFinish(data)
    getData()
  }, [onFinish, getData])

  useEffect(() => {
    if (dialogOpen) {
      getData()
    }
  }, [dialogOpen, getData])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="รายละเอียดค่าเที่ยวอื่นๆ"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <Box height={400} >
            <DataGridCellExpand
              hideFooter
              rows={dataTable}
              columns={otherDriverTripColumns}
              onCellClick={handleCellClick}
              onRowDoubleClick={handleRowDoubleClick}
            />
          </Box>
        </BoxFC>

        <AddDriverTripDialog
          dialogOpen={dialogAddDriverTripOpen}
          setDialogOpen={setDialogAddDriverTripOpen}
          pcItmId={selectedPCItmId}
          dataRow={selectedRow}
          suggestDrvIds={suggestDrvIds}
          onFinish={onAddDriverTripDialogFinish}
          lastFilter={lastFilter}/>
      </DialogContent>
      <DialogFooter okText={""}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={null} />
    </Dialog>
  )
}

export default React.memo(OtherDriverTripDialog)