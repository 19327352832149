import { ArrowDropDownRounded } from "resource/muiImport";
import { Button, ButtonGroup, ClickAwayListener, Paper, Popper } from "@mui/material";
import React from "react";
import { useCallback } from "react";
import { useRef } from "react";
import { useState } from "react";

const ButtonGroupPopper = ({mainButton, children, arrowButtonStyle}) => {
  const [open, setOpen] = useState(false)
  const anchorRef =  useRef(null);

  const toggleOpen = useCallback(()=>{
    console.log("in toggleOpen")
    setOpen(o=>!o)
  }, [])

  const handleClose = (event)=>{
    console.log("in handleClose")
    if (anchorRef.current && anchorRef.current.contains(event.target)) {
      return;
    }
    setOpen(false);
  }
  return (
    <ButtonGroup sx={{ minWidth: 0 }} ref={anchorRef}>
      {mainButton}
      <Button variant="contained"
        style={{ minWidth: 0, padding: 0 }}
        onClick={toggleOpen}
        {...arrowButtonStyle}>
        <ArrowDropDownRounded sx={{ p: 0 }} />
      </Button>
      <Popper
        anchorEl={anchorRef.current}
        open={open}
        sx={{ zIndex: 4000, }}
        placement="bottom-end">
        <ClickAwayListener onClickAway={handleClose}>
          {children?children:<Paper sx={{p:1}}>No child found</Paper>}
        </ClickAwayListener>

      </Popper>
    </ButtonGroup>
  )
}

export default ButtonGroupPopper