import React, { useCallback, useContext, useState } from "react";
import { jobOrderColNm } from "constants/jobOrderConstant";
import SearchButton from "components/buttons/SearchButton";
import ClearButton from "components/buttons/ClearButton";
import { Box, Button, MenuItem, Paper, TextField, useMediaQuery } from "@mui/material"
import BoxFilter from "components/BoxCustom/BoxFilter";
import ComboBox from "components/ComboBox";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import DateTimeSelect from "components/DateTimeSelect/DateTimeSelect";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { FilterListRounded } from "resource/muiImport";
import PopoverBarebone from "components/PopoverBarebone";
import { clearFilterData, initFilterData } from "./initData";

const ClientDataQueryFilter = ({ onSearchButtonClick, selectedIds }) => {
  const [filterData, setFilterData] = useState(initFilterData);
  const { msData } = useContext(GlobalStateContext);
  const [jobCombo, setJobCombo] = useState(msData.jobCombo)

  const w800 = useMediaQuery('(min-width:800px)');

  const setSelectDate = useCallback((start, end) => {
    setFilterData(oldState => ({
      ...oldState,
      AptTmSt: start,
      AptTmEn: end
    }))
  }, [])

  const handleCusSelect = useCallback((id) => {
    console.log("cusID::", id);
    setFilterData(oldState => ({ ...oldState, CusId: id, JobId: null }));
    if (id) {
      setJobCombo(msData.jobCombo.filter(item => item.refId === id));
    } else {
      setJobCombo(msData.jobCombo);
    }
  }, [msData])

  const handleJobOrdIdKeyPress = useCallback(e => {
    // console.log("onKeypress keyCode::", e.keyCode )
    // console.log("onKeyPress value::", e.target.value)
    if (e.keyCode === 13) {
      onSearchButtonClick({ JobOrdId: filterData.JobOrdId })
    }
  }, [onSearchButtonClick, filterData])
  const filterProps = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    sx: { width: 180 },
    label: jobOrderColNm[name],
    value: filterData[name] || "",
    onKeyUp: e => { if (e.code === "Enter" || e.code === "NumpadEnter") { onSearchButtonClick(filterData) } },
    onChange: (e) => { setFilterData(oldState => ({ ...oldState, [name]: e.target.value || null })) }
  }), [filterData, onSearchButtonClick])

  const filterPropsMobile = useCallback((name) => ({
    ...filterProps(name),
    sx: {width: "100%"}
  }), [filterProps])
  console.log("filterData::", filterData)
  return w800 ? (
    <Box display="flex">
      <BoxFilter>
        <TextField {...filterProps("JobOrdId")} sx={{ width: 140 }} onKeyUp={handleJobOrdIdKeyPress} label="Job Order No." />
        <DateTimeSelect
          sx={{ width: 180 }}
          label="Appoint Time"
          start={filterData.AptTmSt}
          end={filterData.AptTmEn}
          setSelectDate={setSelectDate}
        />
        <TextField {...filterProps("JobNo")} />
        <TextField {...filterProps("Bkg")} />
        <TextField {...filterProps("ContNo")} label="Container No." />
        <ComboBox sx={{ width: 180 }} options={msData.cusCombo} label="Customer"
          selectedId={filterData.CusId}
          setSelectedId={handleCusSelect}
        />
        <ComboBox sx={{ width: 180 }} options={jobCombo} label="Job"
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(oldState => ({ ...oldState, JobId: id }))}
        />
        <TextField {...filterProps("IsNotFin")} sx={{ width: 120 }} label="Status" select
          value={filterData.IsNotFin == null ? -1 : filterData.IsNotFin}
          onChange={(e) => setFilterData(oldState => ({ ...oldState, IsNotFin: e.target.value === -1 ? null : e.target.value }))}>
          <MenuItem value={-1}>Not specified</MenuItem>
          <MenuItem value={1}>Not Finished</MenuItem>
          <MenuItem value={0}>Finished</MenuItem>
        </TextField>
        <SearchButton onClick={() => { onSearchButtonClick(filterData) }} />
        <ClearButton onClick={() => { setJobCombo([]); setFilterData(clearFilterData) }} />
      </BoxFilter>
    </Box>
  ) : (
    <BoxFC>
      <PopoverBarebone
        transformOrigin={{ vertical: "top", horizontal: "left" }}
        anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
        component={<Button variant="contained" sx={{ width: 150 }}>
          <FilterListRounded sx={{ mr: 1 }} /> FILTER
        </Button>}
      >
        <Paper sx={{ p: 2, width: 230 }}>
          <BoxFC sx={{ gap: 1 }}>
            <TextField {...filterPropsMobile("JobOrdId")} onKeyUp={handleJobOrdIdKeyPress} label="Job Order No." />
            <DateTimeSelect
              sx={{width: "100%"}}
              label="Appoint Time"
              start={filterData.AptTmSt}
              end={filterData.AptTmEn}
              setSelectDate={setSelectDate}
            />
            <TextField {...filterPropsMobile("JobNo")} />
            <TextField {...filterPropsMobile("Bkg")} />
            <TextField {...filterPropsMobile("ContNo")} label="Container No." />
            <ComboBox options={msData.cusCombo} label="Customer"
              selectedId={filterData.CusId}
              setSelectedId={handleCusSelect}
            />
            <ComboBox options={jobCombo} label="Job"
              selectedId={filterData.JobId}
              setSelectedId={(id) => setFilterData(oldState => ({ ...oldState, JobId: id }))}
            />
            <TextField {...filterPropsMobile("IsNotFin")} label="Status" select
              value={filterData.IsNotFin == null ? -1 : filterData.IsNotFin}
              onChange={(e) => setFilterData(oldState => ({ ...oldState, IsNotFin: e.target.value === -1 ? null : e.target.value }))}>
              <MenuItem value={-1}>Not specified</MenuItem>
              <MenuItem value={1}>Not Finished</MenuItem>
              <MenuItem value={0}>Finished</MenuItem>
            </TextField>
            <BoxFR>
              <SearchButton onClick={() => { onSearchButtonClick(filterData) }} />
              <ClearButton onClick={() => { setJobCombo([]); setFilterData(clearFilterData) }} />
            </BoxFR>
          </BoxFC>
        </Paper>
      </PopoverBarebone>

    </BoxFC>
  )
}

export default React.memo(ClientDataQueryFilter)
