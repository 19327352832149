import { AddCircleRounded, DeleteRounded, DownloadRounded, EditRounded, PublishRounded, VisibilityOffRounded, VisibilityRounded } from "resource/muiImport";
import { IconButton } from "@mui/material";
import { GridActionsCellItem } from "@mui/x-data-grid";
import { BoxFR } from "components/BoxCustom";
import { truckColNm } from "constants/truckConstant";

export const columns = ({ handleAddItem, handleEdit, handleDelete, handleToogleIsActv, handleUp, handleDown }) => [
  {
    field: "UpDown",
    headerName: "",
    width: 100,
    renderCell: (params) => {
      return (
        <BoxFR sx={{ gap: 0 }}>
          <IconButton onClick={() => handleUp(params.row)}><PublishRounded /></IconButton>
          <IconButton onClick={() => handleDown(params.row)}><DownloadRounded /></IconButton>
        </BoxFR>
      )
    }
  },
  {
    field: "No",
    headerName: "No.",
    width: 50,
  },
  {
    field: "MtChkCatNm",
    headerName: "หัวข้อ",
    width: 250,
    // cellClassName: "category-col"
  },
  {
    field: "MtChkItmNm",
    headerName: "รายการ",
    flex: 1,
    minWidth: 400
  },
  {
    field: "actions",
    type: "actions",
    width: 150,
    align: "right",
    getActions: (params) => [
      <GridActionsCellItem
        sx={{ display: params.row.isCatRow ? "inline-flex" : "none" }}
        icon={<AddCircleRounded />}
        label="Add"
        onClick={() => handleAddItem(params.row)}
      />,
      <GridActionsCellItem
        icon={<EditRounded />}
        label="Edit"
        onClick={() => handleEdit(params.row)}
      />,
      <GridActionsCellItem
        icon={params.row.IsActv ? <VisibilityRounded /> : <VisibilityOffRounded />}
        label="IsActv"
        onClick={() => handleToogleIsActv(params.row)}
      />,
      <GridActionsCellItem
        icon={<DeleteRounded />}
        label="Delete"
        onClick={() => handleDelete(params.row)}
      />,
    ]
  },

]

export const planTruckColumns = [
  {
    field: 'No',
    headerName: 'ลำดับ',
    width: 70,
    align: 'center',
  },
  {
    field: 'Code',
    headerName: truckColNm.Code,
    width: 100,
  },
  {
    field: 'IsGas',
    headerName: truckColNm.IsGas,
    width: 90,
    valueFormatter: ({ value }) => value===0?'น้ำมัน':'แก๊ส'
  },
  {
    field: 'Model',
    headerName: truckColNm.Model,
    flex: 1,
    minWidth: 150
  },
  {
    field: 'Dscp',
    headerName: truckColNm.Dscp,
    flex: 1,
    minWidth: 150
  },
  {
    field: 'TagNms',
    headerName: "Tag",
    flex: 1,
    minWidth: 150
  },
  {
    field: 'Rmk',
    headerName: truckColNm.Rmk,
    flex: 1,
    minWidth: 150
  },
  {
    field: 'PlnNmTyp1',
    headerName: "ฟอร์มรายวัน",
    width: 80
  },
  {
    field: 'PlnNmTyp2',
    headerName: "ฟอร์มรายสัปดาห์",
    width: 80
  },
  {
    field: 'PlnNmTyp3',
    headerName: "ฟอร์มรายเดือน",
    width: 80
  },
  {
    field: 'PlnNmTyp4',
    headerName: "ฟอร์มระยะทาง",
    width: 80
  }
]