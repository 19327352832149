import { ArticleRounded } from "resource/muiImport"
import { IconButton, Tooltip } from "@mui/material"
import dayjs from "dayjs"
import { numberFormat, numberFormatNoFlotingPoint } from "utils"

export const incomeByCusYearColumns = (years) => {
  const columns = [
    {
      field: "No",
      headerName: "No",
      width: 60,
    },
    {
      field: "SName",
      headerName: "Job",
      flex: 1,
    },
  ]
  years.forEach(year => {
    columns.push({
      field: year.toString(),
      headerName: year.toString(),
      width: 100,
      valueFormatter: (params) => params.value ? numberFormatNoFlotingPoint(+params.value) : "",
      cellClassName: (params) => {
        const yearB4 = params.row[year - 1]
        if (yearB4) {
          const yearNow = params.row[year]
          if (yearNow < yearB4) return "less-than"
          else if (yearNow > yearB4) return "more-than"
        }
      },
    })
  })
  columns.push({
    field: "Total",
    headerName: "Total",
    width: 130,
    valueGetter: (params) => {

      let sum = 0;
      years.forEach(year => {
        sum += +params.row[year]
      })
      return sum
    },
    valueFormatter: (params) => numberFormatNoFlotingPoint(params.value),
  })
  return columns
}

export const overDueYearMonthColumns = [
  {
    field: "YearMonth",
    headerName: "Month Year",
    valueFormatter: (params) => params.value === "รวม"?params.value:  params.value ? dayjs(params.value.toString(), "YYYYMM").format("MMM YYYY") : "",
    width: 100,
  },
  {
    field: "TotalInv",
    align: "right",
    headerName: "Invoice Total",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    flex: 1,
    minWidth: 140
  },
  {
    field: "TotalPay",
    align: "right",
    headerName: "Settled",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    flex: 1,
    minWidth: 140
  },
  {
    field: "OwnRcptAmnt",
    headerName: "Charge Outstanding",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    flex: 1,
    minWidth: 140
  },
  {
    field: "CusRcptAmnt",
    headerName: "Advance Outstanding",
    align: "right",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    flex: 1,
    minWidth: 140
  },
  {
    field: "Total",
    align: "right",
    headerName: "Total Outstanding",
    valueFormatter: (params) => params.value ? numberFormat(+params.value) : "",
    flex: 1,
    minWidth: 140
  },
  {
    field: "Detail",
    width: 30,
    renderCell: (params) => params.row.YearMonth === "รวม"? "-": (
      <Tooltip title="Detail" followCursor placement="top">
        <IconButton onClick={()=>{window.open(`/client/ClientInvoice/YearMonth/${params.row.YearMonth}`,"_blank")}}>
          <ArticleRounded color="primary"/>
        </IconButton>
      </Tooltip>
    )
  },
]