import { Box, Button, IconButton, Popover, TextField, Typography } from "@mui/material";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";
import { BoxFC, BoxFR } from "components/BoxCustom";
import dayjs from "dayjs";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { columns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { CheckCircleRounded, CheckRounded, CloseRounded, DoNotDisturbOnRounded } from "resource/muiImport";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { driverAttendApiNm } from "./constant";
import AbsentChoice from "./AbsentChoice";
import { blue, green, yellow } from "@mui/material/colors";
import { GRID_CHECKBOX_SELECTION_COL_DEF } from "@mui/x-data-grid";
// import { alertWarning } from "components/Alert";
import { useGridApiRef } from "@mui/x-data-grid-pro";

const initFilterData = { TukAttnMonth: dayjs() }

let absentChoiceData = {
  defaultSts: 1,
  defaultDscp: "",
  tukAttnSts: [],
  tukId: null,
  col: "",
}

let lastFilterData = { ...initFilterData }
const TruckAttend = () => {

  const [tukAttnSts, setTukAttnSts] = useState([])
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [selectionModel, setSelectionModel] = useState([])
  const [dataTable, setDataTable] = useState([])
  // const [dateAddData, setDateAddData] = useState(dayjs())
  
  const [copyData, setCopyData] = useState({
    dteCopy: dayjs().startOf("day").add(-1, "day"),
    dtePaste: dayjs().startOf("day")
  })

  const { ax } = useContext(GlobalStateContext)

  const [anchorEl, setAnchorEl] = React.useState(null);
  const open = Boolean(anchorEl);

  const apiRef = useGridApiRef();

  // const addDataFromDateProp = useMemo(() => ({
  //   label: "วันที่",
  //   inputFormat: "DD/MM/YYYY",
  //   mask: "__/__/____",
  //   value: dateAddData,
  //   onChange: (newValue) => { setDateAddData(newValue) },
  //   renderInput: (params) => <TextField size="small"  {...params} sx={{ width: 150 }} />,
  // }), [dateAddData])

  const copyDateDataProp = useCallback((name)=>({
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    value: copyData[name],
    onChange: (newValue) => { setCopyData(o=>({...o, [name]: newValue})) },
    renderInput: (params) => <TextField size="small"  {...params} sx={{ width: 150 }} />,
  }), [copyData])

  const processData = useCallback((data) => {
    if(!lastFilterData) return 
    const lastDay = lastFilterData.TukAttnMonth.endOf("month").date()
    const sumDay = {}
    for (let i = 1; i <= lastDay; i++) {
      sumDay[`d${i}`] = tukAttnSts.reduce((prev, cur) => ({ ...prev, [cur.TukAttnStsId]: 0 }), {})
    }

    const stsTxt = tukAttnSts.reduce((prev, cur) => ({ ...prev, [cur.TukAttnStsId]: cur.Abbr }), {})
    const result = []
    let no = 0;
    const stsGranTotal = tukAttnSts.reduce((prev, cur) => ({ ...prev, [cur.TukAttnStsId]: 0 }), {})
    for (const row of data) {
      no++
      const newRow = {
        id: row.TukId,
        No: no,
        TukId: row.TukId,
        TukCd: row.TukCd,
        LicNm: row.LicNm,
      }
      let sumAbsent = 0;
      let allDay = 0;
      const stsTotal = tukAttnSts.reduce((prev, cur) => ({ ...prev, [cur.TukAttnStsId]: 0 }), {})
      for (const attn of row.Attns) {
        newRow[`d${attn.Dte}`] = attn.StsId ? `${attn.StsId};${stsTxt[attn.StsId]};${attn.Dscp ?? ""};${attn.Is}` : null
        stsTotal[attn.StsId]++
        stsGranTotal[attn.StsId]++
        if (attn.StsId > 2) sumAbsent++
        attn.StsId&&allDay++
        sumDay[`d${attn.Dte}`][attn.StsId]++
      }
      newRow.stsTotal = stsTotal
      newRow.Total = tukAttnSts
        .filter(sts => stsTotal[sts.TukAttnStsId] > 0)
        .map(sts => `${stsTxt[sts.TukAttnStsId]}:${stsTotal[sts.TukAttnStsId]}`).join(", ")
      newRow.TotalAbsent = `${sumAbsent}/${allDay}`
      result.push(newRow)
    }
    console.log("sumDay", sumDay)
    const sumDayText = { ...sumDay }
    for (const key in sumDayText) {
      sumDayText[key] = tukAttnSts
        .filter(sts => sumDay[key][sts.TukAttnStsId] > 0)
        .map(sts => `${stsTxt[sts.TukAttnStsId]}:${sumDay[key][sts.TukAttnStsId]}`).join(", ")
    }
    const totalRow = {
      id: 0,
      no: "",
      FLNNm: "",
      TukCd: "รวม",
      LicNm: "รวม",
      ...sumDayText,
    }

    result.push(totalRow)
    return result
  }, [tukAttnSts])

  const getData = useCallback((filter) => {
    const postData = {
      TukAttnDteSt: filter.TukAttnMonth.startOf("month").format("YYYY-MM-DD"),
      TukAttnDteEn: filter.TukAttnMonth.endOf("month").format("YYYY-MM-DD"),
    }
    ax.post(driverAttendApiNm.getTruckAttendTable, postData).then(value => {
      if (value.data) {
        setDataTable(processData(value.data))
        lastFilterData = {...filter, ...postData}
      }
    })
  }, [ax, processData])

  const handleCheck = useCallback((tukId, col, tukAttnStsId, dscp) => {
    const tukIds = selectionModel.length === 0 ? [tukId] : selectionModel
    const postData = {
      argsArr: tukIds.map(tukId => ({
        TukId: tukId,
        TukAttnDte: filterData.TukAttnMonth.format(`YYYY-MM-${col.replace("d", "").padStart(2, "0")}`),
        TukAttnStsId: tukAttnStsId,
        Dscp: dscp
      })),
      getArgs: lastFilterData
    }
    ax.post(driverAttendApiNm.insertDuplicateUpdateBulkTruckAttend, postData).then(value => {
      if (value.data) {
        setDataTable(processData(value.data))
        setAnchorEl(null)
      }
    })
  }, [ax, filterData, selectionModel, processData])

  const handleOpenAbsentChoice = useCallback((params, attns) => (e) => {
    console.log("attns", attns)
    absentChoiceData = {
      defaultSts: attns && attns.length > 0 ? attns[0] : 101,
      defaultDscp: attns && attns.length > 0 ? attns[2] || "" : "",
      tukAttnSts: tukAttnSts,
      tukId: params.row.TukId,
      col: params.field,
    }
    setAnchorEl(e.currentTarget)
  }, [tukAttnSts])

  const monthColumns = useMemo(() => {
    const lastDay = filterData.TukAttnMonth.endOf("month").date()
    console.log("lastDay", lastDay)
    const modColumns = [...columns];
    const isSameMonth = filterData.TukAttnMonth.month() === dayjs().month()
    for (let i = 1; i <= lastDay; i++) {
      modColumns.push({
        field: `d${i}`,
        headerName: `${i}`,
        width: 120,
        align: "center",
        headerAlign: 'center',
        cellClassName: () => i === dayjs().date() && isSameMonth ? "today" : "",
        renderCell: (params) => {
          const attns = params.value ? params.value.split(";") : []
          return (
            params.id === 0 ? params.value :
              params.value ?
                <BoxFC sx={{ gap: 0, alignItems: "center" }}>
                  <IconButton sx={{ py: 0 }} onClick={handleOpenAbsentChoice(params, attns)}>

                    {["1", "2", "3"].includes(attns[0]) ?
                      <CheckCircleRounded sx={{ color: ["1", "2"].includes(attns[0]) ? green[700] : yellow[700] }} />
                      : <DoNotDisturbOnRounded color="error" />}

                  </IconButton>
                  <Typography variant="caption">
                    {attns[0] === "1" ? "" : `(${attns[1]})`}
                    {attns[2] ? attns[2] : ""}
                  </Typography>
                </BoxFC>

                :
                <BoxFR sx={{ gap: 0 }}>
                  <IconButton onClick={() => handleCheck(params.row.TukId, params.field, 1)}>
                    <CheckRounded color="success" />
                  </IconButton>

                  <IconButton onClick={handleOpenAbsentChoice(params, attns)}>
                    <CloseRounded color="action" />
                  </IconButton>
                </BoxFR>
          )
        }
      });
    }
    modColumns.push({
      field: "Total",
      headerName: "รวม",
      width: 120,
      align: "left",
      headerAlign: "left",
    })
    modColumns.push({
      field: "TotalAbsent",
      headerName: "รวมไม่มา",
      width: 90,
      align: "left",
      headerAlign: "left",
    })
    return modColumns
  }, [filterData.TukAttnMonth, handleCheck, handleOpenAbsentChoice]);

  const handleDatePickerKeyUp = useCallback((e) => {
    if (e.key === "ArrowUp") {
      setFilterData(o => {
        const newData = { ...o, TukAttnMonth: o.TukAttnMonth.add(-1, "month") }
        getData(newData)
        return newData
      })
    } else if (e.key === "ArrowDown") {
      setFilterData(o => {
        const newData = { ...o, TukAttnMonth: o.TukAttnMonth.add(1, "month") }
        getData(newData)
        return newData
      })
    }
  }, [getData])

  const handleDatePickerChange = useCallback((newValue) => {
    setFilterData(o => {
      const newFilter = { ...o, TukAttnMonth: newValue }
      getData(newFilter)
      return newFilter
    })
  }, [getData])

  // const handleAddDataFromJobOrder = useCallback(() => {
  //   const postData = {
  //     AptTmSt: dateAddData.format("YYYY-MM-DD 00:00:00"),
  //     AptTmEn: dateAddData.format("YYYY-MM-DD 23:59:59"),
  //     getArgs: lastFilterData
  //   }
  //   ax.post(driverAttendApiNm.insertTruckAttendFromJobOrder, postData).then(value => {
  //     if (value.data) {
  //       setDataTable(processData(value.data.data))
  //       if(value.data.message){
  //         alertWarning(value.data.message)
  //       }
  //     }
  //   })
  // }, [ax, dateAddData, processData])

  const handleCopyData = useCallback(() => {
    const postData = {
      dteCopy: copyData.dteCopy.format("YYYY-MM-DD"),
      dtePaste: copyData.dtePaste.format("YYYY-MM-DD"),
      getArgs: lastFilterData
    }
    ax.post(driverAttendApiNm.copyTruckAttend, postData).then(value => {
      if (value.data) {
        setDataTable(processData(value.data))
      }
    })
  }, [ax, copyData, processData])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  useEffect(() => {
    ax.post(driverAttendApiNm.getTruckAttendStatus).then(value => {
      if (value.data) {
        setTukAttnSts(value.data)
      }
    })
  }, [ax])

  useEffect(()=>{
    //monthColumns.length > 20 means already loaded
    if(monthColumns.length > 20 && apiRef.current){
      
      apiRef.current.scrollToIndexes({rowIndex: 0, colIndex: dayjs().date() + 3})
    }
  }, [monthColumns, apiRef])
  console.log("dataTable", dataTable)

  return (
    <BoxFC height="100%" alignItems="center" sx={{ position: "relative" }}>
      <BoxFR sx={{ position: "absolute", top: 0, left: 0 }}>
        {/* <Typography>เพิ่มข้อมูลจากงานประจำวัน</Typography>
        <DesktopDatePicker {...addDataFromDateProp} />
        <Button variant="contained" onClick={handleAddDataFromJobOrder}>
          เพิ่มข้อมูล
        </Button> */}
        <Typography>คัดลอกข้อมูลจากวันที่</Typography>
        <DesktopDatePicker {...copyDateDataProp("dteCopy")} label="วันที่คัดลอก"/>
        <Typography>ไปยังวันที่</Typography>
        <DesktopDatePicker {...copyDateDataProp("dtePaste")} label="วันที่ใส่ข้อมูล"/>
        <Button variant="contained" onClick={handleCopyData}>
          เพิ่มข้อมูล
        </Button>
      </BoxFR>
      <DesktopDatePicker label="เดือน" value={filterData.TukAttnMonth}
        openTo="month"
        views={["year", "month"]}
        renderInput={(params) => <TextField {...params} sx={{ width: 200 }} size="small" onKeyUp={handleDatePickerKeyUp} />}
        onChange={handleDatePickerChange} />
      <Box flex={1} width="100%" sx={{
        "& .today": { bgcolor: blue[50] },
        "& .total": { bgcolor: yellow[100] }
      }}>
        <DataGridCellExpand
          //density="compact"
          apiRef={apiRef}
          checkboxSelection
          disableSelectionOnClick={true}
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          hideFooter
          rows={dataTable}
          columns={monthColumns}
          initialState={{ pinnedColumns: { 
            left: [GRID_CHECKBOX_SELECTION_COL_DEF.field, 'No', "FLNNm", "TukCd"] ,
            right: ['Total', 'TotalAbsent']
          } }}
          getRowClassName={(params) => params.id === 0 ? "total" : ""}
          isRowSelectable={(params) => params.id !== 0} 
        //extraContext={[{ icon: <EditRounded />, label: "แก้ไข", onClick: handleEditData }]}
        />
      </Box>
      <Popover open={open}
        anchorEl={anchorEl}
        onClose={() => setAnchorEl(null)}
        anchorOrigin={{
          vertical: 'bottom',
          horizontal: 'center',
        }}
        transformOrigin={{
          vertical: 'top',
          horizontal: 'center',
        }} >
        <AbsentChoice
          {...absentChoiceData}
          handleCheck={handleCheck} />
      </Popover>
    </BoxFC>
  )
}

export default TruckAttend;