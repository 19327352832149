import { BackspaceRounded, KeyboardDoubleArrowLeftRounded, KeyboardDoubleArrowRightRounded, PersonSearchRounded, PrintRounded, RefreshRounded, SettingsEthernetRounded } from "resource/muiImport";
import { Box, Button, Divider, IconButton, Paper, TextField, Typography } from "@mui/material";
import { useTheme } from "@mui/material/styles";
import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { UserContext } from "contexts/UserContext";
import React, { useContext, useState, useCallback, useMemo } from "react";
import { driverColumns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { blue, blueGrey, green, grey, red } from "@mui/material/colors";
import DailyJobDialogV2 from "pages/Menu1/DailyJob/DailyJobDialogV2";
import { branchApiNm } from "branch/constant/branchApiNm";
import dayjs from "dayjs";
import { jobOrderColNm } from "constants/jobOrderConstant";
import { alertError } from "components/Alert";
import { getDataAndPrintJobOrder } from "utils/getDataAndPrintjobOrder";
import CheckboxFormControl from "components/CheckboxFormControl";
import ClearJobOrderDocDialog from "./ClearJobOrderDocDialog";
import SummaryBar from "pages/Menu1/DailyJob/SummaryBar";
import { DesktopDatePicker } from "@mui/x-date-pickers-pro";


const displayModule = {
  selection: false,
  sumRow: true,
  shpmType: false,
  trailerType: false,
  customer: true,
  job: false,
}

const sxHover = {
  "& .MuiDataGrid-row:hover": {
    bgcolor: grey[300],
    "& .error .today": { bgcolor: red[200] },
    "& .today": { bgcolor: blue[200] },
    "& .tomorrow": { bgcolor: green[200] },
    "& .not-attend": { bgcolor: grey[400] },
  },
  "& .selected": { bgcolor: blue[50] },
  "& .selected:hover": { bgcolor: blue[100] },
  "& .today": { bgcolor: blue[100] },
  "& .tomorrow": { bgcolor: green[100] },
  "& .not-attend": { bgcolor: grey[300] },
  "& .MuiDataGrid-columnHeaderTitleContainer .MuiDataGrid-columnHeaderTitleContainerContent": { width: "100%" },
  "& .error": { bgcolor: red[100] },
  "& .error .today, .error .tomorrow": { bgcolor: red[200] }
}

const boxExpandProps = {
  bgcolor: blueGrey[50],
  borderRadius: 1,
  p: 0,
  border: 1,
  borderColor: blueGrey[200],
  sx: { cursor: "pointer" }
}

let selectedJobOrdId = null

const initFilterData = {
  Bgk: "",
  JobNo: "",
  DrvId: null,
  CusIds: [],
  JobIds: [],
  IsAval: null,
  FDay: {
    yd: false,
    td: false,
    tm: false,
    atm: false
  },
  ContSts: {
    1: false,
    3: false,
    4: false,
    5: false
  }
}

const initColsWidth = driverColumns().reduce((prev, cur) => ({ ...prev, [cur.field]: cur.width }), {})

const DriverData = ({ driverData, driverWithBatt, setDriverData, refreshData, selectionDrvs, setSelectionDrvs
  , setLeftPanelWidth, leftPanelWidth, driverAttendData, truckAttendData, onFinish }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const { user } = useContext(UserContext)

  const theme = useTheme()
  // const [jobCombo, setJobCombo] = useState(msData.jobCombo)

  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [dailyJobDialogOpen, setDailyJobDialogOpen] = useState(false)
  const [selectionPrint, setSelectionPrint] = useState([])
  const [showCardDetail, setShowCardDetail] = useState(false)
  const [colsWidth, setColsWidth] = useState(initColsWidth)
  const [drvIdJobOrder, setDrvIdJobOrder] = useState(null)
  const [dialogClearJobOrderDocOpen, setDialogClearJobOrderDocOpen] = useState(false)
  const [aptTm, setAptTm] = useState(dayjs())

  const filterFDayAllUnchecked = useMemo(() => {
    return Object.keys(filterData.FDay).every(key => filterData.FDay[key] === false)
  }, [filterData.FDay])

  const textFieldProp = useCallback((name) => ({
    label: jobOrderColNm[name],
    size: "small",
    sx: { width: 150 },
    value: filterData[name],
    onChange: (e) => { setFilterData(o => ({ ...o, [name]: e.target.value })) },
  }), [filterData])

  const checkboxProps = useCallback((name) => ({
    disabled: filterData.IsAval === null,
    sx: { width: 100 },
    value: filterData.FDay[name],
    onChange: (e) => { setFilterData(o => ({ ...o, FDay: { ...o.FDay, [name]: e.target.checked } })) }
  }), [filterData])

  const driverTable = useMemo(() => {
    const drvData = driverWithBatt.map(item => {
      const attnDrv = driverAttendData.filter(attn => attn.DrvId === item.DrvId)
      const attnTuk = truckAttendData.filter(attn => attn.TukId === item.TukId)
      const yd = []
      const td = []
      const tm = []
      const atm = []
      for (const data of attnDrv) {
        const addedData = {
          Type: "Attn",
          StsNm: data.DrvAttnStsNm,
          Dscp: data.Dscp,
          IsAttn: data.IsAttn,
          IsAval: data.IsAval
        }
        const attnDte = dayjs(data.DrvAttnDte)
        const startOfToday = aptTm.startOf("day")
        if (attnDte.isSame(startOfToday.add(-1, "day")) && data.IsAval === 0) yd.push(addedData)
        else if (attnDte.isSame(startOfToday) && data.IsAval === 0) td.push(addedData)
        else if (attnDte.isSame(startOfToday.add(1, "day")) && data.IsAval === 0) tm.push(addedData)
        else if (attnDte.isSame(startOfToday.add(2, "day")) && data.IsAval === 0) atm.push(addedData)
      }
      for (const data of attnTuk) {
        const addedData = {
          Type: "Attn",
          StsNm: `รถ ${data.TukAttnStsNm}`,
          Dscp: data.Dscp,
          IsAttn: 1,
          IsAval: data.IsAval
        }
        const attnDte = dayjs(data.TukAttnDte)
        const startOfToday = aptTm.startOf("day")
        if (attnDte.isSame(startOfToday.add(-1, "day")) && data.IsAval === 0 && yd.length === 0) yd.push(addedData)
        else if (attnDte.isSame(startOfToday) && data.IsAval === 0 && td.length === 0) td.push(addedData)
        else if (attnDte.isSame(startOfToday.add(1, "day")) && data.IsAval === 0 && tm.length === 0) tm.push(addedData)
        else if (attnDte.isSame(startOfToday.add(2, "day")) && data.IsAval === 0 && atm.length === 0) atm.push(addedData)
      }
      return {
        id: item.DrvId,
        DrvId: item.DrvId,
        TukCd: item.TukCd,
        NName: item.NName,
        Batt: item.Batt,
        yd: yd,
        td: td,
        tm: tm,
        atm: atm,
        all: []
      }
    })
    // const drvData = msData.drivers.filter(item => item.IsSub === 0).map(item => ({
    //   id: item.DrvId,
    //   DrvId: item.DrvId,
    //   TukCd: msData.trucks.find(t => t.TukId === item.TukId)?.Code,
    //   NName: item.NName,
    //   yd: [],
    //   td: [],
    //   tm: [],
    //   atm: [],
    //   all: []
    // }))
    for (const data of driverData) {
      if (!data.DrvId) continue

      const driver = drvData.find(drv => drv.DrvId === data.DrvId)

      if (!driver) continue

      driver.all.push(data)
      if (!data.DrvActTm) {
        continue
        // addInArray(result.nd, data)
      } else if (dayjs(data.AptTm).isBefore(aptTm.startOf("day"))) {
        driver.yd.push(data)
      } else if (dayjs(data.AptTm).startOf("day").isSame(aptTm.startOf("day"))) {
        driver.td.push(data)
      } else if (dayjs(data.AptTm).startOf("day").isSame(aptTm.add(1, "day").startOf("day"))) {
        driver.tm.push(data)
      } else {
        driver.atm.push(data)
      }
    }
    return drvData
  }, [driverData, driverWithBatt, driverAttendData, truckAttendData, aptTm])

  const filteredDriverData = useMemo(() => {
    console.log("filterData::", filterData)
    console.log("driverTable::", driverTable)
    let tableResult = driverTable.map(item => ({ ...item }))
    if (filterData.Bkg) {
      tableResult = tableResult.filter(item => {
        return item.all.some(jo => jo.Bkg.indexOf(filterData.Bgk) > -1)
      })
    }
    if (filterData.JobNo) {
      tableResult = tableResult.filter(item => {
        return item.all.some(jo => jo.JobNo.indexOf(filterData.JobNo) > -1)
      })
    }
    if (filterData.CusIds.length > 0) {
      tableResult = tableResult.filter(item => {
        return item.all.some(jo => filterData.CusIds.includes(jo.CusId))
      })
    }
    if (filterData.JobIds.length > 0) {
      tableResult = tableResult.filter(item => {
        return item.all.some(jo => filterData.JobIds.includes(jo.JobId))
      })
    }
    if (filterData.DrvId) {
      tableResult = tableResult.filter(item => {
        return item.DrvId === filterData.DrvId
      })
    }

    console.log("filterData.FDay::", filterData.FDay)
    if (filterData.IsAval === true) {
      if (filterData.FDay.yd) {
        console.log("item.yd::", tableResult)
        tableResult = tableResult.filter(item => {
          return item.yd.length === 0
        })
      }
      if (filterData.FDay.td) {
        console.log("item.td::", tableResult)
        tableResult = tableResult.filter(item => {
          return item.td.length === 0
        })
      }
      if (filterData.FDay.tm) {
        tableResult = tableResult.filter(item => {
          return item.tm.length === 0
        })
      }
      if (filterData.FDay.atm) {
        tableResult = tableResult.filter(item => {
          return item.atm.length === 0
        })
      }
    }
    if (filterData.IsAval === false) {

      const filterSts = (item) => (sts) =>
        (filterData.ContSts[1] && sts.ContStsId === 1 && sts.DrvId === item.DrvId)
        || (filterData.ContSts[3] && sts.ContStsId === 3 && sts.DrvId === item.DrvId)
        || (filterData.ContSts[4] && sts.ContStsId === 4 && sts.DrvId === item.DrvId)
        || (filterData.ContSts[5] && sts.ContStsId === 5 && sts.DrvId === item.DrvId)


      if (!filterData.FDay.yd && !filterData.FDay.td && !filterData.FDay.tm && !filterData.FDay.atm) {
        tableResult = tableResult.filter(item => {
          return item.all.length > 0
        })
        if (filterData.ContSts[1] || filterData.ContSts[3] || filterData.ContSts[4] || filterData.ContSts[5]) {
          tableResult = tableResult.filter(item => {
            return item.all.some(jo => jo.ContStses.find(filterSts(item)))
          })
        }
      }
      if (filterData.FDay.yd) {
        console.log("item.yd::", tableResult)
        tableResult = tableResult.filter(item => {
          return item.yd.length > 0
        })

        if (filterData.ContSts[1] || filterData.ContSts[3] || filterData.ContSts[4] || filterData.ContSts[5]) {
          tableResult = tableResult.filter(item => {
            return item.yd.some(jo => jo.ContStses.find(filterSts(item)))
          })
        }
      }

      if (filterData.FDay.td) {
        console.log("item.td::", tableResult)
        tableResult = tableResult.filter(item => {
          return item.td.length > 0
        })
        if (filterData.ContSts[1] || filterData.ContSts[3] || filterData.ContSts[4] || filterData.ContSts[5]) {
          tableResult = tableResult.filter(item => {
            return item.td.some(jo => jo.ContStses.find(filterSts(item)))
          })
        }
      }

      if (filterData.FDay.tm) {
        tableResult = tableResult.filter(item => {
          return item.tm.length > 0
        })
      }
      if (filterData.FDay.atm) {
        tableResult = tableResult.filter(item => {
          return item.atm.length > 0
        })
      }

      // if (filterData.FDay.yd) {
      //   tableResult = tableResult.filter(item => {
      //     return item.yd.length > 0
      //   })
      //   console.log("item.yd::", tableResult)


      // }
      // if (filterData.FDay.td) {
      //   console.log("item.td::", tableResult)

      //   tableResult = tableResult.filter(item => {
      //     return item.td.some(jo => jo.ContStses.find(sts => 
      //       (filterData.ContSts[1] && sts.ContStsId === 1 && sts.DrvId === item.DrvId)
      //       || (filterData.ContSts[3] && sts.ContStsId === 3 && sts.DrvId === item.DrvId)
      //       || (filterData.ContSts[4] && sts.ContStsId === 4 && sts.DrvId === item.DrvId)
      //       || (filterData.ContSts[5] && sts.ContStsId === 5 && sts.DrvId === item.DrvId)))
      //   })
      // }



      // if (filterData.FDay.td) {
      //   tableResult = tableResult.filter(item => {
      //     return item.td.length === 0
      //   })
      // }
      // if (filterData.FDay.tm) {
      //   tableResult = tableResult.filter(item => {
      //     return item.tm.length === 0
      //   })
      // }
      // if (filterData.FDay.atm) {
      //   tableResult = tableResult.filter(item => {
      //     return item.atm.length === 0
      //   })
      // }
    }


    if (filterData.ContSts[1]) {
      tableResult = tableResult.filter(item => {
        return item.all
      })
    }
    if (filterData.ContSts[3]) {
      tableResult = tableResult.filter(item => {
        return item.all.some(jo => jo.ContStses.find(sts => sts.ContStsId === 3 && sts.DrvId === item.DrvId))
      })
    }

    return tableResult
  }, [filterData, driverTable])

  const summaryData = useMemo(() => {
    const result = []
    for (const drv of driverTable) {
      result.push(...drv.all)
    }
    return result
  }, [driverTable])

  const handleRemoveJobOrder = useCallback((item) => {
    // console.log("in handleRemove item::", item)
    const postData = {}
    const jobOrdId = item.JobOrdId
    const now = dayjs().format("YYYY-MM-DD HH:mm:ss")
    const contStsTemplate = { JobOrdId: jobOrdId, DrvId: null, TukId: null, StsTm: now }
    postData.contStatusData = [
      { ContStsId: 1, ...contStsTemplate },
      { ContStsId: 3, ...contStsTemplate },
      { ContStsId: 5, ...contStsTemplate }
    ]

    postData.jobOrdArr = [{
      JobOrdId: jobOrdId,
      ColValues: {
        DrvId: null,
        TukId: null,
        DrvActTm: now,
      },
      LogRmk: "แก้ไขผู้รับผิดชอบงาน"
    }]
    postData.getArgs = { getFn: "getJobPlanning" }


    ax.post(branchApiNm.updateJobOrderAllDetail, postData).then(value => {
      if (value.data) {
        onFinish(value.data)
      }
    })
  }, [ax, onFinish])

  const handleJobOrderClick = useCallback((jobOrdId) => {
    // e.stopPropagation()
    selectedJobOrdId = jobOrdId
    setDailyJobDialogOpen(true)
  }, []);

  const handleBkgClick = useCallback((shpmId) => (e) => {
    e.stopPropagation()
    setDriverData(o => {
      const result = [...o]
      for (const jo of result) {
        jo.hilightShpmId = shpmId
      }
      return result
    })
  }, [setDriverData])

  const onDailyJobDialogFinish = useCallback(() => {
    refreshData(aptTm)
  }, [refreshData, aptTm])

  // const handleCusComboChange = useCallback((id) => {
  //   setFilterData(o => ({ ...o, CusId: id }))

  //   setJobCombo(id ? msData.jobCombo.filter(item => item.refId === id) : msData.jobCombo)
  // }, [msData.jobCombo])

  const handleClearFilter = useCallback(() => {
    setFilterData({ ...initFilterData })
    setSelectionDrvs([])
  }, [setSelectionDrvs])

  const handlePrint = useCallback(() => {
    if (selectionPrint.length === 0) {
      alertError("กรุณาเลือกอย่างน้อย 1 ใบงาน")
      return;
    }

    getDataAndPrintJobOrder(ax, msData, selectionPrint, user.FName)
  }, [ax, msData, selectionPrint, user.FName])

  const columns = useMemo(() => {
    const drvFree = { yd: 0, td: 0, tm: 0, atm: 0, all: driverTable.length }
    const drvAbsent = { yd: 0, td: 0, tm: 0, atm: 0 }
    const drvDoOther = { yd: 0, td: 0, tm: 0, atm: 0 }
    for (const drv of driverTable) {
      drvAbsent.yd += drv.yd.find(item => item.Type === "Attn" && item.IsAval === 0 && item.IsAttn === 0) ? 1 : 0
      drvAbsent.td += drv.td.find(item => item.Type === "Attn" && item.IsAval === 0 && item.IsAttn === 0) ? 1 : 0
      drvAbsent.tm += drv.tm.find(item => item.Type === "Attn" && item.IsAval === 0 && item.IsAttn === 0) ? 1 : 0
      drvAbsent.atm += drv.atm.find(item => item.Type === "Attn" && item.IsAval === 0 && item.IsAttn === 0) ? 1 : 0
      drvDoOther.yd += drv.yd.find(item => item.Type === "Attn" && item.IsAval === 0 && item.IsAttn === 1) ? 1 : 0
      drvDoOther.td += drv.td.find(item => item.Type === "Attn" && item.IsAval === 0 && item.IsAttn === 1) ? 1 : 0
      drvDoOther.tm += drv.tm.find(item => item.Type === "Attn" && item.IsAval === 0 && item.IsAttn === 1) ? 1 : 0
      drvDoOther.atm += drv.atm.find(item => item.Type === "Attn" && item.IsAval === 0 && item.IsAttn === 1) ? 1 : 0
      drvFree.yd += drv.yd.length === 0 ? 1 : 0
      drvFree.td += drv.td.length === 0 ? 1 : 0
      drvFree.tm += drv.tm.length === 0 ? 1 : 0
      drvFree.atm += drv.atm.length === 0 ? 1 : 0
    }

    return driverColumns(handleRemoveJobOrder, handleBkgClick, handleJobOrderClick
      , filterData, selectionPrint, setSelectionPrint, showCardDetail, colsWidth, drvFree, drvAbsent, drvDoOther)
  }, [handleRemoveJobOrder, handleBkgClick, handleJobOrderClick
    , filterData, selectionPrint, setSelectionPrint, showCardDetail, colsWidth, driverTable])

  const handleColumnChnage = useCallback((params) => {
    setColsWidth(o => ({ ...o, [params.colDef.field]: params.width }))
  }, [])

  const openClearJobOrderDocDialog = useCallback(() => {
    setDialogClearJobOrderDocOpen(true)
  }, [])

  const handleDesktopDatePickerChange = useCallback((newValue) => {
    setAptTm(newValue)
    refreshData(newValue)
  }, [refreshData])

  const handleDatePickerKeyUp = useCallback((e) => {
    if (e.code === 'ArrowUp') {
      setAptTm(prev => {
        const newValue = prev.add(1, 'day')
        refreshData(newValue)
        return newValue
      });
    } else if (e.code === 'ArrowDown') {
      setAptTm(prev => {
        const newValue = prev.add(-1, 'day')
        refreshData(newValue)
        return newValue
      });
    }
  }, [refreshData])
  const getRowClassName = useCallback(({ row }) => {
    // if(row.DrvId === 151){
    //   debugger
    //   console.log("row::", row)
    // }
    const yd = row.yd
    const td = row.td
    const tm = row.tm
    const atm = row.atm
    return (yd.length > 1 && yd.find(item => item.Type === "Attn"))
      || (td.length > 1 && td.find(item => item.Type === "Attn"))
      || (tm.length > 1 && tm.find(item => item.Type === "Attn"))
      || (atm.length > 1 && atm.find(item => item.Type === "Attn")) ? "error" : ""
  }, [])
  console.log("DriverTable render::", driverTable)
  console.log("leftPanelWidth::", leftPanelWidth)
  return (
    <BoxFC sx={{ width: `calc(100% - ${leftPanelWidth}px)` }} position="relative">

      <Paper sx={{ flex: 1, p: 1, width: "100%" }}>
        <BoxFC height="100%" sx={{ gap: 1 }}>
          <BoxFR>
            <Typography variant="h6">ตารางงานของ พขร. </Typography>
            <DesktopDatePicker label="วันที่" value={aptTm}
              renderInput={(params) => <TextField {...params} sx={{ width: 150 }} size="small" onKeyUp={handleDatePickerKeyUp} />}
              onChange={handleDesktopDatePickerChange} />
            <IconButton onClick={() => refreshData(aptTm)}>
              <RefreshRounded color="primary" />
            </IconButton>
            <Box flex={1} />
          </BoxFR>
          {leftPanelWidth <= 850 ? (
            <BoxFC sx={{ gap: 0 }}>
              <BoxFR>
                <BoxFC>
                  <Typography variant="body" fontWeight={"bold"}>FILTER: </Typography>
                </BoxFC>
                <BoxFR sx={{ gap: 0 }}>
                  <BoxFR sx={{
                    border: `${filterFDayAllUnchecked ? 1 : 2}px solid ${filterFDayAllUnchecked ? grey[400] : blue[700]}`,
                    borderRadius: 1,
                    p: 1,
                    position: "relative",
                    gap: 0
                  }}>
                    <BoxFC sx={{ gap: 2 }}>
                      {/* <RadioGroup value={filterData.IsAval} sx={{ width: 90 }}
                        onChange={(e) => { setFilterData(o => ({ ...o, IsAval: +e.target.value })) }} >
                        <FormControlLabel value={0} control={<Radio sx={{ px: 1, py: 0.7 }} />} label="ไม่ว่าง" />
                        <FormControlLabel value={1} control={<Radio sx={{ px: 1, py: 0.7 }} />} label="ว่าง" />
                      </RadioGroup> */}

                      <CheckboxFormControl sx={{ width: 80 }} checked={filterData.IsAval === true} label="ว่าง"
                        onChange={e => {
                          setFilterData(o => {
                            return { ...o, IsAval: e.target.checked ? true : null }
                          })
                        }} />
                      <CheckboxFormControl sx={{ width: 80 }} checked={filterData.IsAval === false} label="ไม่ว่าง"
                        onChange={e => {
                          setFilterData(o => {
                            return { ...o, IsAval: e.target.checked ? false : null }
                          })
                        }} />
                    </BoxFC>
                    <Divider orientation="vertical" flexItem sx={{ mr: 1, height: 50, alignSelf: "center" }} />
                    <BoxFC>
                      <BoxFR>
                        <CheckboxFormControl {...checkboxProps("yd")} label="วันก่อน" />
                        <CheckboxFormControl {...checkboxProps("td")} label="วันนี้" />
                        <CheckboxFormControl {...checkboxProps("tm")} label="พรุ่งนี้" />
                        <CheckboxFormControl {...checkboxProps("atm")} label="วันหน้า" />
                      </BoxFR>
                      <BoxFR>
                        {msData.containerStatuses.map(sts => (
                          <CheckboxFormControl disabled={filterData.IsAval !== false} checked={filterData.ContSts[sts.ContStsId]} sx={{ width: 100 }} label={<BoxFR sx={{ gap: 0.5 }}>
                            <Box sx={{
                              width: 10, height: 16, borderRadius: 1,
                              border: `1px solid ${theme.palette[sts.BgClr].main}`,
                              bgcolor: theme.palette[sts.BgClr].main
                            }} />
                            <Typography >{sts.Abbr}</Typography>
                          </BoxFR>}
                            onChange={e => {
                              setFilterData(o => ({
                                ...o, ContSts: {
                                  ...o.ContSts,
                                  1: false,
                                  3: false,
                                  4: false,
                                  5: false,
                                  [sts.ContStsId]: e.target.checked
                                }
                              }))
                            }} />
                        ))}
                      </BoxFR>
                    </BoxFC>
                    <Typography variant="caption" sx={{
                      position: "absolute", top: -10, left: 10, bgcolor: "white", px: 1
                    }}>
                      พนักงานขับรถ
                    </Typography>
                  </BoxFR>
                  {/* <BoxFR sx={{
                    border: `${filterFDayAllUnchecked ? 1 : 2}px solid ${filterFDayAllUnchecked ? grey[400] : blue[700]}`,
                    borderRadius: 1,
                    p: 1,
                    position: "relative",
                  }}>
                    <BoxFC>
                      <BoxFR>
                        <CheckboxFormControl {...checkboxProps("yd")} label="วันก่อน" />
                        <CheckboxFormControl {...checkboxProps("td")} label="วันนี้" />
                        <CheckboxFormControl {...checkboxProps("tm")} label="พรุ่งนี้" />
                        <CheckboxFormControl {...checkboxProps("atm")} label="วันหน้า" />
                      </BoxFR>
                      <BoxFR>
                        {msData.containerStatuses.map(sts => (
                          <CheckboxFormControl value={filterData.ContSts[sts.ContStsId]} sx={{ width: 110 }} label={<BoxFR sx={{ gap: 0.5 }}>
                            <Box sx={{
                              width: 10, height: 16, borderRadius: 1,
                              border: `1px solid ${theme.palette[sts.BgClr].main}`,
                              bgcolor: theme.palette[sts.BgClr].main
                            }} />
                            <Typography >{sts.Name}</Typography>
                          </BoxFR>}
                            onChange={e => { setFilterData(o => ({ ...o, ContSts: { ...o.ContSts, [sts.ContStsId]: e.target.checked } })) }} />
                        ))}
                      </BoxFR>
                    </BoxFC>
                    <Typography variant="caption" sx={{
                      position: "absolute", top: -10, left: 10, bgcolor: "white", px: 1, height: 20
                    }}>
                      พนักงานขับรถไม่ว่าง
                    </Typography>
                  </BoxFR> */}
                </BoxFR>

                <BoxFC sx={{ gap: 1 }}>
                  <BoxFR>
                    <TextField {...textFieldProp("JobNo")} />
                    <TextField {...textFieldProp("Bkg")} />
                    {/* <ComboBox sx={{ width: 150 }} options={msData.cusCombo} label="ลูกค้า"
                      selectedId={filterData.CusIds[0] || null}
                      setSelectedId={(id) => setFilterData(o => ({ ...o, CusIds: [id] }))}
                    />
                    <ComboBox sx={{ width: 150 }} options={msData.jobCombo} label="งาน"
                      selectedId={filterData.JobIds[0] || null}
                      setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
                    /> */}
                  </BoxFR>
                  <BoxFR >
                    <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label="พนักงานขับรถ"
                      selectedId={filterData.DrvId}
                      setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
                    />
                  </BoxFR>
                  {/* <BoxFR>
                    <ComboBox sx={{ width: 150 }} options={msData.jobCombo} label="เลขรถ"
                      selectedId={filterData.TukId}
                      setSelectedId={(id) => setFilterData(o => ({ ...o, TukId: id }))}
                    />
                  </BoxFR> */}
                </BoxFC>
                <Button onClick={handleClearFilter} color="secondary" variant="contained" sx={{ height: "100%" }}>
                  <BoxFC sx={{ alignItems: "center" }} >
                    <BackspaceRounded sx={{ mr: 1 }} />
                    ล้างค่า
                  </BoxFC>
                </Button>
                {/* <ClearButton onClick={handleClearFilter} /> */}
              </BoxFR>
              <Box mt={-1}>
                <SummaryBar
                  initFilter={initFilterData}
                  dataTable={summaryData}
                  summaryFilter={filterData}
                  setSummaryFilter={setFilterData}
                  selectionModel={[]}
                  displayModule={displayModule} />
              </Box>
            </BoxFC>
          ) : null}
          <BoxFR>
            <Typography variant="body">เลือกทั้งหมด: {selectionDrvs.length} คน</Typography>
            <CheckboxFormControl value={showCardDetail} onChange={(e) => setShowCardDetail(e.target.checked)} label={"แสดงข้อมูลงาน"} />
            <Box flex={1} />
            <Button variant="contained" onClick={handlePrint}>{<PrintRounded sx={{ mr: 1 }} />} พิมพ์ใบงานที่เลือก</Button>

            <BoxFR >
              <ComboBox options={msData.driverOnlyCombo} label="รับใบงาน" sx={{ width: 120 }}
                selectedId={drvIdJobOrder}
                setSelectedId={(id) => setDrvIdJobOrder(id)} />
              <Button variant="contained" sx={{ minWidth: 0, p: 1 }} onClick={openClearJobOrderDocDialog}>
                <PersonSearchRounded />
              </Button>
            </BoxFR>
          </BoxFR>
          <Box flex={1} sx={sxHover}>
            <DataGridCellExpand
              componentsProps={{
                panel: {
                  sx: {
                    left: '150px !important',
                  },
                },
              }}
              headerHeight={70}
              checkboxSelection
              selectionModel={selectionDrvs}
              onSelectionModelChange={(newSelectionModel) => {
                setSelectionDrvs(newSelectionModel);
              }}
              getRowHeight={() => 'auto'}
              disableSelectionOnClick
              hideFooter
              rows={filteredDriverData}
              columns={columns}
              onColumnWidthChange={handleColumnChnage}
              getRowClassName={getRowClassName} />
          </Box>
          <Typography variant="caption" ><strong>หมายเหตุ: </strong>การแสดงวันลาของ <strong>วันก่อน</strong>แสดงเฉพาะเมื่อวาน และ<strong>วันหน้า</strong>แสดงเฉพาะวันมะรืนเท่านั้น</Typography>
        </BoxFC>
      </Paper>
      <DailyJobDialogV2
        dialogOpen={dailyJobDialogOpen}
        setDialogOpen={setDailyJobDialogOpen}
        selectedId={selectedJobOrdId}
        onFinish={onDailyJobDialogFinish}
        lastFilter={null} />
      <ClearJobOrderDocDialog
        dialogOpen={dialogClearJobOrderDocOpen}
        setDialogOpen={setDialogClearJobOrderDocOpen}
        drvId={drvIdJobOrder}
      />

      <BoxFC position="absolute" top="40%" left={-15}>
        <Box {...boxExpandProps} onClick={() => { setLeftPanelWidth(500) }}>
          <KeyboardDoubleArrowLeftRounded color="primary" sx={{ fontSize: 25, mt: 1 }} />
        </Box>

        <Box {...boxExpandProps} onClick={() => { setLeftPanelWidth(850) }}>
          <SettingsEthernetRounded color="primary" sx={{ fontSize: 25, mt: 1 }} />
        </Box>
        <Box {...boxExpandProps} onClick={() => { setLeftPanelWidth(1100) }}>
          <KeyboardDoubleArrowRightRounded color="primary" sx={{ fontSize: 25, mt: 1 }} />
        </Box>
      </BoxFC>
    </BoxFC>
  )
}

export default DriverData