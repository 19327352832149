import { Box, Button, ButtonGroup, Paper, TextField, Typography, useMediaQuery } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import ComboBox from "components/ComboBox";
import DateTimeSelect from "components/DateTimeSelect";
import ClearButton from "components/buttons/ClearButton";
import SearchButton from "components/buttons/SearchButton";
import { UserContext } from "contexts/UserContext";
import { clearFilterData, initFilterData } from "./initData";
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { invoiceApiNm, invoiceColNm } from "constants/invoiceConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { addIdForDataGrid } from "utils";
import { columns } from "./columns";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor";
import { grey } from "@mui/material/colors";
import InvoiceDialog from "./InvoiceDialog";
import dayjs from "dayjs";
import { useParams } from "react-router-dom/cjs/react-router-dom.min";
import PopoverBarebone from "components/PopoverBarebone";
import { FilterListRounded } from "resource/muiImport";

let lastFilter = { ...initFilterData }
let selectedInvId = null
let selectedContNo = null

const GroupView = ({ viewMode, handleViewModeChange }) => (
  <Box p={0.8} sx={{ border: 1, borderColor: grey[400], borderRadius: 1, position: "relative" }}>
    <ButtonGroup>
      <Button size="small" variant={viewMode === "Inv" ? "contained" : "outlined"}
        onClick={() => handleViewModeChange("Inv")}>Invoice</Button>
      <Button siez="small" variant={viewMode === "ContNo" ? "contained" : "outlined"}
        onClick={() => handleViewModeChange("ContNo")}>Container</Button>
    </ButtonGroup>
    <Box width={40} height={10} sx={{ position: "absolute", top: -8, left: 6, bgcolor: "white" }} />
    <Typography variant="caption" sx={{ position: "absolute", top: -11, left: 6, px: 1, color: grey[700], fontSize: 11 }}>View</Typography>

  </Box>
)
const ClientInvoice = () => {

  const { user } = useContext(UserContext)
  const { ax, msData } = useContext(GlobalStateContext)

  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [dataTable, setDataTable] = useState([])
  const [jobCombo, setJobCombo] = useState(msData.jobCombo)
  const [viewMode, setViewMode] = useState("Inv") // ["Inv", "ContNo"
  const [dialogInvoiceOpen, setDialogInvoiceOpen] = useState(false)

  const w800 = useMediaQuery('(min-width:800px)');

  const { mField, mValue } = useParams();

  const modDataTable = useMemo(() => {
    return addSumRow({
      dataTable: dataTable,
      sumColNms: ["TrnsAmnt", "OthChrg", "CusRcptAmnt", "Total", "WhtPr", "PayTotal"],
      sumColTotal: "InvNo"
    })
  }, [dataTable])

  const getInvoice = useCallback((filter) => {
    ax.post(invoiceApiNm.getInvoiceTableForClient, { ...filter, View: viewMode }).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, viewMode === "Inv" ? "InvId" : "ContNo"))
        lastFilter = { ...filter }
      }
    })
  }, [ax, viewMode])

  const handleCusSelect = useCallback((id) => {
    console.log("cusID::", id);
    setFilterData(oldState => ({ ...oldState, CusId: id, JobId: null }));
    if (id) {
      setJobCombo(msData.jobCombo.filter(item => item.refId === id));
    } else {
      setJobCombo(msData.jobCombo);
    }
  }, [msData])

  const handleOpenDialog = useCallback((row) => {
    selectedInvId = row.InvId
    selectedContNo = viewMode === "Inv" ? null : row.ContNo
    setDialogInvoiceOpen(true)
  }, [viewMode])

  const filterProps = useCallback((name) => ({
    variant: "outlined",
    size: "small",
    name: name,
    sx: { width: 130 },
    label: invoiceColNm[name],
    value: filterData[name] || "",
    onKeyUp: e => { if (e.code === "Enter" || e.code === "NumpadEnter") { getInvoice(filterData) } },
    onChange: (e) => { setFilterData(oldState => ({ ...oldState, [name]: e.target.value || null })) }
  }), [filterData, getInvoice])

  const filterPropsMobile = useCallback((name) => ({
    ...filterProps(name),
    sx: { width: "100%" }
  }), [filterProps])

  const handleViewModeChange = useCallback((mode) => {
    setViewMode(mode)
  }, [])

  useEffect(() => {
    if (mField && mValue) {
      if (mField === "YearMonth") {
        const dteSt = dayjs(mValue.toString(), "YYYYMM").startOf("month")
        const dteEn = dteSt.endOf("month")
        lastFilter = {
          ...lastFilter,
          InvDteSt: dteSt.format("YYYY-MM-DD"),
          InvDteEn: dteEn.format("YYYY-MM-DD")
        }
        setFilterData({ ...lastFilter })
      }
    } else {
      lastFilter = { ...lastFilter, View: viewMode }
    }
    getInvoice(lastFilter)
  }, [viewMode, getInvoice, mField, mValue])

  return (
    user?.Pms?.find(pm => pm.Code === "FIN") ?
      <BoxFC height="100%">
        {w800 ? (
          <BoxFR>
            <GroupView viewMode={viewMode} handleViewModeChange={handleViewModeChange} />
            <Box flex={1} />
            <DateTimeSelect
              sx={{ width: 160 }}
              label="วันที่ใบแจ้งหนี้"
              start={filterData.InvDteSt}
              end={filterData.InvDteEn}
              setSelectDate={(start, end) => { setFilterData(o => ({ ...o, InvDteSt: start, InvDteEn: end })) }}
              getData={() => { getInvoice(filterData) }}
            />
            <DateTimeSelect
              sx={{ width: 180 }}
              label="วันที่นัดเข้าโรงงาน"
              start={filterData.AptTmSt}
              end={filterData.AptTmEn}
              setSelectDate={(start, end) => { setFilterData(o => ({ ...o, AptTmSt: start, AptTmEn: end })) }}
              getData={() => { getInvoice(filterData) }}
            />
            <TextField {...filterProps("InvNo")} />
            <TextField {...filterProps("BNNo")} />
            <TextField {...filterProps("JobNo")} />
            <TextField {...filterProps("Bkg")} />
            <TextField {...filterProps("ContNo")} />
            <ComboBox sx={{ width: 150 }} options={msData.cusCombo} label="ลูกค้า"
              selectedId={filterData.CusId}
              setSelectedId={handleCusSelect}
            />
            <ComboBox sx={{ width: 150 }} options={jobCombo} label="งาน"
              selectedId={filterData.JobId}
              setSelectedId={(id) => setFilterData(oldState => ({ ...oldState, JobId: id }))}
            />
            <SearchButton onClick={() => { getInvoice(filterData) }} />
            <ClearButton onClick={() => { setFilterData(clearFilterData) }} />
          </BoxFR>
        ) : (
          <BoxFR justifyContent="space-between">
            <PopoverBarebone sx={{ flex: 1, maxWidth: 120 }}
              transformOrigin={{ vertical: "top", horizontal: "left" }}
              anchorOrigin={{ vertical: "bottom", horizontal: "left" }}
              component={<Button variant="contained" sx={{ width: "100%" }}>
                <FilterListRounded sx={{ mr: 1 }} /> FILTER
              </Button>}
            >
              <Paper sx={{ p: 2, width: 230 }}>
                <BoxFC sx={{ gap: 1 }}>
                  <DateTimeSelect
                    sx={{ width: "100%" }}
                    label="วันที่ใบแจ้งหนี้"
                    start={filterData.InvDteSt}
                    end={filterData.InvDteEn}
                    setSelectDate={(start, end) => { setFilterData(o => ({ ...o, InvDteSt: start, InvDteEn: end })) }}
                    getData={() => { getInvoice(filterData) }}
                  />
                  <DateTimeSelect
                    sx={{ width: "100%" }}
                    label="วันที่นัดเข้าโรงงาน"
                    start={filterData.AptTmSt}
                    end={filterData.AptTmEn}
                    setSelectDate={(start, end) => { setFilterData(o => ({ ...o, AptTmSt: start, AptTmEn: end })) }}
                    getData={() => { getInvoice(filterData) }}
                  />
                  <TextField {...filterPropsMobile("InvNo")} />
                  <TextField {...filterPropsMobile("BNNo")} />
                  <TextField {...filterPropsMobile("JobNo")} />
                  <TextField {...filterPropsMobile("Bkg")} />
                  <TextField {...filterPropsMobile("ContNo")} />
                  <ComboBox sx={{ width: "100%" }} options={msData.cusCombo} label="ลูกค้า"
                    selectedId={filterData.CusId}
                    setSelectedId={handleCusSelect}
                  />
                  <ComboBox sx={{ width: "100%" }} options={jobCombo} label="งาน"
                    selectedId={filterData.JobId}
                    setSelectedId={(id) => setFilterData(oldState => ({ ...oldState, JobId: id }))}
                  />
                  <BoxFR>
                    <SearchButton onClick={() => { getInvoice(filterData) }} />
                    <ClearButton onClick={() => { setFilterData(clearFilterData) }} />
                  </BoxFR>
                </BoxFC>
              </Paper>
            </PopoverBarebone>

            <GroupView viewMode={viewMode} handleViewModeChange={handleViewModeChange} />
          </BoxFR>

        )}

        <Box width='100%' mt={w800 ? 2 : 0} flexGrow={1} sx={sxSumColorClass}>
          <DataGridCellExpand
            density={w800 ? "standard" : "compact"}
            hideFooter
            rows={modDataTable}
            columns={columns(handleOpenDialog)}
            // onRowDoubleClick={(params, event, detail) => fn.openDialog(params.id)}
            getRowClassName={getSumColorClassName} />
        </Box>
        <InvoiceDialog
          dialogOpen={dialogInvoiceOpen}
          setDialogOpen={setDialogInvoiceOpen}
          invId={selectedInvId}
          contNo={selectedContNo} />
      </BoxFC>
      : "You don't have permission to access this page"
  )
}

export default ClientInvoice