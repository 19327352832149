import React, { useCallback, useContext, useEffect, useMemo, useState } from "react";
import { Box, Button, Dialog, DialogContent, FormControlLabel, IconButton, Radio, RadioGroup, TextField } from '@mui/material'
import ModAccName from 'components/ModAccName';
import { DialogFooter, DialogHeader } from 'components/dialog/DialogHeader';
import { initDialogData, cusColNm } from 'constants/cusConstant';
import { cusApiNm } from "constants/cusConstant";
import useDialogOpen from "hooks/useDialogOpen";
import { loadMSData, validate } from "utils";
import PaperComponent from "components/PaperComponent";
import { BoxFC, BoxFR } from "components/BoxCustom";
import FileUpload from "components/FileUplaod/FileUpload";
import { initFileUploadData } from "constants/fileUploadConstant";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { alertConfirmDelete } from "components/Alert";
import { FOLDER_CUSTOMER_FILE } from "constants/serverConstant";
import { saveFile } from "components/FileUplaod/saveFile";
import { alertWarning } from "components/Alert";
import CheckboxFormControl from "components/CheckboxFormControl";
import TextFieldChangeLanguage from "components/TextFieldChangeLanguage";
import { CancelRounded, SearchRounded } from "resource/muiImport";
import SearchCompanyAddrDialog from "components/SearchCompanyAddrDialog";

const CustomerDialog = ({ state, fn }) => {
  const { dialogOpen, selectedCusId } = state
  const { setDialogOpen, onFinish } = fn
  const [dialogData, setDialogData] = useState(initDialogData)
  const [dialogSearchAddrOpen, setDialogSearchAddrOpen] = useState(false)
  const [fileUploadData, setFileUploadData] = useState(initFileUploadData())
  const { ax, msData, setMSData } = useContext(GlobalStateContext)

  const modifyData = useCallback((dialogData) => {
    dialogData.IsHeadBrch = dialogData.BrchNo === 0 ? 1 : 0
    const serverFiles = dialogData.Files.map(item => ({
      id: item.CusFileId,
      fileNm: item.FileNm,
      label: item.Label,
      oLabel: item.Label,
      CusId: selectedCusId,
      isEdit: false,
      file: null,
      imgLink: `${window.location.origin}/${FOLDER_CUSTOMER_FILE}${item.CusId}/${item.FileNm}`,
    }))
    setFileUploadData({ ...initFileUploadData(), serverFiles })
    return dialogData
  }, [selectedCusId])

  useDialogOpen(dialogOpen, selectedCusId, cusApiNm.getCustomer, "CusId", setDialogData, initDialogData, modifyData)

  const validation = useMemo(() => ({
    Name: dialogData.Name === '',
    SName: dialogData.SName === '',
    Abbr: dialogData.Abbr?.length > 5,
    LnkSiteAbbr: dialogData.LnkSiteAbbr?.length > 3,
  }), [dialogData])

  const dialogDataProp = useCallback((name) => ({
    variant: 'outlined',
    size: 'small',
    name: name,
    label: cusColNm[name],
    value: dialogData[name] || "",
    error: validation[name],
    onChange: (e) => { setDialogData((oldState) => ({ ...oldState, [name]: e.target.value })) }
  }), [dialogData, validation])

  const insertUpdateCustomer = useCallback(async (dialogData) => {
    let result;
    let cusId = selectedCusId
    if (dialogData.CusId > 0) {
      result = await ax.post(cusApiNm.updateCustomer, dialogData);
    } else {
      result = await ax.post(cusApiNm.insertCustomer, dialogData);
      cusId = result.data
    }
    if (result.data) {

      await saveFile({
        ax,
        alertWarning,
        fileUploadData,
        setFileUploadData,
        apiUpdateFileName: cusApiNm.updateCustomerFileName,
        apiDeleteFile: cusApiNm.deleteCustomerFile,
        apiAddFile: cusApiNm.uploadCustomerFile,
        key: "CusFileId",
        refKey: "CusId",
        refValue: cusId
      })
      onFinish(result.data);
      setDialogOpen(false);
      loadMSData(ax, setMSData, null)
    }
  }, [ax, setMSData, setDialogOpen, onFinish, fileUploadData, selectedCusId])

  const deleteCustomer = useCallback((cusId) => {
    alertConfirmDelete(() => {
      ax.post(cusApiNm.deleteCustomer, { CusId: cusId }).then(value => {
        if (value.data) {
          onFinish();
          setDialogOpen(false);
          loadMSData(ax, setMSData, null)
        }
      })
    })
  }, [ax, setMSData, onFinish, setDialogOpen])

  const handleIsHeadBrchChange = useCallback((e) => {
    console.log("e.target.value::", e.target.value)
    if (+e.target.value === 1) {
      console.log("e.target.value::1")
      setDialogData(o => ({ ...o, IsHeadBrch: 1, BrchNo: "0" }))
    } else {
      console.log("e.target.value::0")
      setDialogData(o => ({ ...o, IsHeadBrch: 0 }))
    }
  }, [])

  const onSearchAddrFinish = useCallback((addrData) => {
    if (addrData) {
      setDialogData(o => ({
        ...o,
        Name: addrData.Name,
        Addr: addrData.Addr,
        TaxId: addrData.TaxId,
      }))
    }
  }, [])
  useEffect(() => {
    if (!dialogOpen) {
      setFileUploadData(initFileUploadData())
    }
  }, [dialogOpen])
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='md' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={selectedCusId === 0 ? "แก้ไขลูกค้า" : "เพิ่มลูกค้า"}
        onDeleteClick={selectedCusId > 0 ? () => deleteCustomer(selectedCusId) : null}
      >
        {selectedCusId > 0 ? null :
          <Button color="secondary" variant="contained"
            sx={{ ml: 1 }} onClick={() => setDialogSearchAddrOpen(true)}>
            <SearchRounded sx={{ mr: 1 }} />ค้นหาที่อยู่
          </Button>
        }
      </DialogHeader>
      <DialogContent>
        <BoxFC >
          <BoxFR mt={2} sx={{ alignItems: "stretch" }}>
            <Box display='flex' flex={1} flexDirection='column' gap={1} >

              <CheckboxFormControl label={"Active"}
                checked={dialogData.IsActv === 1 ? true : false}
                onChange={e => setDialogData(o => ({ ...o, IsActv: e.target.checked ? 1 : 0 }))} />
              <TextFieldChangeLanguage {...dialogDataProp('Name')} required />
              <TextFieldChangeLanguage {...dialogDataProp('SName')} required />
              <BoxFR>
                <TextFieldChangeLanguage {...dialogDataProp('Abbr')} sx={{flex: 1}}  value={dialogData.Abbr ?? ""}
              onChange={(e)=> setDialogData(o => ({ ...o, Abbr: e.target.value || null }))}
                  helperText={dialogData.Abbr && dialogData.Abbr.length > 5 ? "ตัวย่อต้องไม่เกิน 5 ตัวอักษร" : ""} />
                <TextFieldChangeLanguage {...dialogDataProp('LnkSiteAbbr')} sx={{flex: 1}}  value={dialogData.LnkSiteAbbr ?? ""}
              onChange={(e)=> setDialogData(o => ({ ...o, LnkSiteAbbr: e.target.value || null }))}
                  helperText={dialogData.LnkSiteAbbr && dialogData.LnkSiteAbbr.length > 3 ? "ตัวย่อต้องไม่เกิน 3 ตัวอักษร" : ""} />
              </BoxFR>
              <TextFieldChangeLanguage {...dialogDataProp('Addr')} multiline rows={3} />
              <BoxFR>
                <TextFieldChangeLanguage {...dialogDataProp('TaxId')} sx={{ width: 220 }} />
                <RadioGroup row value={dialogData.IsHeadBrch} onChange={handleIsHeadBrchChange}>
                  <FormControlLabel value={1} control={<Radio />} label="สำนักงานใหญ่" />
                  <FormControlLabel value={0} control={<Radio />} label="สาขาที่" />
                  <TextField size="small" {...dialogDataProp('BrchNo')} disabled={dialogData.IsHeadBrch === 1}
                    label="เลขที่สาขา" type="number" sx={{ width: 80 }}
                    inputProps={{ style: { textAlign: 'center' } }} />
                </RadioGroup>
              </BoxFR>
              <TextFieldChangeLanguage {...dialogDataProp('CtDscp')} multiline rows={1} />
              <BoxFR sx={{ alignItems: "flex-start" }}>
                <TextFieldChangeLanguage {...dialogDataProp("LineGrpNm")} disabled fullWidth
                  helperText="*** สามารถเพิ่มได้โดย เพิ่ม Emily เป็นเพื่อนและดึง Emily เข้ากลุ่ม" />
                <IconButton sx={{ p: 0, pt: 0.2 }} onClick={() => setDialogData(o => ({ ...o, LineGrpNm: "", LineId: null }))}>
                  <CancelRounded fontSize="large" />
                </IconButton>
              </BoxFR>
              <TextFieldChangeLanguage {...dialogDataProp('CtMailCont')} />
              <TextFieldChangeLanguage {...dialogDataProp('CtMailInv')} />
              <TextFieldChangeLanguage {...dialogDataProp('XpssCusCod')} />
              <TextFieldChangeLanguage {...dialogDataProp('XpssSlmCod')} />
              <TextFieldChangeLanguage {...dialogDataProp('Rmk')} />
            </Box>
            <FileUpload
              width={300}
              options={msData.serverData.PreText.CustomerFiles || []}
              fileUploadData={fileUploadData}
              setFileUploadData={setFileUploadData}
            />
          </BoxFR>
          {
            selectedCusId > 0 && <ModAccName modDte={dialogData.ModDte} modAcc={dialogData.ModAcc} />
          }
        </BoxFC>
        <SearchCompanyAddrDialog
          dialogOpen={dialogSearchAddrOpen}
          setDialogOpen={setDialogSearchAddrOpen}
          onFinish={onSearchAddrFinish} />

      </DialogContent>
      <DialogFooter okText={selectedCusId > 0 ? "แก้ไขข้อมูล" : "เพิ่มข้อมูล"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={() => validate(validation) && insertUpdateCustomer(dialogData)} />
    </Dialog>
  )
}

export default React.memo(CustomerDialog)