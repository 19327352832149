
import React, { useMemo, useState, useCallback, useEffect, useContext } from "react"
import { Box, Button, Dialog, DialogContent, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { addSumRow, getSumColorClassName, sxSumColorClass } from "utils/highlightSumColor";
import { GlobalStateContext } from "contexts/GlobalStateContext"
import { shippingApiNm } from "./shippingApiNm"
import { ContentPasteRounded, FileCopyRounded } from "resource/muiImport"
import { addIdForDataGrid, toNumber } from "utils"
import { alertError } from "components/Alert"
import { approveCDColumns } from "./columns"
import { read } from 'xlsx';


let isEditing = false;

// const fetchData = await fetch(`/file/shipping/${selectedId}/${data.MTFiles[0].FileNm}`)
//             console.log("fetchData::", fetchData)
//             const f = await fetchData.arrayBuffer();
const readMultipleFiles = async (files, mulItms) => {
  try {
    const multiFile = files.find(file => file.ShpFileTypId === 6)
    if(!multiFile) return null
    
      const file = multiFile.FileNm
      const fetchData = await fetch(`/file/shipping/${multiFile.ShpInvId}/${file}`)
      const fileArrayBuffer = await fetchData.arrayBuffer();
      
    

    const wb = read(fileArrayBuffer); // parse the array buffer
    const ws0 = wb.Sheets[wb.SheetNames[0]]; // get the first worksheet
    let contNoCol = ""
    let gwCol = ""
    let pscCol = ""
    let cntCol = ""
    let cbmCol = ""
    let runRow = 0;
    for (let i = 1; i < 10; i++) {
      runRow = i
      for (let col of ["A", "B", "C", "D", "E", "F", "G", "H", "I", "J", "K"]) {
        const str = ws0[`${col}${i}`]?.v
        if (typeof str === 'string' || str instanceof String) {
          const colValue = str.toLowerCase()
          if (colValue.indexOf("container") >= 0 || colValue.indexOf("contrainer") >= 0) {
            contNoCol = col
          } else if (colValue.indexOf("shipment") >= 0) { //GW.(shipment)
            gwCol = col
          } else if (colValue.indexOf("psc") >= 0) {
            pscCol = col
          } else if (colValue.indexOf("cnt") >= 0) {
            cntCol = col
          } else if (colValue.indexOf("cbm") >= 0) {
            cbmCol = col
          }
        }
      }
      if (contNoCol && gwCol && pscCol && cntCol && cbmCol) {
        break
      }
    }
    if (!contNoCol || !gwCol || !pscCol || !cntCol || !cbmCol) {
      const errArr = []
      if (!contNoCol) errArr.push("Container No")
      if (!gwCol) errArr.push("GW")
      if (!pscCol) errArr.push("PSC")
      if (!cntCol) errArr.push("CNT")
      if (!cbmCol) errArr.push("CBM")
      alertError(`ไม่พบข้อมูล ${errArr.join(", ")}`)
      return null
    }

    const result = []
    let isRunnig = true
    const MAX_ROW = 100
    let no = 0
    let emptyRowCount = 0
    while (isRunnig) {
      runRow++
      no++
      const contNo = ws0[`${contNoCol}${runRow}`]?.v || ""
      if (!contNo) {
        emptyRowCount++
        if (emptyRowCount > 3) {
          isRunnig = false
        }
        continue
      }
      if (runRow === MAX_ROW) {
        isRunnig = false
        continue
      }
      const gw = ws0[`${gwCol}${runRow}`]?.v || 0
      const psc = ws0[`${pscCol}${runRow}`]?.v || 0
      const cnt = ws0[`${cntCol}${runRow}`]?.v || 0
      const cbm = ws0[`${cbmCol}${runRow}`]?.v || 0
      // console.log("contNo::", contNo)
      // console.log("data.Conts::", data.Conts)
      const cont = mulItms.find(cont => cont.ContNo.trim() === contNo.trim())
      // const isoCd = cont?.ISOCd || ""
      const tareWt = cont?.TareWt || ""
      result.push({
        No: no,
        id: cont.JobOrdId,
        JobOrdId: cont.JobOrdId,
        ContNo: cont.ContNo,
        TareWt: tareWt,
        GW: gw,
        PSC: psc,
        CNT: cnt,
        CBM: cbm,
      })
    }
    return result


  } catch (e) {
    console.log("excel err::", e)
    return null
  }
}

let multipleFiles = []
const MultipleConainerDataDialog = ({ shpInvId, dialogModDte, cusId, dialogOpen, setDialogOpen }) => {

  const [excelData, setExcelData] = useState([])

  const { ax } = useContext(GlobalStateContext)

  const modDataTable = useMemo(() => {
    console.log('in MultipleConainerDataDialog excelData::', excelData)
    if (excelData.length > 0 && excelData[excelData.length - 1].id === -1) {
      return excelData
    }
    // return excelData
    return addSumRow({
      dataTable: excelData,
      sumColNms: ["GW", "PSC", "CNT", "CBM"],
      sumColTotal: "ContNo"
    })
  }, [excelData])

  const loadFileData = useCallback(async () => {
    const rowData = await readMultipleFiles(multipleFiles, excelData)
    if(rowData){
      setExcelData(rowData)
    }
  }, [excelData])

  const pasteDataFromClipboard = useCallback(() => {
    navigator.clipboard.readText().then(clipText => {
      const notFoundContNo = []
      setExcelData(o => {
        const results = o.map(item => ({ ...item }))

        const rows = clipText.trim().split('\n').map(row => row.split('\t'));
        console.log('rows::', rows)
        if (rows[0].length !== 5) {
          alert('ข้อมูลที่วางต้องมี 5 คอลัมน์เท่านั้น')
          return o
        }
        for (const row of rows) {
          const found = results.find(oRow => oRow.ContNo.trim() === row[0].trim())
          if (!found) {
            notFoundContNo.push(row[0])
            continue
          }
          found.GW = toNumber(row[1])
          found.PSC = toNumber(row[2])
          found.CNT = toNumber(row[3])
          found.CBM = toNumber(row[4])

        }
        if (notFoundContNo.length > 0) {
          alertError(`ไม่พบข้อมูล Container No: ${notFoundContNo.join(', ')}`)
        }
        return results
      })
    });
  }, [])

  const handleSave = useCallback(() => {
    console.log("isEditing::", isEditing)
    if (isEditing) {
      alertError("กำลังจัดการข้อมูลที่แก้ไขอยู่ กรุณากดบันทีกอีกครั้ง")
      return
    }
    const postData = {
      // ...dialogData,
      // ShpInvId: shpInvId,
      // ModDte: dialogModDte,
      argsArr: excelData.map(row => {
        return {
          ShpInvId: shpInvId,
          JobOrdId: row.JobOrdId,
          GW: row.GW,
          PSC: row.PSC,
          CNT: row.CNT,
          CBM: row.CBM,
        }
      })
    }
    ax.post(shippingApiNm.insertShippingMultipleBulk, postData).then(value => {
      if (value.data) {
        setDialogOpen(false)
      }
    })
    setDialogOpen(false)

  }, [ax, setDialogOpen, excelData, shpInvId])

  const handleRowEditStart = useCallback((params) => {
    console.log("rowEidt start params::", params)
    isEditing = true
  }, [])

  const handleRowEidtStop = useCallback((params) => {
    console.log('params::', params)

    setExcelData(o => {
      return o.map(oRow => {
        if (params.id === oRow.id) {
          return params.row
        } else {
          return oRow
        }
      })
    })
    isEditing = false
  }, [])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(shippingApiNm.getShippingInvMultipleItem, { ShpInvId: shpInvId }).then(async value => {
        if (value.data) {
          setExcelData(addIdForDataGrid(value.data.multipleData, "JobOrdId"))
          multipleFiles = value.data.files
         
        }
      })
    } else {
      setExcelData([])
      isEditing = false
    }
  }, [dialogOpen, ax, shpInvId, setDialogOpen])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth
      maxWidth="lg"
      PaperProps={{
        style: {
          height: '90%',
        }
      }}
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="ข้อมูล Invoice-Packing List"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC height="100%" pt={2}>
          <BoxFR sx={{ alignItems: "flex-start" }}>
            <BoxFR>
              <Button variant="contained" onClick={loadFileData}>
                <FileCopyRounded sx={{ mr: 1 }} />
                ดึงข้อมูล Inv-PL จาก File(s)
              </Button>
              <Button variant="contained" onClick={pasteDataFromClipboard}>
                <ContentPasteRounded sx={{ mr: 1 }} />
                วางข้อมูลจาก Clipboard
              </Button>
              <Typography variant="caption" sx={{ alignSelf: "flex-end" }}>
                (ข้อมูลที่ต้องการ : หมายเลขตู้, GW, PSC, CNT, CBM)
              </Typography>

            </BoxFR>
          </BoxFR>

          <Box width='100%' flex={1} sx={sxSumColorClass}>
            <DataGridCellExpand
              hideFooter
              getRowClassName={getSumColorClassName}
              rows={modDataTable}
              columns={approveCDColumns(true)}
              editMode="row"
              onRowEditStart={handleRowEditStart}
              onRowEditStop={handleRowEidtStop} />
          </Box>
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handleSave} />
    </Dialog>
  )
}

export default React.memo(MultipleConainerDataDialog)