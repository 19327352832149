import { PrintRounded } from "resource/muiImport";
import { Box, Button, TextField, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import React, { useCallback, useState, useContext, useMemo } from "react";
import JobBox from "./JobBox";
import { printJobOrder } from "branch/functions/printJobOrder";
import { GlobalStateContext } from "contexts/GlobalStateContext";
import { jobOrderApiNm } from "constants/jobOrderConstant";
import { alertError } from "components/Alert";
import AddDataDialog from "./AddDataDialog";
import { jobHasDriverColumns, jobNoDriverColumns } from "./columns";
import CheckboxFormControl from "components/CheckboxFormControl";
import { blue, grey } from "@mui/material/colors";
import dayjs from "dayjs";
import ClearButton from "components/buttons/ClearButton";
import SummaryBar from "pages/Menu1/DailyJob/SummaryBar";

let selectedRow = []
const initFilterData = {
  FDay: { nd: false, yd: false, td: false, tm: false, atm: false },
  JobNo: "",
  JobIds: [],
  ShpmTypIds: [],
  TlrTypIds: [],
  CusIds: [],
}

const displayModule = {
  selection: false,
  sumRow: true,
  shpmType: false,
  trailerType: false,
  customer: true,
  job: false,
}
const JobOrderNoDriver = ({ jobData, selectionDrvs, handleSelect, leftPanelWidth, confirmSelect }) => {

  const { ax, msData } = useContext(GlobalStateContext)
  const [jobOrdId, setJobOrdId] = useState("")
  const [dialogAddDataOpen, setDialogAddDataOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })

  const filterdTable = useMemo(() => {
    const searchData = {
      hasNo: [...jobData.hasNo],
      hasTake: [...jobData.hasTake],
      hasLoc: [...jobData.hasLoc],
      hasBack: [...jobData.hasBack],
    }
    const resultFDay = { hasNo: [], hasTake: [], hasLoc: [], hasBack: [] }
    let hasNoFilter = true
    if (filterData.FDay.nd) {
      for (const key in searchData) {
        resultFDay[key].push(...searchData[key].filter(item => !item.AptTm))
      }
      hasNoFilter = false
    }
    if (filterData.FDay.yd) {
      for (const key in searchData) {
        resultFDay[key].push(...searchData[key].filter(item => item.AptTm && dayjs(item.AptTm).isBefore(dayjs().startOf("day"))))
      }
      hasNoFilter = false
    }
    if (filterData.FDay.td) {
      for (const key in searchData) {
        resultFDay[key].push(...searchData[key].filter(item => item.AptTm && dayjs(item.AptTm).startOf("day").isSame(dayjs().startOf("day"))))
      }
      hasNoFilter = false
    }
    if (filterData.FDay.tm) {
      for (const key in searchData) {
        resultFDay[key].push(...searchData[key].filter(item => item.AptTm
          && dayjs(item.AptTm).startOf("day").isSame(dayjs().add(1, "day").startOf("day"))))
      }
      hasNoFilter = false
    }
    if (filterData.FDay.atm) {
      for (const key in searchData) {
        resultFDay[key].push(...searchData[key].filter(item => item.AptTm && dayjs(item.AptTm).isAfter(dayjs().add(1, "day").endOf("day"))))
      }
      hasNoFilter = false
    }
    const result = hasNoFilter ? { ...searchData, all: [...jobData.all] }
      : {
        ...resultFDay,
        all: [
          ...resultFDay.hasNo,
          ...resultFDay.hasTake,
          ...resultFDay.hasLoc,
          ...resultFDay.hasBack
        ]
      }
    if (filterData.JobNo) {
      for (const key in result) {
        result[key] = result[key].filter(item => item.JobNo.indexOf(filterData.JobNo) > -1)
      }
    }
    if (filterData.JobIds.length > 0) {
      for (const key in result) {
        result[key] = result[key].filter(item => filterData.JobIds.includes(item.JobId))
      }
    }
    if (filterData.CusIds.length > 0) {
      for (const key in result) {
        result[key] = result[key].filter(item => filterData.CusIds.includes(item.CusId))
      }
    }
    return result
  }, [filterData, jobData])

  const checkboxProps = useCallback((name) => ({
    value: filterData.FDay[name],
    onChange: (e) => { setFilterData(o => ({ ...o, FDay: { ...o.FDay, [name]: e.target.checked } })) }
  }), [filterData])

  const filterFDayAllUnchecked = useMemo(() => {
    return Object.keys(filterData.FDay).every(key => filterData.FDay[key] === false)
  }, [filterData.FDay])

  const handlePrintEmptyJobOrder = useCallback(() => {
    const printDataArr = [{
      jobOrderData: {
        AptTm: null,
        TalePlc: "",
        Loc: "",
        RtnPlc: "",
        ContSize: "",
        ContTyp: "",
        ContHq: "",
        ContNo: "",
        SealNo: "",
        Rmk: "",
        Ref1: "",
        CusNm: "",
      },
      shipmentData: {
        JobNo: "",
        CusNm: "",
        Bkg: "",
        Agnt: "",
        Job: { RcptNm: "", RcptAddr: "", RcptTaxId: "", RcptBrchNo: "" },
      },
      jobOrdIds: [""],
      isPrintDateTime: true,
      fName: ""
    }]
    printJobOrder({ printDataArr, msData })
  }, [msData,])

  const handleGiveJobOrder = useCallback(() => {
    if (selectionDrvs.length > 1) {
      alertError("กรุณาเลือกพนักงานขับรถไม่เกิน 1 คน")
      return
    }
    ax.post(jobOrderApiNm.getJobOrder, { JobOrdId: jobOrdId }).then(value => {
      if (value.data) {
        if (value.data.length === 0) {
          alertError("ไม่พบเลขที่ใบงานนี้")
          return
        }
        selectedRow = value.data[0]
        if (selectedRow.FinDte) {
          alertError("ใบงานนี้ถูกลงจบแล้ว ไม่สามารถจ่ายใบงานได้อีก")
          return
        }
        selectedRow.JobOrdIds = [jobOrdId]
        setDialogAddDataOpen(true)
      }
    })
  }, [ax, jobOrdId, selectionDrvs])
  // console.log("in JobOrderNoDriver Render jobData::", jobData)
  // console.log("in JobOrderNoDriver Render display::", displayJobData)
  // console.log("displayJobData::", displayJobData)

  const hasNoColumns = useMemo(() => {
    return jobNoDriverColumns(handleSelect)
  }, [handleSelect])

  const locColumns = useMemo(() => {
    return jobHasDriverColumns(handleSelect, "Loc")
  }, [handleSelect])

  const otherColumns = useMemo(() => {
    return jobHasDriverColumns(handleSelect)
  }, [handleSelect])
  return (

    <BoxFC sx={{
      pt: 1, gap: 1, overflowY: "none", pr: 1, width: leftPanelWidth, height: "100%"
      , transition: 'width 0.5s ease-in-out'
    }}>
      <BoxFR minWidth={750}>
        <Button variant="contained" onClick={handlePrintEmptyJobOrder}>
          <PrintRounded sx={{ mr: 1 }} /> พิมพ์ใบงานเปล่า
        </Button>
        <TextField label="เลขที่ใบงาน" size="small" type="number" sx={{ width: 150 }}
          onKeyUp={(e) => { if (e.key === "Enter") { handleGiveJobOrder() } }}
          value={jobOrdId} onChange={(e) => setJobOrdId(e.target.value)} />
        <Button onClick={handleGiveJobOrder} variant="contained">จ่ายใบงาน</Button>
      </BoxFR>
      <BoxFR>
        <Typography fontWeight={"bold"}>FILTER: </Typography>
        <BoxFR sx={{
          border: `${filterFDayAllUnchecked ? 1 : 2}px solid ${filterFDayAllUnchecked ? grey[400] : blue[700]}`,
          borderRadius: 1,
          p: 1,
          position: "relative",
        }}>
          <CheckboxFormControl {...checkboxProps("nd")} label="ไม่มีวันที่" />
          <CheckboxFormControl {...checkboxProps("yd")} label="วันก่อน" />
          <CheckboxFormControl {...checkboxProps("td")} label="วันนี้" />
          <CheckboxFormControl {...checkboxProps("tm")} label="พรุ่งนี้" />
          <CheckboxFormControl {...checkboxProps("atm")} label="วันหน้า" />
          <Typography variant="caption" sx={{
            position: "absolute", top: -10, left: 10, bgcolor: "white", px: 1
          }}>
            เวลานัด
          </Typography>

        </BoxFR>
        {
          leftPanelWidth >= 850 ?

            <BoxFR>
              <TextField size="small" label="JobNo" sx={{flex: 1 }}
                value={filterData.JobNo} onChange={e => setFilterData(o => ({ ...o, JobNo: e.target.value }))} />
              {/* <ComboBox sx={{ width: 120 }} options={msData.jobCombo} label={"งาน"}
                selectedId={filterData.JobId}
                setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
              /> */}
              <ClearButton onClick={e=>setFilterData({...initFilterData})}/>
            </BoxFR>
            : null
        }
      </BoxFR>
      <Box height={70}>
        <SummaryBar
          initFilter={initFilterData}
          dataTable={filterdTable.all}
          summaryFilter={filterData}
          setSummaryFilter={setFilterData}
          selectionModel={[]}
          displayModule={displayModule} />
      </Box>

      <Box sx={{ flexGrow: 1, overflowY: "scroll" }}>
        <JobBox header="งานรอปล่อย" data={filterdTable.hasNo} columns={hasNoColumns} filter={filterData} />
        <JobBox header="งานรอส่ง" data={filterdTable.hasTake} columns={locColumns} filter={filterData} />
        <JobBox header="งานรอกลับลาน" data={filterdTable.hasLoc} columns={otherColumns} filter={filterData} />
        <JobBox header="งานรอคืน" data={filterdTable.hasBack} columns={otherColumns} filter={filterData} />
      </Box>
      <AddDataDialog
        dialogOpen={dialogAddDataOpen}
        setDialogOpen={setDialogAddDataOpen}
        row={selectedRow}
        confirmSelect={confirmSelect}
        selectionDrvs={selectionDrvs} />
    </BoxFC>
  )
}

export default JobOrderNoDriver