import { Button, Dialog, DialogContent, Divider, TextField, Typography } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import DateTimePickerCustomTime from "components/DateTimePickerCustomTime"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useMemo, useState } from "react"
import { shippingInvoiceColNm } from "./constant"
import { initShippingInvoiceData } from "./initData"
import { shippingApiNm } from "./shippingApiNm"
import FileUpload from "components/FileUplaod/FileUpload"
import { saveFile } from "components/FileUplaod/saveFile"
import { alertConfirmDelete, alertWarning } from "components/Alert"
import { grey } from "@mui/material/colors"
import SystemUpdateAltRoundedIcon from '@mui/icons-material/SystemUpdateAltRounded';
import isFileOverSize from "utils/isFileOverSize"
import { DeleteRounded, NoteAddRounded } from "resource/muiImport"
import { handleFileDrop } from "utils/handleFileDrop"
import ComboBox from "components/ComboBox"
import { DesktopDatePicker } from "@mui/x-date-pickers-pro"
import PackingListDataDialog from "./PackingListDataDialog"
import ApproveDialog from "./ApproveDialog"
import ApproveCDDialog from "./ApproveCDDialog"
import MultipleContainerDataDialog from "./MultipleContainerDataDialog"

let originalDialogDataStr = null
let originalFileUploadDataPLStr = null
let originalFileUploadDataEDStr = null
let originalFileUploadDataCDStr = null
let originalFileUploadDataPCStr = null
let originalMultiContFile = null
let excelData = []
const ShippingInvoiceDialog = ({ selectedId, dialogOpen, setDialogOpen, onFinish, localAccountCombo, lastFilter }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState({ ...initShippingInvoiceData })
  const [multiContFile, setMultiContFile] = useState(null)
  const [dialogPLDataOpen, setDialogPLDataOpen] = useState(false)
  const [dialogApproveOpen, setDialogApproveOpen] = useState(false)
  const [dialogCDApproveOpen, setDialogCDApproveOpen] = useState(false)
  const [dialogMultiContOpen, setDialogMultiContOpen] = useState(false)

  const [fileUploadDataPL, setFileUploadDataPL] = useState({
    serverFiles: [],
    addedFiles: [],
    deletedFiles: [],
  })
  const [fileUploadDataED, setFileUploadDataED] = useState({
    serverFiles: [],
    addedFiles: [],
    deletedFiles: [],
  })
  const [fileUploadDataCD, setFileUploadDataCD] = useState({
    serverFiles: [],
    addedFiles: [],
    deletedFiles: [],
  })

  const [fileUploadDataPC, setFileUploadDataPC] = useState({
    serverFiles: [],
    addedFiles: [],
    deletedFiles: [],
  })

  const textFieldProps = useCallback((name) => ({
    size: "small",
    label: shippingInvoiceColNm[name],
    value: dialogData[name] || "",
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value }))
  }), [dialogData])

  const accountComboProp = useCallback((name) => ({
    name: name,
    options: localAccountCombo,
    label: shippingInvoiceColNm[name],
    selectedId: dialogData[name],
    setSelectedId: (id) => setDialogData(o => ({ ...o, [name]: id })),
  }), [localAccountCombo, dialogData])

  const datetimePickerProp = useCallback((name) => ({
    label: shippingInvoiceColNm[name],
    inputFormat: "DD/MM/YYYY HH:mm",
    mask: "__/__/____ __:__",
    value: dialogData[name],
    onChange: (newValue) => { setDialogData(o => ({ ...o, [name]: newValue })) },
    renderInput: (params) => <TextField size="small"  {...params} />,
  }), [dialogData])

  const datePickerProp = useMemo(() => ({
    label: "วันที่ Invoice",
    inputFormat: "DD/MM/YYYY",
    mask: "__/__/____",
    value: dialogData.IP_InvDte,
    onChange: (newValue) => { setDialogData(o => ({ ...o, IP_InvDte: newValue })) },
    renderInput: (params) => <TextField size="small"  {...params} />,
  }), [dialogData])

  const handleMultiContFileChange = useCallback((e) => {
    const files = Array.from(e.target.files)
    console.log({ files })
    if (!files || files.length === 0 || isFileOverSize(files[0])) return
    const fileNameOnly = files[0].name.substring(0, files[0].name.lastIndexOf('.')) || files[0].name
    files[0].label = fileNameOnly
    setMultiContFile(files[0])
  }, [])

  const handleMultiContFileClick = useCallback((link) => () => {
    window.open(link, "_blank")
  }, [])
  const handleRemoveMultiCont = useCallback(() => {
    setMultiContFile(null)
  }, [])

  const handleDropFile = useCallback((e) => {
    handleFileDrop(e, (files) => {
      if (!files || files.length === 0) return
      setMultiContFile(files[0])
    })
  }, [])

  const handleDragOver = useCallback((e) => {
    /** Must prevent then can use onDrop */
    e.preventDefault()
  }, [])

  const handleDialogOk = useCallback(async (closeAfterInsert = true, callback) => {
    let hasChange = false
    let success = true
    if (originalDialogDataStr !== JSON.stringify(dialogData)) {
      hasChange = true
      const dialogResult = await ax.post(shippingApiNm.updateShippingInvoice, { ...dialogData })
      if (!dialogResult.data) {
        success = false
      }
    }

    const localSaveFile = (fileUploadData, setFileUploadData, ShpFileTypId) => saveFile({
      ax,
      alertWarning,
      fileUploadData: fileUploadData,
      setFileUploadData: setFileUploadData,
      apiUpdateFileName: shippingApiNm.updateFileName,
      apiDeleteFile: shippingApiNm.deleteFile,
      apiAddFile: shippingApiNm.uploadFile,
      key: "ShpFileId",
      refKey: "ShpInvId",
      refValue: selectedId,
      extraColValues: { ShpFileTypId: ShpFileTypId }
    })

    if (originalFileUploadDataPLStr !== JSON.stringify(fileUploadDataPL)) {
      hasChange = true
      localSaveFile(fileUploadDataPL, setFileUploadDataPL, 1)
    }
    if (originalFileUploadDataEDStr !== JSON.stringify(fileUploadDataED)) {
      hasChange = true
      localSaveFile(fileUploadDataED, setFileUploadDataED, 2)
    }
    if (originalFileUploadDataCDStr !== JSON.stringify(fileUploadDataCD)) {
      hasChange = true
      localSaveFile(fileUploadDataCD, setFileUploadDataCD, 3)
    }
    if (originalFileUploadDataPCStr !== JSON.stringify(fileUploadDataPC)) {
      hasChange = true
      localSaveFile(fileUploadDataPC, setFileUploadDataPC, 5)
    }

    if (originalMultiContFile !== multiContFile) {
      hasChange = true
      if (multiContFile === null) {
        await ax.post(shippingApiNm.deleteFile, { ShpFileId: originalMultiContFile.id })
      } else {

        const data = new FormData();
        data.append("file", multiContFile);
        data.append("ShpInvId", selectedId);
        data.append("Label", multiContFile.label);
        data.append("ShpFileTypId", 6)
        await ax.post(shippingApiNm.uploadFile, data)
      }

    }

    if (hasChange) {
      onFinish()
    }
    if (success) {
      if(callback){
        callback()
      }
      if(closeAfterInsert){
        setDialogOpen(false)
      }
    }
  }, [ax, onFinish, setDialogOpen, selectedId, dialogData, multiContFile
    , fileUploadDataPL, fileUploadDataED, fileUploadDataCD, fileUploadDataPC])

  const handleGenerageDataFromPL = useCallback(async () => {
    setDialogPLDataOpen(true)
  }, [])



  const handleDeleteShippingInvoice = useCallback((id) => {
    alertConfirmDelete(async () => {
      ax.post(shippingApiNm.deleteShippingInvoice, { ShpInvId: selectedId, getArgs: lastFilter }).then(value => {
        if (value.data) {
          onFinish(value.data)
          setDialogOpen(false)
        }
      })
    })
  }, [selectedId, ax, lastFilter, onFinish, setDialogOpen])

  const handleOpenMultiContainerDialog = useCallback(() => {
    handleDialogOk(false, ()=>{
      setDialogMultiContOpen(true)
    })
  }, [handleDialogOk])
  useEffect(() => {
    if (dialogOpen) {
      if (selectedId > 0) {
        ax.post(shippingApiNm.getShippingInvoiceDetail, { ShpInvId: selectedId }).then(value => {
          if (value.data && value.data.length > 0) {
            const data = value.data[0]
            originalDialogDataStr = JSON.stringify(data)
            setDialogData(data)
            const getFileObj = file => ({
              id: file.ShpFileId,
              fileNm: file.FileNm,
              label: file.Label,
              oLabel: file.Label,
              ShpInvId: selectedId,
              isEdit: false,
              file: null,
              imgLink: `${window.location.origin}/file/shipping/${file.ShpInvId}/${file.FileNm}`,
            })
            const serverFilesPL = data.Files.filter(file => file.ShpFileTypId === 1).map(getFileObj)
            const serverFilesED = data.Files.filter(file => file.ShpFileTypId === 2).map(getFileObj)
            const serverFilesCD = data.Files.filter(file => file.ShpFileTypId === 3).map(getFileObj)
            const serverFilesPC = data.Files.filter(file => file.ShpFileTypId === 5).map(getFileObj)
            const multiContFile = data.Files.find(file => file.ShpFileTypId === 6)
            originalFileUploadDataEDStr = JSON.stringify(serverFilesED)
            originalFileUploadDataCDStr = JSON.stringify(serverFilesCD)
            setFileUploadDataPL(o => {
              const newData = { ...o, serverFiles: serverFilesPL }
              originalFileUploadDataPLStr = JSON.stringify(newData)
              return newData
            })
            setFileUploadDataED(o => {
              const newData = { ...o, serverFiles: serverFilesED }
              originalFileUploadDataEDStr = JSON.stringify(newData)
              return newData
            })
            setFileUploadDataCD(o => {
              const newData = { ...o, serverFiles: serverFilesCD }
              originalFileUploadDataCDStr = JSON.stringify(newData)
              return newData
            })
            setFileUploadDataPC(o => {
              const newData = { ...o, serverFiles: serverFilesPC }
              originalFileUploadDataPCStr = JSON.stringify(newData)
              return newData
            })
            originalMultiContFile = multiContFile ? getFileObj(multiContFile) : null
            setMultiContFile(originalMultiContFile)
          }
        })
      }
    } else {
      setDialogData({ ...initShippingInvoiceData })
      setFileUploadDataPL({
        serverFiles: [],
        addedFiles: [],
        deletedFiles: [],
      })
      setFileUploadDataED({
        serverFiles: [],
        addedFiles: [],
        deletedFiles: [],
      })
      setFileUploadDataCD({
        serverFiles: [],
        addedFiles: [],
        deletedFiles: [],
      })
      setFileUploadDataPC({
        serverFiles: [],
        addedFiles: [],
        deletedFiles: [],
      })

    }
  }, [selectedId, dialogOpen, ax])


  console.log("dialogData:: ", dialogData)
  console.log("multiContFile:: ", multiContFile)
  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='xl' >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title={"Invoice"}
        onDeleteClick={handleDeleteShippingInvoice}
      />
      <DialogContent>
        <BoxFC mt={2} height={710} >
          <BoxFR sx={{ alignItems: "flex-start" }}>
            <BoxFC>
              <TextField {...textFieldProps("IP_InvNo")} />
              <DesktopDatePicker {...datePickerProp} />
              <TextField {...textFieldProps("OwnAccNm")} disabled />
              <TextField {...textFieldProps("OwnAccEmail")} />
              <TextField {...textFieldProps("IP_Csgn")} />
              <TextField {...textFieldProps("IP_PO")} />
              <DateTimePickerCustomTime {...datetimePickerProp("IP_ETD")} />
              <DateTimePickerCustomTime {...datetimePickerProp("PLCreTm")} />
              <TextField {...textFieldProps("EDNo")} />
              {/* <TextField {...textFieldProps("EDCreAccNNm")} disabled /> */}
              <ComboBox {...accountComboProp("EDCreAccId")} />
              <DateTimePickerCustomTime {...datetimePickerProp("EDCreTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("EDAprvTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("EDSndTm")} />
            </BoxFC>
            <BoxFC>
              <DateTimePickerCustomTime {...datetimePickerProp("EDCfTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("ED0209CreTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("ED0209SndTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("CDCreTm")} />
              {/* <TextField {...textFieldProps("CDCreAccNNm")} disabled /> */}
              <ComboBox {...accountComboProp("CDCreAccNNm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("CDAprvTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("CDSndTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("ED0309CreTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("ED0309SndTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("ED0409CreTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("ED0409SndTm")} />
              <DateTimePickerCustomTime {...datetimePickerProp("PayCstmTm")} />
            </BoxFC>
            <BoxFC height="100%" width={300}>
              <TextField {...textFieldProps("Note")} multiline rows={16} />
              <BoxFC sx={{ height: 0.5 }} />
              <TextField {...textFieldProps("Rmk")} multiline rows={10} />
            </BoxFC>
            <BoxFC sx={{ height: "100%", gap: 0, flex: 1 }}>
              <BoxFC sx={{ height: "50%", gap: 0 }}>
                <BoxFR mb={1}>
                  <Typography variant="h6">Packing List</Typography>
                  <Button variant="contained" size="small" onClick={handleGenerageDataFromPL} >
                    ดูข้อมูล Inv-PL
                  </Button>
                </BoxFR>
                <FileUpload
                  width={325}
                  options={["ใบขน"]}
                  fileUploadData={fileUploadDataPL}
                  setFileUploadData={setFileUploadDataPL}
                />
                <BoxFR mt={1}>
                  <Typography variant="body" fontWeight="bold">Multi Container</Typography>
                  <Button variant="contained" size="small" onClick={handleOpenMultiContainerDialog}
                  disabled={multiContFile?false:true} >
                    ดูข้อมูล Multi Container
                  </Button>
                </BoxFR>
                <BoxFR sx={{ justifyContent: "space-between" }} bgcolor={grey[100]} mt={1} p={1} px={2}
                  onDrop={handleDropFile} onDragOver={handleDragOver}>
                  {multiContFile ?
                    <Button variant="outlined" size="small" onClick={handleMultiContFileClick(multiContFile.imgLink)}>
                      <Typography noWrap component="span" variant="caption" >{multiContFile.label}</Typography>
                    </Button>
                    :
                    <BoxFR>
                      <SystemUpdateAltRoundedIcon sx={{ color: grey[500] }} /><Typography variant="caption" color={grey[500]}>Drop file here</Typography>
                    </BoxFR>
                  }
                  {multiContFile ?
                    <Button color="error" variant="contained" size="small" sx={{ minWidth: 0, p: 0.5 }} onClick={handleRemoveMultiCont}>
                      <DeleteRounded />
                    </Button>
                    :
                    <Button variant="contained" size="small" component="label" sx={{ minWidth: 0, p: 0.5 }}>
                      <NoteAddRounded />
                      <input type="file" hidden onChange={handleMultiContFileChange} />
                    </Button>
                  }
                </BoxFR>
              </BoxFC>
              <Divider sx={{ mt: 2, mb: 1 }} />
              <BoxFC sx={{ height: "50%", gap: 0 }}>
                <BoxFR mb={1}>
                  <Typography variant="h6">ใบขน</Typography>
                  <Button variant="contained" size="small" color="secondary" onClick={() => setDialogApproveOpen(true)} >
                    ตรวจสอบใบขน
                  </Button>
                </BoxFR>
                <FileUpload
                  width={325}
                  options={["ใบขน"]}
                  fileUploadData={fileUploadDataED}
                  setFileUploadData={setFileUploadDataED}
                />
              </BoxFC>
            </BoxFC>

            <BoxFC alignSelf="stretch" sx={{ gap: 0, flex: 1 }}>
              <BoxFC sx={{ height: "50%", gap: 0 }}>
                <BoxFR mb={1}>
                  <Typography variant="h6">ใบกำกับตู้</Typography>
                  <Button variant="contained" size="small" color="secondary" onClick={() => setDialogCDApproveOpen(true)} >
                    ตรวจสอบใบกำกับตู้
                  </Button>
                </BoxFR>
                <FileUpload
                  width={325}
                  options={["ใบกำกับตู้"]}
                  fileUploadData={fileUploadDataCD}
                  setFileUploadData={setFileUploadDataCD}
                />
              </BoxFC>
              <Divider sx={{ mt: 2, mb: 1 }} />
              <BoxFC sx={{ height: "50%", gap: 0 }}>
                <Typography variant="h6">ใบเสร็จศุลกากร</Typography>
                <FileUpload
                  width={325}
                  options={["ใบขน"]}
                  fileUploadData={fileUploadDataPC}
                  setFileUploadData={setFileUploadDataPC}
                />
              </BoxFC>

            </BoxFC>
          </BoxFR>
        </BoxFC>
        <PackingListDataDialog
          shpInvId={dialogData.ShpInvId}
          files={dialogData.Files}
          dialogModDte={dialogData.ModDte}
          cusId={dialogData.CusId}
          excelData={excelData}
          dialogOpen={dialogPLDataOpen}
          setDialogOpen={setDialogPLDataOpen} />
        <ApproveDialog
          selectedId={dialogData.ShpInvId}
          dialogOpen={dialogApproveOpen}
          setDialogOpen={setDialogApproveOpen}
          onFinish={onFinish}
          lastFilter={lastFilter} />
        <ApproveCDDialog
          selectedId={dialogData.ShpInvId}
          dialogOpen={dialogCDApproveOpen}
          setDialogOpen={setDialogCDApproveOpen}
          onFinish={onFinish}
          lastFilter={lastFilter} />
        <MultipleContainerDataDialog
          dialogOpen={dialogMultiContOpen}
          setDialogOpen={setDialogMultiContOpen}
          shpInvId={dialogData.ShpInvId}
          files={dialogData.Files}
        />
      </DialogContent>
      <DialogFooter okText={"บันทึก"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={()=>handleDialogOk(true, null)} />
    </Dialog>
  )
}

export default ShippingInvoiceDialog