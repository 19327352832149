
import { IconButton, Paper, Typography } from "@mui/material";
import { blue, blueGrey, green, purple, red, yellow } from "@mui/material/colors";
import { Box } from "@mui/system";
import { BoxFC, BoxFR } from "components/BoxCustom";
import DataGridCellExpand from "components/DataGridCellExpand/DataGridCellExpand";
import dayjs from "dayjs";
import React, { useCallback, useMemo, useState } from "react";
import { ExpandCircleDownRounded, RemoveCircleRounded } from "resource/muiImport";


const getRowClassName = (params) => {
  if (params.row.TakeDteEn && dayjs(params.row.TakeDteEn).isBefore(dayjs())) {
    return "red"
  } else if (params.row.TakeDteEn && dayjs(params.row.TakeDteEn).add(-3, "day").isBefore(dayjs())) {
    return "yellow"
  } else if (params.row.TakeDteSt && dayjs(params.row.TakeDteSt).isAfter(dayjs())) {
    return "grey"
  } else if (!params.row.AptTm) {
    return "no-apttm"
  } else if (dayjs(params.row.AptTm).startOf("day").isSame(dayjs().startOf("day"))){
    return "today"
  } else if (dayjs(params.row.AptTm).startOf("day").isSame(dayjs().add(1,"day").startOf("day"))){
    return "tomorrow"
  } else {
    return null
  }
}

const sxColor = {
  "& .grey": { bgcolor: blueGrey[100], '&:hover': { bgcolor: `${blueGrey[200]}!important` } },
  "& .yellow": { bgcolor: yellow[100], '&:hover': { bgcolor: `${yellow[200]}!important` } },
  "& .red": { bgcolor: red[100], '&:hover': { bgcolor: `${red[200]}!important` } },
  "& .today": { bgcolor: blue[100], '&:hover': { bgcolor: `${blue[200]}!important` } },
  "& .tomorrow": { bgcolor: green[100], '&:hover': { bgcolor: `${green[200]}!important` } },
  "& .no-apttm": { bgcolor: purple[100], '&:hover': { bgcolor: `${purple[200]}!important` } }
}
const JobBox = ({ header, data, columns }) => {
  const [expanded, setExpanded] = useState(false)
  const [jobIdsExpanded, setJobsIdExpanded] = useState([])

  const numCont = useMemo(() => {
    return data.reduce((prev, cur) => prev + cur.JobOrdIds.length, 0)
  }, [data])
  console.log("JobBox render data::", data)  

  const memoData = useMemo(() => {
    const result = []
    for (const row of data) {
      if(jobIdsExpanded.includes(row.CusId)){
        result.push({...row, IsExpanded: true})
      } else {

        const found = result.find(item => item.CusId === row.CusId)
        if(found){
          found.JobOrdIds.push(...row.JobOrdIds)
        } else {
          result.push({
            id: row.JobOrdId,
            CusId: row.CusId,
            AptTm: row.AptTm, 
            CusSNm: row.CusSNm,
            IsExpanded: false,
            JobOrdIds: [...row.JobOrdIds]
          })
        }
      }
    }
    return result
  }, [data, jobIdsExpanded])

  const handleCellClick = useCallback((params)=>{
    if(params.field === "CusSNm"){
      if(params.row.IsExpanded){
        setJobsIdExpanded(o => o.filter(id => id !== params.row.CusId))
      } else {
        setJobsIdExpanded(o => [...o, params.row.CusId])
      }
    }
  }, [])

  return (
    <Paper elevation={3} sx={{ 
        mt: 1, p: 1, width: "100%", minWidth: 750,  height:expanded ? 800 : 300, transition: 'height 0.5s ease-in-out'  
      }}>
      <BoxFC sx={{ gap: 0, height: "100%"}}>
        <BoxFR>
          <Typography variant="h6">{header} ({numCont}เที่ยว)</Typography>
          <IconButton onClick={() => { setExpanded(o => !o) }}>
            {expanded ?
              <RemoveCircleRounded color="primary" /> :
              <ExpandCircleDownRounded color="primary" />
            }
          </IconButton>
        </BoxFR>
        <Box flex={1} sx={sxColor}>
          <DataGridCellExpand
            hideFooter
            rows={memoData}
            columns={columns}
            getRowClassName={getRowClassName}
            onCellClick={handleCellClick}
            />
        </Box>
      </BoxFC>
    </Paper>
  )
}

export default JobBox