
import { Box, Button, MenuItem, TextField } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { branchApiNm } from 'branch/constant/branchApiNm';
import { driverTripPaymentColumns } from './columns';
import { green, grey, red } from '@mui/material/colors';
import ComboBox from 'components/ComboBox';
import { alertError } from 'components/Alert';
import SelectMonthDialog from './SelectMonthDialog';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { numberFormat } from 'utils';
import DailyJobDialogV3 from 'pages/Menu1/DailyJob/DailyJobDialogV3';
import dayjs from 'dayjs';
import { CloudDownloadRounded } from '@mui/icons-material';
import AddDriverTripDialog from './AddDriverTripDialog';
import OtherDriverTripDialog from './OtherDriverTripDialog';
import { expensePOIApiNm } from 'pages/Menu4/ExpensePOI/constant';
import CheckboxFormControl from 'components/CheckboxFormControl';

// let selectedId = null;
let lastFilter = null
let jobOrdId = null
let selectedPCTrnsIds = []
let selectedSalDte = null
let selectedPCItmId = null
let selectedRow = {}
let suggestDrvIds = []

export const driverTripWatchCols = [92, 88, 93]
// const watchFields = driverTripWatchCols.map(id => `E${id}`)

/**
 * DOC:: One job order one saldte for all pc_transaction if want to add another saldte use child job order
 */
const DriverTripPayment = () => {
  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [dialogOpen, setDialogOpen] = useState(false)
  const [dialogAddDriverTripOpen, setDialogAddDriverTripOpen] = useState(false)
  const [dialogSelectMonth, setDialogSelectMonthOpen] = useState(false)
  const [dialogOtherDriverTripOpen, setDialogOtherDriverTripOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })
  const [selectionModel, setSelectionModel] = useState([])

  const textFieldProps = useCallback((name) => ({
    size: "small",
    sx: { width: 120 },
    value: filterData[name] ?? "",
    onChange: (e) => { setFilterData(o => ({ ...o, [name]: e.target.value ?? null })) }
  }), [filterData])

  const monthPickerProp = useMemo(() => ({
    disableMaskedInput: true,
    openTo: "month",
    views: ["year", "month"],
    label: "คิดเงินเดือน",
    inputFormat: "MMMM YYYY",
    value: filterData.SalDte,
    onChange: (newValue) => { setFilterData(o => ({ ...o, SalDte: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} sx={{ width: 160 }} />,

  }), [filterData.SalDte])

  const setAllData = useCallback((datas) => {
    const newData = []
    let no = 0;
    for (const data of datas) {
      no++;
      const result = {
        id: data.id,
        No: no,
        ...data
      }
      const othExps = []
      if (data.Exps) {
        for (const exp of data.Exps) {
          // expTotal += +exp.Amnt
          if (exp.ExpId === 92 && exp.UsrAccId === data.Sts1DrvId) {
            result[`E${exp.ExpId}`] = +exp.Amnt
          } else if (exp.ExpId === 88 && exp.UsrAccId === data.Sts3DrvId) {
            result[`E${exp.ExpId}`] = +exp.Amnt
          } else if (exp.ExpId === 93 && exp.UsrAccId === data.Sts5DrvId) {
            result[`E${exp.ExpId}`] = +exp.Amnt
          } else {
            othExps.push(exp)
          }
        }
      }
      if (othExps.length > 0) {
        result.OthExp = othExps.map(exp => `[${exp.UsrAccNm}:${exp.ExpNm}:${numberFormat(+exp.Amnt)}]`).join(', ')
      }
      // result.ExpTotal = expTotal
      newData.push(result)
    }
    console.log("newData::", newData)
    setDataTable(newData)
  }, [])

  const getData = useCallback((filter) => {
    ax.post(branchApiNm.getDriverTripPayment, filter).then(value => {
      if (value.data) {
        // setDataTable(addIdForDataGrid(value.data, 'PCTrnsId'))
        lastFilter = filter
        setAllData(value.data)
      }
    })
  }, [ax, setAllData])



  const handleCellClick = useCallback((params) => {
    // selectedId = params.id
    if (params.field === 'ShowDetail') {
      jobOrdId = params.row.JobOrdId
      setDialogOpen(true)
    } else if (params.field === "AddData") {
      selectedPCItmId = null
      selectedRow = params.row
      suggestDrvIds = [params.row.Sts1DrvId, params.row.Sts3DrvId, params.row.Sts5DrvId].filter(id => id)
      console.log("suggestDrvIds::", suggestDrvIds)
      setDialogAddDriverTripOpen(true)
    } else if (params.field === "JobSNm") {
      if (selectionModel.length > 0) {
        setSelectionModel([])
      } else {
        setSelectionModel([...dataTable.filter(r => r.JobId === params.row.JobId).map(r => r.id)])
      }
    } else if (params.field === "OthExp") {
      if (params.value) {
        selectedRow = params.row
        setDialogOtherDriverTripOpen(true)
      }
    } else if (params.field === 'SalDte') {
      selectedSalDte = params.value
      if (selectionModel.includes(params.id)) {
        console.log("dataTable::", dataTable)
        const filtered = dataTable.filter(r => selectionModel.includes(r.id))
        if (filtered.some(r => r.Exps.length === 0)) {
          alertError("มีบางข้อมูลยังไม่ลงค่าเที่ยว ไม่สามารถลงคิดเงินเดือนได้")
          return
        }
        selectedPCTrnsIds = []
        for (const row of filtered) {
          selectedPCTrnsIds.push(...row.Exps.map(exp => exp.PCTrnsId))
        }
      }



      setDialogSelectMonthOpen(true)


    }
  }, [selectionModel, dataTable])

  const onFinish = useCallback((data) => {
    getData(lastFilter)
  }, [getData])


  const onSelectMonthDialogFinish = useCallback((data) => {
    const postData = {
      PCTrnsIds: selectedPCTrnsIds,
      SalDte: data,
      getArgs: lastFilter
    }
    ax.post(branchApiNm.insertDeleteSalaryPCTrnsaction, postData).then(value => {
      if (value.data) {
        setAllData(value.data)
      }
    })
  }, [ax, setAllData])

  const handleCellEditCommit = useCallback((params) => {
    console.log("params::", params)
    const foundRow = dataTable.find(r => r.id === params.id)

    let noDrv = false
    if (params.field === "E92") {
      noDrv = !foundRow.Sts1DrvId
    } else if (params.field === "88") {
      noDrv = !foundRow.Sts3DrvId
    } else if (params.field === "E93") {
      noDrv = !foundRow.Sts5DrvId
    }

    if (noDrv) {
      alertError("ไม่พบพนักงานขับรถไม่สามารถเพิ่มค่าเที่ยวได้")
      return
    }
    const expId = +params.field.substring(1)
    const findDrvId = (foundRow, eId) => {
      let drvId = null
      if (eId === 92) {
        drvId = foundRow.Sts1DrvId
      } else if (eId === 88) {
        drvId = foundRow.Sts3DrvId
      } else if (eId === 93) {
        drvId = foundRow.Sts5DrvId
      }
      return drvId
    }

    if (params.value === null) {
      let pcTrnsIds = []
      if (selectionModel.includes(params.id)) {
        const filtered = dataTable.filter(r => selectionModel.includes(r.id))
        for (const row of filtered) {
          const foundExp = row.Exps.find(exp => exp.ExpId === expId)
          if (foundExp) {
            pcTrnsIds.push(foundExp.PCTrnsId)
          }
        }
      }
      else {
        const row = dataTable.find(r => r.id === params.id)
        const foundExp = row.Exps.find(exp => exp.ExpId === expId)
        if (foundExp) {
          pcTrnsIds.push(foundExp.PCTrnsId)
        }
      }
      ax.post(branchApiNm.deleteDriverPayment, { PCTrnsIds: pcTrnsIds, getArgs: lastFilter }).then(value => {
        if (value.data) {
          setAllData(value.data)
        }
      })
    } else {
      if (selectionModel.includes(params.id)) {
        const updateArgsArr = []
        const insertArgsArr = []

        for (const id of selectionModel) {

          const row = dataTable.find(r => r.id === id)
          const foundExp = row.Exps.find(exp => exp.ExpId === expId)
          if (foundExp) {
            updateArgsArr.push({
              PCTrnsId: foundExp.PCTrnsId,
              PCItmId: foundExp.PCItmId,
              ExpId: expId,
              DrvId: findDrvId(row, expId),
              Dscp: "",
              PayTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
              Amnt: params.value,
            })
          } else {
            const drvId = findDrvId(row, expId)
            if (drvId) {
              insertArgsArr.push({
                ExpId: expId,
                DrvId: drvId,
                JobOrdId: row.JobOrdId,
                Amnt: params.value,
                SalDte: row.SalDte ? dayjs(row.SalDte).format("YYYY-MM-DD") : null
              })
            }
          }
        }
        const postData = {
          updateArgsArr,
          insertArgsArr,
          getArgs: lastFilter
        }
        ax.post(branchApiNm.insertUpdateDriverPayments, postData).then(value => {
          if (value.data) {
            setAllData(value.data)
          }
        })
      } else {
        const row = dataTable.find(r => r.id === params.id)
        const foundExp = row.Exps.find(exp => exp.ExpId === expId)
        if (foundExp) {
          const postData = {
            PCTrnsId: foundExp.PCTrnsId,
            PCItmId: foundExp.PCItmId,
            ExpId: expId,
            DrvId: findDrvId(row, expId),
            Dscp: "",
            PayTm: dayjs().format("YYYY-MM-DD HH:mm:ss"),
            Amnt: params.value,
            getArgs: lastFilter
          }
          ax.post(branchApiNm.updateDriverPaymentAmnt, postData, false).then(value => {
            if (value.data) {
              setAllData(value.data)
            }
          })
        } else {
          const postdata = {
            ExpId: expId,
            DrvId: findDrvId(row, expId),
            JobOrdId: row.JobOrdId,
            Amnt: params.value,
            SalDte: row.SalDte ? dayjs(row.SalDte).format("YYYY-MM-DD") : null,
            getArgs: lastFilter
          }
          ax.post(branchApiNm.insertDriverPayment, postdata, false).then(value => {
            if (value.data) {
              setAllData(value.data)
            }
          })
        }
      }
    }

  }, [dataTable, ax, selectionModel, setAllData])

  const handleInsertTripAmnt = useCallback(() => {
    const selectedRows = dataTable.filter(row => selectionModel.includes(row.id))
    const jobIds = [...new Set(selectedRows.map(row => row.JobId))]
    ax.post(expensePOIApiNm.getExpensePOI, { JobIds: jobIds, ExpTypId: 6 }).then(value => {
      if (value.data) {
        const expJobObj = value.data.reduce((acc, row) => {
          if (!acc[row.JobId]) {
            acc[row.JobId] = [{ ...row }]
          } else {
            acc[row.JobId].push({ ...row })
          }
          return acc
        }, {})
        const argsArr = []
        const insertArr = (row, expId) => {
          console.log("insertArr expId::", expId)
          const amnt = row.Exps.find(exp => exp.ExpId === expId)?.Amnt
          console.log("amnt::", amnt)
          let drvId = null
          if (expId === 92) {
            drvId = row.Sts1DrvId
          } else if (expId === 88) {
            drvId = row.Sts3DrvId
          } else if (expId === 93) {
            drvId = row.Sts5DrvId
          }

          if (drvId && !amnt) {
            const found = expJobObj[row.JobId].find(exp => exp.ExpId === expId)
            console.log("found::", found)
            if (found) {
              argsArr.push({
                ExpId: expId,
                DrvId: drvId,
                JobOrdId: row.JobOrdId,
                Amnt: +found.UntPr,
                SalDte: row.SalDte ? dayjs(row.SalDte).format("YYYY-MM-DD") : null
              })
            }
          }
        }
        for (const row of selectedRows) {
          if (row.Sts1DrvId) {
            insertArr(row, 92)
          }
          if (row.Sts3DrvId) {
            insertArr(row, 88)
          }
          if (row.Sts5DrvId) {
            insertArr(row, 93)
          }
        }
        const postData = {
          argsArr: argsArr,
          getArgs: lastFilter
        }
        ax.post(branchApiNm.insertDriverPaymentBulk, postData).then(value => {
          if (value.data) {
            setAllData(value.data)
          }
        })
        console.log("argsArr::", argsArr)
      }
    })
  }, [dataTable, ax, selectionModel, setAllData])

  const onAddDriverTripDialogFinish = useCallback((data) => {
    if (data) {
      setAllData(data)
    } else {
      getData(lastFilter)
    }
  }, [setAllData, getData])

  const driverTripPaymentColumnsMemo = useMemo(() => {
    for (const col of driverTripPaymentColumns) {
      if (["E92", "E88", "E93"].includes(col.field)) {
        col.headerName = msData.expObj[+col.field.substring(1)]?.ExpNm
      }
    }
    return driverTripPaymentColumns
  }, [msData.expObj])

  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height='100%'>
      <BoxFR>
        <Button variant="contained" onClick={handleInsertTripAmnt} disabled={selectionModel.length === 0}>
          <CloudDownloadRounded sx={{ mr: 1 }} /> ดึงค่าเที่ยวจากที่บันทึกไว้
        </Button>
        <Box flex={1} />
        <TextField {...textFieldProps('PaySts')} sx={{ width: 150 }} label="สถานะลงค่าเที่ยว" select >
          <MenuItem value={null}>ไม่ระบุ</MenuItem>
          <MenuItem value={1}>ลงค่าเที่ยวแล้ว</MenuItem>
          <MenuItem value={0}>ยังไม่ลงค่าเที่ยว</MenuItem>
        </TextField>
        <TextField {...textFieldProps('JobOrdId')} label="เลขที่ใบงาน" />
        <TextField {...textFieldProps('ContNo')} label="หมายเลขตู้" />
        <ComboBox sx={{ width: 150 }} options={msData.jobCombo} label="งาน"
          selectedId={filterData.JobId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, JobId: id }))}
        />
        <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label={"พนักงานขับรถ"}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
        />
        <DateTimeSelect sx={{ width: 180 }} label='วันนัด' start={filterData.AptTmSt} end={filterData.AptTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, AptTmSt: st, AptTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <DateTimeSelect sx={{ width: 180 }} label='วันที่ลงจบ' start={filterData.FinDteSt} end={filterData.FinDteEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, FinDteSt: st, FinDteEn: en })) }}
          getData={() => { getData(filterData) }}
        />

        <Box sx={{ position: "relative" }}>
          <DesktopDatePicker {...monthPickerProp} />
          <CheckboxFormControl sx={{ position: "absolute", bottom: -24, left: 0 }} label="ระบุุคิดเงินเดือน"
            checked={filterData.IsFilterSalDte === 1}
            onChange={(e) => setFilterData(o => ({ ...o, IsFilterSalDte: e.target.checked ? 1 : 0 }))} />
        </Box>

        <SearchButton onClick={(() => getData(filterData))} />
        <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
      </BoxFR>
      <Box height='100%' sx={{
        mt: 1,
        "& .bg-red": { bgcolor: red[100] },
        "& .bg-green": { bgcolor: green[100] },
        "& .disabled": { bgcolor: grey[200] }
      }}>
        <DataGridCellExpand
          hideFooter
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(ids) => {
            setSelectionModel(ids);
          }}
          rows={dataTable}
          columns={driverTripPaymentColumnsMemo}
          onCellEditCommit={handleCellEditCommit}
          onCellClick={handleCellClick}
        // isCellEditable={isCellEditable}
        // experimentalFeatures={{ newEditingApi: true }}
        />
      </Box>
      <DailyJobDialogV3
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        selectedId={jobOrdId}
        onFinish={onFinish} />
      <SelectMonthDialog
        oldData={selectedSalDte}
        dialogOpen={dialogSelectMonth}
        setDialogOpen={setDialogSelectMonthOpen}
        onFinish={onSelectMonthDialogFinish} />
      <AddDriverTripDialog
        dialogOpen={dialogAddDriverTripOpen}
        setDialogOpen={setDialogAddDriverTripOpen}
        pcItmId={selectedPCItmId}
        dataRow={selectedRow}
        suggestDrvIds={suggestDrvIds}
        onFinish={onAddDriverTripDialogFinish}
        lastFilter={lastFilter}
      />
      <OtherDriverTripDialog
        jobOrdId={selectedRow?.JobOrdId}
        dialogOpen={dialogOtherDriverTripOpen}
        setDialogOpen={setDialogOtherDriverTripOpen}
        lastFilter={lastFilter}
        suggestDrvIds={suggestDrvIds}
        onFinish={onAddDriverTripDialogFinish}
        joData={selectedRow}
      />
    </BoxFC>
  );
}

export default DriverTripPayment