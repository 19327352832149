
import React, { useCallback, useContext, useMemo } from "react";
import { Avatar, Box, Divider, IconButton, Paper, Popover, SvgIcon, Toolbar, Typography } from "@mui/material";
import { BoxFC, BoxFR } from "components/BoxCustom";
import { UserContext } from "contexts/UserContext";
import { ReactComponent as EmilyLogo } from "resource/emily_no_fill.svg";
import ProfileButton from "components/MainLayout/ProfileButton";
import { NavLink } from "react-router-dom";
import { extraMenuData } from "branch/pages/Client/extraMenuData";
import {  ArrowRightRounded } from "resource/muiImport";
import MenuIcon from '@mui/icons-material/Menu';
import SubMenuPopover from "./SubmenuPopover";


const MobileToolbar = ({ menuData }) => {
  const { user} = useContext(UserContext);
  const [anchorEl, setAnchorEl] = React.useState(null);
  const [anchorElSubMenu, setAnchorElSubMenu] = React.useState(null);

  const pms = useMemo(() => {
    return (user?.Pms?.map(pm => pm.Code)) || []
  }, [user])

  const handleMenuClose = useCallback(() => {
    setAnchorEl(null);
  }, [])

  const handleSubMenuClose = useCallback(() => {
    setAnchorElSubMenu(null)
    setAnchorEl(null);
  }, [])


  return (
    <Toolbar variant="dense">
      <BoxFR width="100%">
        <IconButton sx={{ px: 0 }} onClick={(e) => { setAnchorEl(e.currentTarget) }}>
          <MenuIcon fontSize="large" sx={{ color: "white" }} />
        </IconButton>
        <Popover anchorEl={anchorEl}
          open={Boolean(anchorEl)}
          onClose={handleMenuClose}
          transformOrigin={{ vertical: "top", horizontal: "left" }}
          anchorOrigin={{ vertical: "bottom", horizontal: "left" }}>
          <Paper sx={{ p: 2, bgcolor: "primary.main" }}>
            <BoxFC>
              {[...menuData, ...extraMenuData]
                .filter(item => !item.pms || item.pms?.some(pm => pms.includes(pm)))
                .map((item, index) => (
                  <BoxFC sx={{ gap: 0 }} key={index}>
                    <BoxFR
                      key={index}
                      component={item.subMenu ? "div" : NavLink}
                      to={item.path}
                      onClick={item.subMenu ? (e)=>{setAnchorElSubMenu(e.currentTarget)} : handleMenuClose}
                    >
                      <Typography color="white">{item.label}</Typography>
                      {item.subMenu ? <ArrowRightRounded sx={{ color: "white" }} /> : null}
                    </BoxFR>
                    {item.subMenu? 
                      <SubMenuPopover 
                      anchorEl={anchorElSubMenu} 
                      subMenu={item.subMenu} 
                      handleMenuClose={handleSubMenuClose} 
                      pms={pms} />: null }
                    <Divider sx={{ pt: 1, borderColor: "white" }} width="100%" />
                  </BoxFC>
                ))}
                
            </BoxFC>
          </Paper>
        </Popover>


        <BoxFR>
          <Avatar alt="emily" sx={{ bgcolor: "white", width: 50, height: 42 }}>
            <SvgIcon sx={{ fontSize: 40, color: "primary.dark" }}>
              <EmilyLogo />
            </SvgIcon>
          </Avatar>
          <Typography variant="h6" textAlign="center" sx={{ color: "white" }}>Emily</Typography>
        </BoxFR>
        <Box flex={1} />
        <ProfileButton />
      </BoxFR>
    </Toolbar>
  );
};

export default MobileToolbar;