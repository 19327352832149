

export const shippingApiNm = {
  getShippingJob: "/branch/shipping/getShippingJob",
  getShippingInvoice: "/branch/shipping/getShippingInvoice",
  getShippingInvoiceDetail: "/branch/shipping/getShippingInvoiceDetail",
  getPackingListDetail: "/branch/shipping/getPackingListDetail",
  getShippingAccount: "/branch/shipping/getShippingAccount",
  getApproveCDData: "/branch/shipping/getApproveCDData",
  getShippingInvPLItem: "/branch/shipping/getShippingInvPLItem",
  getShippingInvMultipleItem: "/branch/shipping/getShippingInvMultipleItem",
  getSendEmailData: "/branch/shipping/getSendEmailData",
  updateShippingInvoiceTime: "/branch/shipping/updateShippingInvoiceTime",
  updateShippingInvoiceColumns: "/branch/shipping/updateShippingInvoiceColumns",
  updateShippingJobColumns: "/branch/shipping/updateShippingJobColumns",
  insertShippingJob: "/branch/shipping/insertShippingJob",
  deleteShippingJob: "/branch/shipping/deleteShippingJob",
  insertPackingList: "/branch/shipping/insertPackingList",
  updatePackingList: "/branch/shipping/updatePackingList",
  deletePackingList: "/branch/shipping/deletePackingList",
  updateShippingInvoice: "/branch/shipping/updateShippingInvoice",
  uploadFile: "/branch/shipping/uploadFile",
  updateFileName: "/branch/shipping/updateFileName",
  deleteFile: "/branch/shipping/deleteFile",
  deleteFileByType: "/branch/shipping/deleteFileByType",
  sendEmail: "/branch/shipping/sendEmail",
  deleteShippingInvoice: "/branch/shipping/deleteShippingInvoice",
  insertShippingInvoice: "/branch/shipping/insertShippingInvoice",
  insertShippingInvWithPLItem: "/branch/shipping/insertShippingInvWithPLItem",
  insertShippingMultipleBulk: "/branch/shipping/insertShippingMultipleBulk",
}