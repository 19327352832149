import { Box, Button, TextField } from '@mui/material';
import { BoxFC, BoxFR } from 'components/BoxCustom';
import DataGridCellExpand from 'components/DataGridCellExpand/DataGridCellExpand';
import React, { useCallback, useState, useContext, useEffect, useMemo } from 'react';
import { addSumRow, getSumColorClassName, sxSumColorClass } from 'utils/highlightSumColor';
import { columns } from './columns';
import { GlobalStateContext } from 'contexts/GlobalStateContext';
import DateTimeSelect from 'components/DateTimeSelect';
import SearchButton from 'components/buttons/SearchButton';
import ClearButton from 'components/buttons/ClearButton';
import { initFilterData, clearFilterData } from './initData';
import { addIdForDataGrid } from 'utils';
import { branchApiNm } from 'branch/constant/branchApiNm';
import { salaryDriverColNm } from '../DriverSalary/constant';
import { DesktopDatePicker } from '@mui/x-date-pickers-pro';
import { driverColNm } from 'constants/driverConstant';
import ComboBox from 'components/ComboBox';
import SelectMonthDialog from '../DriverTripPayment/SelectMonthDialog';
import CheckboxFormControl from 'components/CheckboxFormControl';
import DriverAdvancePayDialog from './DriverAdvancePayDialog';
import dayjs from 'dayjs';

let selectedId = null;
let selectedIds = []
let lastFilter = null
let oData  = null;

const DriverAdvancePay = () => {
  const { ax, msData } = useContext(GlobalStateContext)
  const [dataTable, setDataTable] = useState([])
  const [selectionModel, setSelectionModel] = useState([])
  const [dialogSelectMonthOpen, setDialogSelectMonthOpen] = useState(false)
  const [dialogOpen, setDialogOpen] = useState(false)
  const [filterData, setFilterData] = useState({ ...initFilterData })

  const modDataTable = useMemo(() => {
    return addSumRow({
      dataTable: dataTable,
      sumColNms: ["Amnt"],
      sumColTotal: "DrvNm"
    })
  }, [dataTable])

  const monthPickerProp = useMemo(() => ({
    disableMaskedInput: true,
    label: salaryDriverColNm.SalDte,
    openTo: "month",
    views: ["year", "month"],
    inputFormat: "DD/MM/YYYY",
    value: filterData.SalDte,
    onChange: (newValue) => { setFilterData(o => ({ ...o, SalDte: newValue })) },
    renderInput: (params) => <TextField size="small" {...params} sx={{ width: 160 }} />,

  }), [filterData.SalDte])

  const getData = useCallback((filter) => {
    const postData = {
      ...filter,
      SalDte: filter.SalDte ? filter.SalDte.format("YYYY-MM-DD") : null,
    }
    ax.post(branchApiNm.getDriverAdvancePay, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, 'PCTrnsId'))
        lastFilter = postData
      }
    })
  }, [ax])

  const handleCellClick = useCallback((params) => {
    if (params.field === 'ShowDetail') {
      selectedId = params.row.PCTrnsId
      setDialogOpen(true)
    } else if (params.field === 'SalDte') {
      oData = params.row.SalDte? dayjs(params.row.SalDte).format("YYYY-MM-01"): null
      if (selectionModel.includes(params.id)) {
        selectedIds = dataTable.filter(row => selectionModel.includes(row.id)).map(row => row.PCTrnsId)
      } else {
        selectedIds = [params.row.PCTrnsId]
      }
      console.log("selectedIds::", selectedIds)
      setDialogSelectMonthOpen(true)
    }
  }, [dataTable, selectionModel])


  const onSelectMonthDialogFinish = useCallback((data) => {
    // console.log("data::", data)
    const postData = {
      PCTrnsIds: selectedIds,
      SalDte: data? dayjs(data).format("YYYY-MM-01"): null,
      getFn: "getDriverAdvancePay",
      getArgs: lastFilter,
    }
    ax.post(branchApiNm.insertDeleteSalaryPCTrnsaction, postData).then(value => {
      if (value.data) {
        setDataTable(addIdForDataGrid(value.data, 'PCTrnsId'))
      }
    })
  }, [ax])


  const handleRowDoubleClick = useCallback((params) => {
    selectedId = params.id
    setDialogOpen(true)
  }, [])

  const onFinish = useCallback((data) => {
    getData(lastFilter)
  }, [getData])

  const handleAddDriverAdvancePay = useCallback(() => {
    selectedId = null
    setDialogOpen(true)
  }, [])
  useEffect(() => {
    getData({ ...initFilterData })
  }, [getData])

  return (
    <BoxFC height='100%'>
      <BoxFR>
        <Button variant='contained' onClick={handleAddDriverAdvancePay}>เพิ่มเบิกเงินล่วงหน้า</Button>
        <Box flex={1} />

        <ComboBox sx={{ width: 150 }} options={msData.driverOnlyCombo} label={driverColNm.DrvNm}
          selectedId={filterData.DrvId}
          setSelectedId={(id) => setFilterData(o => ({ ...o, DrvId: id }))}
        />
        <DateTimeSelect sx={{ width: 180 }} label='เวลาทำรายการ' start={filterData.PayTmSt} end={filterData.PayTmEn}
          setSelectDate={(st, en) => { setFilterData(o => ({ ...o, PayTmSt: st, PayTmEn: en })) }}
          getData={() => { getData(filterData) }}
        />
        <Box sx={{ position: "relative" }}>
          <DesktopDatePicker {...monthPickerProp} />
          <CheckboxFormControl sx={{ position: "absolute", bottom: -24, left: 0 }} label="ระบุุคิดเงินเดือน"
            checked={filterData.IsFilterSalDte === 1}
            onChange={(e) => setFilterData(o => ({ ...o, IsFilterSalDte: e.target.checked ? 1 : 0 }))} />
        </Box>
        <SearchButton onClick={(() => getData(filterData))} />
        <ClearButton onClick={(() => setFilterData({ ...clearFilterData }))} />
      </BoxFR>
      <Box height='100%' sx={{ ...sxSumColorClass, mt: 1 }}>
        <DataGridCellExpand
          checkboxSelection
          selectionModel={selectionModel}
          onSelectionModelChange={(newSelectionModel) => {
            setSelectionModel(newSelectionModel);
          }}
          hideFooter
          rows={dataTable}
          columns={columns}
          onRowDoubleClick={handleRowDoubleClick}
          onCellClick={handleCellClick}
          getRowClassName={getSumColorClassName}
          experimentalFeatures={{ rowPinning: true }}
          pinnedRows={{ bottom: [modDataTable.find(row => row.id === 0)] }}
        />
      </Box>
      <DriverAdvancePayDialog
        pcTrnsId={selectedId}
        dialogOpen={dialogOpen}
        setDialogOpen={setDialogOpen}
        onFinish={onFinish} />

      <SelectMonthDialog
        dialogOpen={dialogSelectMonthOpen}
        setDialogOpen={setDialogSelectMonthOpen}
        onFinish={onSelectMonthDialogFinish}
        oldData={oData} />
    </BoxFC>
  );
}

export default DriverAdvancePay