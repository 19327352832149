import { Dialog, DialogContent, TextField } from "@mui/material"
import { BoxFC, BoxFR } from "components/BoxCustom"
import PaperComponent from "components/PaperComponent"
import { DialogFooter, DialogHeader } from "components/dialog/DialogHeader"
import { GlobalStateContext } from "contexts/GlobalStateContext"
import React, { useCallback, useContext, useEffect, useState } from "react"
import { shippingApiNm } from "./shippingApiNm"
import CheckboxFormControl from "components/CheckboxFormControl"

const initDialogData = {
  subject: "",
  message: "",
  to: "",
  cc: "",
}
const initCheckboxData = {
  InvNo: true,
  JobNo: false,
  Bkg: false,
}
let sendEmailData = {
  IP_InvNo: "",
  JobNo: "",
  Bkg: "",
  Files: []
}
const SendEmailDialog = ({ shpInvId, dialogOpen, setDialogOpen, colNm, onFinish }) => {

  const { ax } = useContext(GlobalStateContext)
  const [dialogData, setDialogData] = useState(initDialogData)
  const [checkboxData, setCheckboxData] = useState(initCheckboxData)

  const textFieldProps = useCallback((name) => ({
    size: "small",
    value: dialogData[name],
    onChange: (e) => setDialogData(o => ({ ...o, [name]: e.target.value })),
  }), [dialogData])

  const handleCheckBoxChange = useCallback((col) => (e) => {
    setCheckboxData(o => {
      const result = { ...o, [col]: e.target.checked }
      let subject = ""
      if (result.InvNo) {
        subject += `Invoice No. ${sendEmailData.IP_InvNo} `
      }
      if (result.JobNo) {
        if (subject !== "") subject += ", "
        subject += `Job No. ${sendEmailData.JobNo} `
      }
      if (result.Bkg) {
        if (subject !== "") subject += ", "
        subject += `Booking ${sendEmailData.Bkg} `
      }
      subject = `ใบขน ${subject}`
      setDialogData(o => ({ ...o, subject }))
      return result
    })

  }, [])

  const handledialogOk = useCallback(async () => {
    
    const postData = {
      ShpInvId: shpInvId,
      ColNm: colNm,
      Subject: dialogData.subject,
      Message: dialogData.message,
      To: dialogData.to,
      CC: dialogData.cc,
    }
    ax.post(shippingApiNm.sendEmail, postData).then(value=>{
      if(value.data){
        onFinish()
        setDialogOpen(false)
      }
    })
  }, [ax, shpInvId, colNm, onFinish, setDialogOpen, dialogData])

  useEffect(() => {
    if (dialogOpen) {
      ax.post(shippingApiNm.getSendEmailData, { ShpInvId: shpInvId }).then(value => {
        if (value.data && value.data.length > 0) {
          const data = value.data[0]
          let docName = ""
          if (colNm === "EDSndTm") {
            docName = "Draft ใบขน"
          } else if (colNm === "ED0209SndTm") {
            if (data.Files.find(file => file.ShpFileTypId === 3)) {
              docName = "ใบขนและ ใบกำกับตู้"
            } else {
              docName = "ใบขน"
            }
          } else if (colNm === "CDSndTm") {
            docName = "ใบกำกับตู้"
          } else if (colNm === "ED0309SndTm") {
            docName = "ใบขน 0309"
          } else if (colNm === "ED0409SndTm") {
            docName = "ใบขน 0409"
          }
          const subject = `${docName} Invoice No. ${data.IP_InvNo}`
          const message = `กรุณาตรวจสอบ ${docName} ตามไฟล์แนบ`
          sendEmailData = { 
            ...data,
           }
          setDialogData({ subject, message, 
            to: data.OwnAccEmail || "",
            cc: data.CCEmails || "", })
        }
      })
    }
  }, [dialogOpen, ax, shpInvId, colNm])

  return (
    <Dialog
      open={dialogOpen}
      PaperComponent={PaperComponent}
      aria-labelledby="draggable-dialog-title"
      fullWidth maxWidth='sm'
    >
      <DialogHeader handleDialogClose={() => setDialogOpen(false)}
        title="ส่ง Email"
        onDeleteClick={null}
      />
      <DialogContent>
        <BoxFC pt={2} >
          <BoxFR>
            <CheckboxFormControl label="Invoice No" checked={checkboxData.InvNo} onChange={handleCheckBoxChange("InvNo")} />
            <CheckboxFormControl label="Job No" checked={checkboxData.JobNo} onChange={handleCheckBoxChange("JobNo")} />
            <CheckboxFormControl label="Booking" checked={checkboxData.Bkg} onChange={handleCheckBoxChange("Bkg")} />
          </BoxFR>
          <TextField {...textFieldProps("subject")} label="Subject" fullWidth />
          <TextField {...textFieldProps("to")} label="To" fullWidth />
          <TextField {...textFieldProps("cc")} label="CC" fullWidth />
          <TextField {...textFieldProps("message")} label="Message" fullWidth multiline rows={4} />
        </BoxFC>
      </DialogContent>
      <DialogFooter okText={"ส่ง Email"}
        handleDialogClose={() => setDialogOpen(false)}
        handleDialogOk={handledialogOk} />
    </Dialog>
  )
}

export default React.memo(SendEmailDialog)