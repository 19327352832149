import { ImageRounded } from "resource/muiImport"
import { IconButton } from "@mui/material"
import { jobOrderColNm } from "constants/jobOrderConstant"
import dayjs from "dayjs"
// import { Link } from "react-router-dom";
import PlaceRoundedIcon from '@mui/icons-material/PlaceRounded';
import { getPOIPlace } from "utils/getPOIPlace";
import { shipmentColNm } from "constants/shipmentConstant";
export const scheduleJobColumns = (handleImageClick) => [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "Loc",
    headerName: "Location",
    valueGetter: (params) => getPOIPlace(params.row, "LOC"),
    width: 160,
  },
  {
    field: "JobNo",
    headerName: jobOrderColNm.JobNo,
    width: 130,
  },
  {
    field: "Bkg",
    headerName: jobOrderColNm.Bkg,
    width: 130,
  },
  {
    field: "ShpmTypNmEN",
    headerName: shipmentColNm.ShpmTypNm,
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "Appoint Time",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "TakePlc",
    headerName: "CY",
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 90,
  },
  {
    field: "RtnPlc",
    headerName: "Return",
    valueGetter: (params) => getPOIPlace(params.row, "RTN"),
    width: 90,
  },
  {
    field: "ContNo",
    headerName: "Container No.",
    width: 130,
  },
  {
    field: "SealNo",
    headerName: "Seal No.",
    width: 100,
  },
  {
    field: "NumFile",
    headerName: "รูปภาพ",
    width: 70,
    renderCell: (params) => (
      <IconButton onClick={params.value ? handleImageClick(params.id) : null}>
        <ImageRounded color={params.value ? "primary" : "inherit"} />
      </IconButton>
    )
  },
  {
    field: "TareWt",
    headerName: "Tare Wt.",
    width: 90,
  },
  {
    field: "RtnDteEn",
    headerName: "Cut-Off",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "Rmk",
    headerName: "Remark",
    flex: 1,
  },
]
export const todayColumns = (handleImageClick, handleLocationClick) => [
  {
    field: "No",
    headerName: "No.",
    width: 60,
    align: "center",
  },
  {
    field: "Loc",
    headerName: "Location",
    valueGetter: (params) => getPOIPlace(params.row, "LOC"),
    width: 160,
  },
  {
    field: "JobNo",
    headerName: jobOrderColNm.JobNo,
    width: 130,
  },
  {
    field: "Bkg",
    headerName: jobOrderColNm.Bkg,
    width: 130,
  },
  {
    field: "ShpmTypNmEN",
    headerName: shipmentColNm.ShpmTypNm,
    width: 90,
  },
  {
    field: "AptTm",
    headerName: "Appoint Time",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "ArvTm",
    headerName: "Arrive",
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "LevTm",
    headerName: "Leave",
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
    width: 100,
  },
  {
    field: "TakePlc",
    headerName: "CY",
    valueGetter: (params) => getPOIPlace(params.row, "TAKE"),
    width: 90,
  },
  {
    field: "ContNo",
    headerName: "Container No.",
    width: 130,
  },
  {
    field: "SealNo",
    headerName: "Seal No.",
    width: 100,
  },
  {
    field: "NumFile",
    headerName: "Images",
    width: 70,
    renderCell: (params) => (
      <IconButton onClick={params.value ? handleImageClick(params.id) : null}>
        <ImageRounded color={params.value ? "primary" : "inherit"} />
      </IconButton>
    )
  },
  {
    field: "TareWt",
    headerName: "Tare Wt.",
    width: 90,
  },
  {
    field: "RtnDteEn",
    headerName: "Cut-Off",
    width: 100,
    type: 'dateTime',
    valueGetter: (params) => params.value && dayjs(params.value),
    valueFormatter: (params) => params.value ? params.value.format("DD/MM HH:mm") : "-",
  },
  {
    field: "Status",
    headerName: "Status",
    width: 150,
  },
  {
    field: "LicNm",
    headerName: "Truck",
    width: 75,
  },
  {
    field: "Coord",
    headerName: "พิกัด",
    width: 60,
    valueGetter: (params) => `${params.row.Lat},${params.row.Lng}`,
    renderCell: (params) => {
      const enable = params.row.Lat && params.row.Lng && !params.row.IsCmpt
      return (
        // <Link replace to={{ pathname: enable?`https://www.google.com/maps/search/?api=1&query=${params.value}`:"#" }} target="_blank" rel="noreferrer">
          <IconButton onClick={()=>enable?handleLocationClick(params.row): null}>
            <PlaceRoundedIcon color={enable ? "primary" : "disabled"}/>
          </IconButton>
        // </Link>
      )
    }
  },
  {
    field: "Rmk",
    headerName: "Remark",
    flex: 1,
  },
]