export const clearFilterData = {
  PayTmSt: null,
  PayTmEn: null,
  DrvId: null,
  SalDte: null,
  IsFilterSalDte: 1
}

export const initFilterData = { ...clearFilterData }

export const initDriverAdvancePay = {
  PCTrnsId: null,
  PCItmId: null,
  ExpId: null,
  DrvId: null,
  Dscp: "",
  PayTm: null,
  Amnt: "",
  PCJnlId: null,
  PCPrdId: null,
}